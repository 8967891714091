//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import './index.less'
export default {
  computed: {
    ...mapGetters(['lang'])
  },
  props: {
    showSearch: Boolean,
    searchData: {
      type: Object, default: {
        games_list: [],
        guilds_list: [],
        tokens_list: [],
        vc_list: [],
      }
    }
  },
  methods: {
    goDetail(item) {
      this.$router.push('/games/' + item.game_name)
    }
  }
}
