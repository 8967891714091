//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  props: {
    data: Array,
    columns: Array,
    loading: Boolean
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  methods: {
    rowClick(item) {
      if (!item.is_online) return this.$Message.info('暂未收录');
      if (item.is_active) {
        this.$router.push(`/games/${item.query_name}`)
      } else {
        this.$Message.info('暂未收录');
      }
    },
    go(item) {
      this.$router.push('/investor/' + item.query_name)
    },
    changeList(record) {
      record.expand = !record.expand
      this.$emit('setInvestor', record)
    },
  }
}
