//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './index.less'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: { Swiper, SwiperSlide },
  directives: {
    swiper: directive
  },
  computed: {
    ...mapGetters(['lang', 'theme', 'h5'])
  },
  data() {
    return {
      showOpacity: false,
      bannerBg: '',
      trendsTag: {
        'whitelist': 'Whitelist',
        'airdrop': 'Airdrop',
        'giveaway': 'Giveaway',
        'nft': 'NFT Mint',
        'other': this.$t('trends.other'),
      },
      trends: [],
      socials: [
        { url: "https://twitter.com/MyMetadata_io", type: "twitter", title: "twitter" },
        { url: "https://discord.gg/7BGJ7YCBz7", type: "discord", title: "discord" },
        { url: "https://t.me/my_metadata", type: "telegram", title: "telegram" }
      ],
      whaleCount: 0,
      games: [
        { image_url: '', link_url: '' },
        { image_url: '', link_url: '' },
      ],
      data: {},
      loading: true,
      banners: [],
      swiperOption: {
        slidesPerView: 3,
        spaceBetween: 20,
        // autoplay: {
        //   delay: 3500
        // },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      },
      h5swiperOption: {
        autoplay: {
          delay: 3500
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

      },
    }
  },
  mounted() {
    this.getBanner()
    this.getValue()
    this.getTrends()
  },
  methods: {
    ...mapMutations(['setGamefiData', 'setWhaleData']),
    slideChange() {
      if (!this.h5) {
        let i = this.$refs.mySwiper.$swiper.activeIndex + 1
        this.bannerBg = this.lang == 'en' ? this.banners[i].image_url_en : this.banners[i].image_url
      }
    },
    go(url = '') {
      // if (url.indexOf('https') >= 0) {
      window.open(url)
      // } else {
      // window.open(url)
      // this.$router.push(url)
      // }
    },
    getBanner() {
      this.$http.get('/api/v1/meta/banners/list', { limit: 10 }, ({ result }) => {
        this.bannerBg = this.lang == 'en' ? result.records[0].image_url_en : result.records[0].image_url
        this.banners = result.records
        this.showOpacity = true //遮挡的透明度
        this.$parent.showSum = true
      })
    },
    getTrends() {
      this.$http.get('/api/v1/news/list', { limit: 10, offset: 0, type: 'trends' }, ({ result }) => {
        this.trends = result.records.map(m => {
          m.str = this.lang == 'en' ? (m.content_en || m.content) : (m.content || m.content_en)
          return m
        })
        this.trends = this.trends.filter(h => h.str)
      })
    },
    getWhale(data) {
      this.$http.get('/v2/game/whale/track_count', { noEncrypt: true }, (result) => {
        this.data = data
        this.whaleCount = this.utils.changeUnit(result.whale_count)
        this.setWhaleData(result)
      })
    },

    getValue() {
      this.$http.get('/api/v1/meta/game/total', {}, ({ result }) => {
        result.sum = result.guild_count + result.investor_count
        for (let key in result) {
          result[key] = this.utils.changeUnit(result[key])
        }
        result.sum = (Number(result.investor_count || 0) + Number(result.guild_count - 0)) || 0
        this.setGamefiData(result)
        this.getWhale(result)

      })
    }
  }
}
