//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/guild-detail.less'
import { mapGetters } from 'vuex'
export default {
  layout: 'index',
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  data() {
    return {
      isOpen: false,
      loading: true,
      data: {},
      investorData: [{}, {}, {}, {}, {}],
      tableData: [],
      game_ids: ''
    };
  },
  mounted() {
    this.getinvestor()
    this.getInfo()
    // this.getData()
  },
  methods: {
    getInfo() {
      let query_name = this.$route.params.id
      this.$http.get('/api/v1/meta/investor/info', { query_name }, ({ result }) => {
        if (result.socials) {
          result.socials = result.socials.filter(h => h.title != 'youtube')
          if (result.website) {
            result.socials.unshift({ type: 'blog', url: result.website })
          }
        }
        this.data = result
        this.game_ids = JSON.stringify(result.game_ids)
        this.loading = false
        this.getData()
      })
    },
    getinvestor() {
      this.loading = true
      this.$http.get('/api/v1/meta/investor/list', { limit: 10, offset: 0 }, res => {
        this.investorData = res.result.records
        this.loading = false
      })
    },
    getData() {
      let { game_ids } = this
      this.$http.get('/api/v1/meta/game/assign/list', { game_ids, limit: 20, offset: 0 }, ({ result }) => {
        this.tableData = result.records.map((m, i) => {
          m.no = i + 1
           let { new_user, total_user  } = m.chain_users
          m.new_users = this.utils.changeUnit(new_user)
          m.total_users_v0 = this.utils.changeUnit(total_user)
          return m
        })
      })
    },
  },
};
