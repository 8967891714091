//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/about.less'
import { LogoTwitter, LogoTelegram, LogoDiscord, AlertCircle, Mail } from 'kui-icons'
export default {
  layout: 'index',
  data() {
    return {
      LogoTwitter, LogoTelegram, LogoDiscord, AlertCircle, Mail
    }
  }
};
