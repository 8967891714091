//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AlertCircle } from "kui-icons";
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['lang'])
  },
  props: {
    data: Array,
    columns: Array,
    loading: Boolean
  },
  data() {
    return {
      AlertCircle
    }
  },
  methods: {
    filterChange(a, b) {
      this.$emit('filter', b)
    },
    rowClick(item) {
      window.location.href = '/guilds/' + item.query_name
    },
  }
}
