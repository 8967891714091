//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['lang', 'h5'])
  },
  data() {
    return {
      points: [{}, {}, {}, {}, {}, {}],
      loading: true,
    }
  },
  mounted() {
    this.getPoints()
  },
  methods: {
    getPoints() {
      let query_name = this.$route.params.id
      this.$http.get('/api/v1/meta/game/dune/kolTweets', { limit: 100, offset: 0, query_name,days:30 }).then(({ result }) => {
        result.records.map(m => {
          m.msg = this.utils.replaceText(m.msg)
        })
        this.points = result.records
        setTimeout(() => {
          this.loading = false
        }, 300);
      })
    },
  }
}
