
import '~/assets/less/ranking.less'
import { mapGetters } from 'vuex'

export default {
  layout: 'index',
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      search: [],
      rowIndex: 1,
      days: 1,
      loading: false,
      page: 1,
      total: 0,
      data: [],
      size: 30,
      item: {},
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('nft.col_name'), key: 'name' },
        { title: this.$t('nft.col_user'), key: 'members', sorter: true },
        { title: this.$t('nft.col_active_user'), key: 'active_users', sorter: true },
        { title: this.$t('nft.col_community'), key: 'active_score', sorter: true },
        { title: this.$t('nft.col_start_time'), key: 'sale_date' },
        { title: this.$t('nft.col_mint_price'), key: 'price' },
        { title: this.$t('nft.col_links'), key: 'other' },
      ],
      h5data: [],
      sortKey: ['members', 'active_users', 'active_score'],
      h5columns: [
        { title: '#', key: 'no', className: "tf tf1" },
        { title: this.$t('nft.col_name'), key: 'name', className: "tf tf2" },
        { title: this.$t('nft.col_user'), key: 'members', sorter: true },
        { title: this.$t('nft.col_active_user'), key: 'active_users', sorter: true },
        { title: this.$t('nft.col_community'), key: 'active_score', sorter: true },
        { title: this.$t('nft.col_start_time'), key: 'sale_date' },
        { title: this.$t('nft.col_mint_price'), key: 'price', },
        { title: this.$t('nft.col_links'), key: 'other' },
      ],
      order: 'desc',//排序
      scroll: 0,
    };
  },
  asyncData({ $http }) {
    return $http.get('/api/v1/nft/rank', {
      limit: 30, offset: 0,
      days: 1, order_list: '[]'
    }).then(({ result }) => {
      result.records.map((m, i) => {
        m.no = i + 1;
        m.other = [
          { title: 'discord', type: 'discord', url: m.discord },
          { title: 'twitter', type: 'twitter', url: 'https://twitter.com/' + m.twitter_id },
          { title: 'blog', type: 'blog', url: m.website },
        ]
        return m
      })

      return {
        total: result.count,
        data: result.records,
        h5data: result.records,
      }
    })
  },
  methods: {
    dayChange(item, i) {
      this.days = item.value
      this.page = 1
      this.getData()
    },
    onDay(v) {
      this.days = v
      this.page = 1
      this.getData()
    },
    filterChange({ key, order }) {
      this.sortKey.map((m, i) => {
        if (m == key) {
          this.search = order ? [key + (order == 'asc' ? '_1' : '_2')] : []
        } else {
          if (this.h5) {
            this.h5columns[i + 2]._order = ''
          } else {
            this.columns[i + 2]._order = ''
          }
        }
      })
      this.page = 1
      this.getData()
    },
    onHandlePageSize(size) {
      this.page = 1
      this.size = size * 1;
      this.getData()
    },
    onHandlePage(page) {
      this.page = page;
      this.getData()
    },
    getData(type) {
      let { size, search, page } = this
      this.loading = true
      let sortList = JSON.stringify(search)
      this.$http.get('/api/v1/nft/rank', { limit: size, offset: (page - 1) * size, days: this.days, order_list: sortList }, ({ result }) => {
        this.total = result.count
        result.records.map((m, i) => {
          m.no = i + ((page - 1) * size) + 1;
          m.other = [
            { title: 'discord', type: 'discord', url: m.discord },
            { title: 'twitter', type: 'twitter', url: 'https://twitter.com/' + m.twitter_id },
            { title: 'blog', type: 'blog', url: m.website },
          ]
          return m
        })
        if (page == 1) this.data = [], this.h5data = []
        if (type) {
          this.h5data = this.h5data.concat(result.records)
        } else {
          this.data = result.records
          this.h5data = result.records
        }

        this.loading = false
      }, err => {
        this.loading = false
        // this.$Message.error(err.message)
      })
    },
    seeMore() {
      this.page++
      this.getData('is_h5')
    },
  },
  render() {
    let { data, columns, total, h5, h5columns, h5data, dayChange } = this
    return (
      <div class="ranking page-nft">
        <div class="rank-title-wrap">
          <div class="title"><span>NFT</span></div>
          <div class="sub-title">
            <span class="sub-desc">{this.$t('nft.sub_title')}</span>
            {!h5 && <DateFilter onDayChange={dayChange} />}
          </div>
        </div>
        {h5 ?
          <NftH5 data={h5data} columns={h5columns} total={total} onFilter={this.filterChange} onMore={this.seeMore} onDay={this.onDay} /> :
          <NftPc data={data} columns={columns} onFilter={this.filterChange} total={total} onPage={this.onHandlePage} onSize={this.onHandlePageSize} />}
      </div>
    )
  }
};
