//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  data() {
    return {
      gameName: '',
      game: 'alien-worlds-1',
      roi: '',
      loading: true,
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let game = this.$route.params.id
      this.$http.get('/api/v1/meta/game/project/info', { game_name: game }).then(res => {
        let info = res.result.project_info
        this.roi = ((this.lang == 'en' ? info.rio_en : info.rio) || '').trim()
        this.gameName = res.result.name
        this.loading = false
      })
    },
  }
}
