//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'theme'])
  },
  data() {
    return {
      showTwitter: true,
      showTwfollow: true,
      showTelegram: true,
      showDiscord: true,
      // showSocial:true,
      colors: ['orange', 'cyan', 'green', 'magenta', 'blue'],
      date: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 90, label: '3M' },
        { value: 365, label: 'All' },
      ],
      dateText: {
        7: '7D',
        30: '30D',
        90: '3M',
        365: 'ALL',
      },
      date_follwers: 30,
      date_likes: 30,
      date_follwers: 30,
      date_retweets: 30,
      date_replies: 30,
      date_quotes: 30,
      date_members: 30,
      date_dau: 30,
      date_news: 30,

      date_dc_members: 30,
      date_dc_user: 30,
      date_dc_msg: 30,
      game: 'alien-worlds-1',

      symbols: [],
      likes_total: 0,
      replies_total: 0,
      retweets_total: 0,
      quotes_total: 0,
      follwers_total: 0,
      dau_total: 0,
      news_total: 0,
      members_total: 0,
      dc_user_total: 0,
      dc_msg_total: 0,
      dc_members_total: 0,
      dcTooltip: {
        dc_user: 'Active Users',
        dc_msg: 'Messages',
        dc_members: 'Members',
        members: 'Members',
        follwers: 'Twitter Followers'
      },
      yValue: {},
      gridText: {
        'dc_msg': 'Discord Messages', 'dc_user': 'Discord Active Users', 'dau': 'Telegram DAU', 'news': 'Telegram News', 'members': 'Telegram Members',
        'likes': 'Twitter Likes', 'replies': 'Twitter Replies',
        'retweets': 'Twitter Retweets', 'quotes': 'Twitter Quotes',
        'follwers': 'Twitter Followers', 'dc_members': 'Discord Members'
      },
      twDays: 30,
      disDays: 30,
      teDays: 30,
    }
  },
  mounted() {
    let game = this.$route.params.id
    game = game == 'game' || !game ? 'alien-worlds-1' : game;
    this.game = game
    this.init()
  },
  methods: {
    init() {
      this.date_follwers = 30
      this.date_likes = 30
      this.date_follwers = 30
      this.date_retweets = 30
      this.date_quotes = 30
      this.date_members = 30
      this.date_dau = 30
      this.date_news = 30;
      this.initMap()
      this.getSymbol()
    },
    initMap() {
      let { h5, theme } = this
      let leftColor = theme == 'dark' ? '#717171' : '#999'
      let dashedColor = theme == 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#dddddd60'

      let option = {
        graphic: [{
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: h5 ? 100 : 200, height: h5 ? 19 : 38 }
        },
        {
          rotation: Math.PI / 2,
          type: 'text', left: '5px', top: 'middle', style: { fill: leftColor, text: 'Token Price ($)', font: '14px "Inter"' }
        },
        {
          rotation: Math.PI / -2,
          type: 'text', right: '5px', top: 'middle', style: { fill: leftColor, text: 'Token Price ($)', font: '14px "Inter"' }
        }
        ],
        tooltip: { extraCssText: "z-index:1", trigger: 'axis', backgroundColor: '#121326', borderWidth: 0, textStyle: { color: '#fff' }, axisPointer: { label: { backgroundColor: '#6a7985' } } },
        grid: { left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        xAxis: {
          type: 'category', data: [],
          axisLine: { show: false },
          // axisLabel: { color: "#666", formatter: v => moment(new Date(v||'')).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
        legend: { data: [], icon: 'circle', bottom: 0, textStyle: { color: '#999', fontSize: 12 }, itemHeight: 8, },
        yAxis: [
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) > 0 && this.utils.changeUnit1(v) < 1 ? (this.utils.changeUnit1(v) * 1).toFixed(2) : this.utils.changeUnit1(v) },
            splitNumber: 6, // 横线数

            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor, //垂直于y轴的线的颜色
                width: 1,
                type: 'dashed' //'dotted'虚线 'solid'实线
              }
            },
            min: value => value.min,
            max: value => value.max,
          },
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor, //垂直于y轴的线的颜色
                width: 1,
                type: 'dashed' //'dotted'虚线 'solid'实线
              }
            },
            min: value => value.min,
            max: value => value.max,
          }
        ],
        series: [
          {
            name: '',
            yAxisIndex: 0,
            data: [], type: 'bar',
            barMaxWidth: 15,
            barMinHeight: 5,
            itemStyle: { color: '#7266FF' }
          },
          { type: 'line', }, { type: 'line', }, { type: 'line', }, { type: 'line', },
        ]
      };
      ['members', 'likes',
        'retweets', 'follwers', 'dc_members'].forEach(id => {
          let chart = echarts.init(document.getElementById('map_' + id))
          this['char_' + id] = chart
          chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' })
          option.graphic[1].style.text = this.gridText[id]
          option.series[0].name = this.gridText[id]
          // option.yAxis[0].name = this.gridText[id]
          if (id == 'likes') {
            option.graphic[1].style.text = ''
            option.series = [
              { type: 'bar', symbol: 'circle', yAxisIndex: 0, barMaxWidth: 15, itemStyle: { color: '#7266FF' }, name: this.gridText['likes'], },
              { type: 'bar', itemStyle: { color: 'cyan' }, symbol: 'circle', yAxisIndex: 0, barMaxWidth: 15, name: this.gridText['replies'], },
              { type: 'line', itemStyle: { color: 'orange' }, symbol: 'circle', yAxisIndex: 1 },
            ]
          } else if (id == 'retweets') {
            option.graphic[1].style.text = ''
            option.series = [
              { type: 'bar', symbol: 'circle', yAxisIndex: 0, barMaxWidth: 15, itemStyle: { color: '#7266FF' }, name: this.gridText['retweets'], },
              { type: 'bar', itemStyle: { color: 'cyan' }, symbol: 'circle', yAxisIndex: 0, barMaxWidth: 15, name: this.gridText['quotes'], },
              { type: 'line', itemStyle: { color: 'orange' }, symbol: 'circle', yAxisIndex: 1 },
            ]
          } else {
            option.series = [
              {
                name: '',
                yAxisIndex: 0,
                data: [], type: 'bar',
                barMaxWidth: 15,
                barMinHeight: 5,
                itemStyle: { color: '#7266FF' }
              },
              { type: 'line', }, { type: 'line', }, { type: 'line', }, { type: 'line', },
            ]
          }
          let line = {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13 },
            splitLine: { show: false, },
          }
          option.yAxis[1] = Object.assign({}, line)
          option.yAxis[2] = Object.assign({}, line, { axisLabel: { show: false } })
          option.yAxis[3] = Object.assign({}, line, { axisLabel: { show: false } })

          chart.setOption(option)
          window.addEventListener('resize', () => {
            chart.resize()
          })
        })
    },
    getInfo() {
      this.$http.get('/api/v1/meta/game/info', { game_name: this.game }, res => {
        this.info = res.result
      })
    },
    renderMap(id, xAxis = [], series = [], index = 0, series1 = [], day) {
      let chart = this['char_' + id]
      chart.hideLoading()
      // let n = new Date(), y = n.getFullYear(), m = n.getMonth() + 1;
      // let days = new Date(y, m, 0).getDate() //本月有多少天

      // let count = 365//new Date(y, m, 0).getDate() //本月有多少天
      // let day = 30
      // if (xAxis.length < count) {
      //   let t = count - xAxis.length
      //   let j = xAxis.slice(0, 1)[0] || new Date();
      //   let f = moment(new Date(j)).subtract(t, 'days')
      //   let nx = []
      //   for (let i = 0; i < t; i++) {
      //     nx.push(f.format('YYYY/MM/DD'))
      //     f.add(1, 'days')
      //   }
      //   xAxis = [].concat(nx, xAxis)
      // }
      //series
      // if (series.length < count) {
      //   let ns = new Array(count - series.length).fill('0')
      //   series = [].concat(ns, series)
      // }

      xAxis = xAxis.map(x => x.replace(/-/g, '/'))

      let symbol_series = [].concat([], this.symbol_series)
      symbol_series = symbol_series.map(x => {
        x.data = this['series_' + x.name].slice(-day)
        return x
      })
      let option = {
        xAxis: {
          data: xAxis.slice(-day),
          axisLabel: { color: "#666", formatter: v => moment(new Date(v)).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
        },
        series: [{
          name: this.dcTooltip[id],
          yAxisIndex: index,
          data: series.slice(-day)
        }, ...symbol_series]
      }
      if (id == 'likes' || id == 'retweets') {
        option.series = [{
          yAxisIndex: index,
          data: series.slice(-day)
        }, {
          yAxisIndex: index,
          data: series1.slice(-day)
        }, ...symbol_series]
      }
      this['xAxis_' + id] = xAxis
      this['series_' + id] = series
      chart.setOption(option)
    },

    getTwitter(key, days) {
      days = days || 30
      this.$http.get('/api/v1/meta/social/tt', { game_name: this.game, days: days }, ({ result }) => {
        if (!result.date_list.length) {
          this.showTwitter = false
        }
        if (!result.follower_date.length) {
          this.showTwfollow = false
        }
        let xAxis = result.date_list || []
        if (key == 'likes' || !key) {
          this.likes_total = this.utils.getCount(result.favorite_list, days)
          this.replies_total = this.utils.getCount(result.reply_list, days)
          this.renderMap('likes', xAxis, result.favorite_list, '0', result.reply_list, days)
        }
        // if (key == 'replies' || !key) {
        // this.renderMap('replies', xAxis, result.reply_list)
        // }
        if (key == 'retweets' || !key) {
          this.retweets_total = this.utils.getCount(result.retweet_list, days)
          this.quotes_total = this.utils.getCount(result.quote_list, days)
          this.renderMap('retweets', xAxis, result.retweet_list, '0', result.quote_list, days)
        }
        // if (key == 'quotes' || !key) {
        //   this.renderMap('quotes', xAxis, result.quote_list)
        // }
        if (key == 'follwers' || !key) {
          this.follwers_total = this.utils.changeUnit1(result.follower_list.slice(-1)[0])
          this.renderMap('follwers', result.follower_date, result.follower_list, '0', [], days)
        }

      })
    },
    dateChange(key) {
      let keys = ['likes', 'retweets', 'follwers',]
      if (keys.indexOf(key) > -1) {
        this.getTwitter(key, this['date_' + key])
      } else if (key == 'members') {
        this.getTelegram(key, this['date_' + key])
      } else if (key == 'dc_members') {
        this.getDiscord(key, this['date_' + key])
      }
      // let xAxis = this['xAxis_' + key]
      // let datas = this['series_' + key]
      // let day = this['date_' + key] * -1
      // let symbol_series = [].concat([], this.symbol_series)

      // symbol_series.forEach(x => {
      //   x.data = this['series_' + x.name].slice(day)
      // })
      // if (key != 'members' && key != 'dc_members' && key != 'follwers') {
      //   this[key + '_total'] = this.utils.getCount(datas.slice(day))
      // }
      // this['char_' + key].setOption({
      //   xAxis: { data: xAxis.slice(day) },
      //   series: [
      //     { data: datas.slice(day) },
      //     ...symbol_series
      //   ]
      // })
    },
    getTelegram(key, days) {
      days = days || 30
      this.$http.get('/api/v1/meta/social/tg', { game_name: this.game, days: days }, ({ result }) => {
        if (!result.date_list.length) {
          this.showTelegram = false
        }
        let members_list = result.member_info.member_list.reverse()
        let members_xAxis = result.member_info.date_list.reverse()

        this.members_total = this.utils.changeUnit1(members_list.slice(-1)[0])

        this.renderMap('members', members_xAxis, members_list, null, [], days)
      })
    },
    getSymbol() {
      this.$http.get('/api/v1/meta/token/price', { game_name: this.game, days: 365 }, ({ result }) => {
        try {
          let lists = result.records || []
          let symbols = [];
          let yAxisIndex = 1;
          let symbol_series = []
          lists.forEach((s, i) => {
            let list = s.rate_list || []
            if (list.length < 365) {
              let nlist = new Array((365 - list.length)).fill('')
              list = [].concat(nlist, list)
            }
            symbols.push({
              title: `${s.symbol} ${this.$t('games.price')}`,
              value: '$' + parseFloat(list.slice(-1)[0] || 0)
            });
            this['series_' + s.symbol] = list
            let items = { name: s.symbol, showSymbol: false, itemStyle: { color: this.colors[i] }, lineStyle: { width: 2, color: this.colors[i] }, data: list, yAxisIndex, }
            symbol_series.push(Object.assign({}, items))

          })
          this.symbols = symbols
          this.symbol_series = symbol_series
        } catch (e) { console.log(e) }
        this.getTwitter()
        this.getTelegram()
        this.getDiscord()
      })
    },
    getDiscord(key, days) {
      days = days || 30
      this.$http.get('/api/v1/meta/social/dc', { game_name: this.game, days: days }, ({ result }) => {
        if (!result.date_list.length) {
          this.showDiscord = false
        }
        let xAxis = result.date_list
        let members_list = result.members_list
        members_list = members_list.map((x, i) => {
          if (x == 0 && i > 0) {
            x = parseInt(members_list[i - 1] * 1.1)
          }
          return x
        })
        this.dc_members_total = this.utils.changeUnit1(members_list.slice(-1)[0])
        this.renderMap('dc_members', xAxis, members_list, null, [], days)
      })
    }
  }
}
