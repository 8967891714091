//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
import { LogoTwitter } from 'kui-icons'
import moment from 'moment'
export default {
  watch: {
    $route: {
      handler(v, o) {
        this.game = v.params.id || ''
        this.getData()
      },
      deep: true
    },
    h5(v) {
      let type = this.$route.query.type || ''
      if (!v && this.tabs[0].key == 'overview') {
        this.tabs.splice(0, 1)
        this.tabs.splice(2, 0, { title: this.$t('games.v'), key: 'viewpoint' })
      }
      if (v && this.tabs[0].key != 'overview') {
        this.tabs.unshift({ title: this.$t('subject.overview'), key: 'overview' })
        this.tabs.splice(3, 1)
      }
      if (!/community|chain|strategy|guide|about/.test(type)) {
        this.tabActive = 'community'
        this.$emit('change', 'community')
        this.$router.push('/games/' + this.game + '?type=community')
      }
    }
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  props: {
    // info: Object,
    // tabActive: { type: [String, Number], default: '0' },
  },
  created() {
    let type = this.$route.query.type || (this.h5 ? 'overview' : 'community')
    if (/about|chain|token|community|event|ido|guide|social|roi|viewpoint|strategy|whale|overview/.test(type)) {
      this.tabActive = type
      this.tabIndex = this.tabs.findIndex(x => x.key == type)
      this.isGame = true
    }
  },
  mounted() {
    if (this.h5 && this.isGame) {
      this.tabs.splice(2, 1)
      this.setScroll(this.tabIndex * 1)
      this.tabs.unshift({ title: this.$t('games.overview'), key: 'overview' })
    }
    this.getData()
    this.getInvestor()
    document.addEventListener('click', () => {
      this.showShare = false
    })
  },
  data() {
    return {
      melodyPoster: '',
      moment,
      showPoster: false,
      LogoTwitter,
      showShare: false,
      showWhale: false,
      share: 'share',
      investorData: [],
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('investor.date'), key: 'date', width: 300 },
        { title: this.$t('investor.rounds'), key: 'rounds', render: (h, p) => h('span', this.utils.getRounds(p.rounds, this.lang) || '--') },
        { title: this.$t('investor.money'), key: 'money', className: 'chain', render: (h, p) => h('span', this.utils.moneyUnit(p.money, this.lang) || '--') },
        {
          title: this.$t('investor.company'), key: 'investor_name', className: 'chain'
          , render: (h, p) => {
            return p.investot_list.map(m => {
              return h('div', m.investor_name || '--')
            })
          }
        },
      ],
      showInvestor: false,
      loading: true,
      tabIndex: 0,
      tabActive: 'community',
      isGame: false,
      ranks: {},
      rois: ['Thetan Arena', 'Starsharks'],
      swiperOption: {
        spaceBetween: 10,
        loop: true,
        loopedSlides: 4,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        spaceBetween: 13,
        centeredSlides: true,
        slidesPerView: 3,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        loop: true,
        loopedSlides: 4,
      },
      visible: false,
      isOpen: false,
      game: this.$route.params.id,
      tabs: [
        { title: this.$t('games.social_title'), key: 'community' },
        { title: this.$t('games.transaction_title'), key: 'chain' },

        { title: this.$t('games.v'), key: 'viewpoint' },
        // { title: this.$t('games.event_title'), key: 'event' },
        // { title: 'IDO', key: 'ido' },
        { title: this.$t('games.strategy_title'), key: 'guide' },
        { title: this.$t('games.strategy'), key: 'strategy' },
        { title: this.$t('games.project_title'), key: 'about' },
        // { title: this.$t('games.volume_title'), key: 'social' },
        // { title: "ROI", key: '7' },
      ],
      info: { screenshots: [] },
    }
  },
  methods: {
    ...mapMutations(['setGameInfo']),
    getMelodyPoster() {
      let img = 'https://res.mymetadata.io/img/melody/' + moment().format('YYYYMMDD') + '_metadata.jpg'
      return new Promise((resolve, reject) => {
        var ImgObj = new Image();
        ImgObj.src = img;
        ImgObj.onload = (res) => {
          this.melodyPoster = img
        }
        ImgObj.onerror = (err) => {
          this.melodyPoster = 'https://res.mymetadata.io/img/melody/' + moment().subtract(1, 'days').format('YYYYMMDD') + '_metadata.jpg'
        }
      });
    },
    hasWhale() {
      this.$http.get('/v2/game/whale/stats', { game_id: this.info.game_id, noEncrypt: true }, (result) => {
        if (result && result.length) {
          //是不是第一次进巨鲸
          this.showWhale = !localStorage.getItem('showWhale')
          //有巨鲸数据 才放出来
          if(this.tabs.findIndex(m=> m.key == 'whale')<0){
            this.tabs.splice(2, 0, { title: this.$t('games.whale'), key: 'whale' })
          }
        }
      })
    },
    play() {
      window.open(this.info.website)
    },
    go() {
      this.showShare = false
      let tags = ''
      this.info.tags.map(m => {
        tags += '&hashtags=' + (this.$store.state.tagData[m] || m)
      })
      let str = `https://twitter.com/intent/tweet?text=The Data-driven GameFi Analytics Platform&url=https://mymetadata.io/games/${this.game}${tags}`
      window.open(str)
    },
    copy() {
      this.showShare = false
      this.$Message.success(this.$t('copy_success'))
      this.utils.linkUrl(window.location.href)
    },
    change(key, i) {
      this.tabActive = key
      this.setScroll(i)
      this.tabIndex = i
      if (key == 'whale') {
        localStorage.setItem('showWhale', 1)
        localStorage.setItem('game_id', this.info.game_id)
      }
      this.$emit('change', key)
      this.$router.push('/games/' + this.game + '?type=' + key)
    },
    openInvestor() {
      this.showInvestor = true
    },
    openStudio() {
      this.$router.push('/studio/' + this.info.studio_info.query_name)
    },
    getData() {
      this.$http.get('/api/v1/meta/game/info', { game_name: this.game }, res => {
        if (res.code != 0) {
          this.$rotuer.push('/')
        }
        let base = res.result
        base.sociallinks = base.sociallinks ? base.sociallinks.filter(m => /twitter|discord|telegram|facebook/.test(m.type)) : []
        base.website && base.sociallinks && base.sociallinks.unshift({ type: 'blog', url: base.website })
        base.screenshot = base.screenshot || []
        let screenshot = []
        let { video } = base
        if (video) {
          if (video.indexOf('youtu') >= 0) {
            let url = video.split('=')[1] || video.split('/').slice(-1)[0] || ''
            url && screenshot.push({ type: 'video', url: 'https://www.youtube.com/embed/' + url, poster: 'https://pbs.mymetadata.io/vi/' + url + '/0.jpg' })
          }
        }
        if(this.h5){
          base.firstCover = base.screenshot.length ? base.screenshot.splice(0, 1)[0] : ''
        }
        base.screenshot.forEach(url => {
          screenshot.push({ type: 'image', url })
        })
        base.screenshots = screenshot
        // console.log(base.screenshots);
        if (base.name == 'Melody') {
          this.getMelodyPoster()
        }
        base.tags = base.tags || []
        if (base.p2e) {
          base.p2e = base.p2e.map(m => m.toUpperCase()).join(' ')
        }
        base.platform = base.platform || []
        this.info = base
        this.setGameInfo(base)
        //判断有没巨鲸tab
        this.hasWhale()
        this.loading = false
      })

    },
    getInvestor() {
      this.$http.get('/api/v1/meta/game/investment/list', { query_name: this.game }, ({ result }) => {
        this.investorData = result.records.map((m, n) => {
          m.query_name = m.investor_name
          m.no = n + 1
          return m
        })
      })
    },
    ScrollTo(target, number = 0, time) {
      if (!time) {
        target.scrollTop = target.scrollLeft = number;
        return number;
      }
      const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
      let spacingInex = time / spacingTime; // 计算循环的次数
      let nowLeft = target.scrollLeft; // 获取当前滚动条位置
      let everLeft = (number - nowLeft) / spacingInex; // 计算每次滑动的距离
      let scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
          spacingInex--;
          this.ScrollTo(target, nowLeft += everLeft);
        } else {
          clearInterval(scrollTimer); // 清除计时器
        }
      }, spacingTime);
    },
    setScroll(i) {
      if (!this.$refs.tabList) return;
      let box = this.$refs.tabList.$el
      let item = box.children[i]//e.target
      if (!item) return;
      let w = box.offsetWidth, w2 = box.scrollWidth
      let { offsetLeft, offsetWidth } = item
      let half = offsetLeft + (offsetWidth / 2)
      if (half > w / 2) {
        // box.scrollLeft = half - w / 2
      } else {
        // box.scrollLeft = 0
      }
      this.ScrollTo(box, half > w / 2 ? half - w / 2 : 0, 300)
    },
  }
}
