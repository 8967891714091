//
//
//
//
//
//
//
//
//
//
//
//
//
//
 
export default {
  methods: {
    back() {
      window.history.back()
    },
    home() {
      window.location.href = '/'
    }
  }
}
