//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/guild-detail.less'
import { mapGetters } from 'vuex'
export default {
  layout: 'index',
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  data() {
    return {
      isOpen: false,
      data: {},
      subject_list: [],
      guildData: [],
    };
  },
  mounted() {
    this.name = this.$route.params.id
    // this.getData()
    // this.getGuilds()
  },
  async asyncData({ $http, route }) {
    let query_name = route.params.id
    let guids = await $http.get('/api/v1/meta/guild/list', { limit: 10, offset: 0 })
    let guildData = []
    return $http.get('/api/v1/meta/guild/info', { query_name }).then(({ result }) => {
      if (result.social) {
        result.social = result.social.filter(h => h.title != 'youtube')
        if (result.website) {
          result.social.unshift({ type: 'blog', url: result.website })
        }
      }
      if (guids.result.records) {
        guildData = guids.result.records.filter(m => m.name != result.name)
      }
      return {
        guildData,
        subject_list: result.subject_list,
        data: result
      }
    })
  },
  methods: {
    changeOpen(item) {
      this.$set(item, 'isopen', !item.isopen)
    },
    getGuilds() {
      this.$http.get('/api/v1/meta/guild/list', { limit: 10, offset: 0 }, ({ result }) => {
        this.guildData = result.records.filter(m => m.name != this.name)
      })
    },
  },
};
