//
//
//
//
//
//
//
//

export default {
  name: 'home-tabs',
  data() {
    return {
    }
  },
  props: {
    activeIndex: { Number, String },
    tabs: Array,
  },
  mounted() {
    this.$nextTick(() => {
      this.setScroll(this.activeIndex || 0)
    })
  },
  methods: {
    ScrollTo(target, number = 0, time) {
      if (!time) {
        target.scrollTop = target.scrollLeft = number;
        return number;
      }
      const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
      let spacingInex = time / spacingTime; // 计算循环的次数
      let nowLeft = target.scrollLeft; // 获取当前滚动条位置
      let everLeft = (number - nowLeft) / spacingInex; // 计算每次滑动的距离
      let scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
          spacingInex--;
          this.ScrollTo(target, nowLeft += everLeft);
        } else {
          clearInterval(scrollTimer); // 清除计时器
        }
      }, spacingTime);
    },
    setScroll(i) {
      let box = this.$refs.tabList
      let item = box.children[i]//e.target
      let w = box.offsetWidth, w2 = box.scrollWidth
      let { offsetLeft, offsetWidth } = item
      let half = offsetLeft + (offsetWidth / 2)
      // console.log(half, w / 2, offsetLeft, offsetWidth)
      if (half > w / 2) {
        // box.scrollLeft = half - w / 2
      } else {
        // box.scrollLeft = 0
      }
      this.ScrollTo(box, half > w / 2 ? half - w / 2 : 0, 300)
    },
    changeTab(item, i) {
      if (i == this.activeIndex) return
      this.setScroll(i)
      this.$emit('changeTab', i)
    }
  }
}
