//
//
//
//
//
//
//

import sprite from '@/assets/svg/web.svg'
export default {
  name: 'Chain',
  data() {
    return {
      sprite
    }
  },
  props: {
    name: String,
    width: {
      type: [String, Number],
      default: '1em'
    },
    height: {
      type: String,
      default: '1em'
    }
  }
}
