
import '~/assets/less/ranking.less'
import { mapGetters } from 'vuex'
import { ChevronDown } from "kui-icons";
export default {
  layout: 'index',
  props: {
    isMain: Boolean,
    limit: Number
    //投资机构页面
  },
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      hasChange: '',
      search: [],
      loading: false,
      page: 1,
      total: 0,
      data: [],
      size: this.isMain ? 10 : 30,
      item: {},
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('social.col_game'), key: 'game_name' },
        { title: this.$t('social.col_volume'), key: 'social_volume', render: (h, p) => h('span', this.utils.changeUnit(p.social_volume)) },
        { title: this.$t('social.col_volume_7d'), key: 'social_volume_7d' },
        { title: this.$t('social.col_engagement'), key: 'social_engagement', render: (h, p) => h('span', this.utils.changeUnit(p.social_engagement)) },
        { title: this.$t('social.col_kol'), key: 'mentions_kol', render: (h, p) => h('span', this.utils.changeUnit(p.mentions_kol)) },
      ],
      currentDay: 0,
      h5data: [],
      sortKey: ['social_volume', 'social_engagement', 'mentions_kol'],
      h5columns: [
        { title: '#', key: 'no', align: 'right', width: 37 },
        { title: this.$t('social.col_game'), key: 'game_name', className: 'chain' },
        { title: '', key: 'filter', },
      ],
      order: 'desc',//排序
      filterType: 'social_volume',
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    h5filterChange(item, i) {
      this.filterType = item.key
      this.page = 1
      this.getData()
    },
    filterChange({ key, order }) {
      this.sortKey.map((m, i) => {
        if (m == key) {
          this.search = order ? [key + (order == 'asc' ? '_1' : '_2')] : []
        } else {
          if (this.h5) {
            this.h5columns[i + 2]._order = ''
          } else {
            this.columns[i + 2]._order = ''
          }
        }
      })
      this.page = 1
      this.getData()
    },
    onHandlePageSize({ value }) {
      this.size = value
      this.initPage()
    },
    onHandlePage(page) {
      this.page = page;
      this.getData()
    },
    initPage() {
      this.page = 1
      this.$refs.page.toPage(1)
    },
    getData(type) {
      let { size, search, page, filterType } = this
      this.loading = true
      let sortList = JSON.stringify(search)
      this.$http.get('/api/v1/meta/kol/list', { limit: size, offset: (page - 1) * size, order_list: sortList }, ({ result }) => {
        this.total = result.count
        result.records.map((m, i) => {
          m.no = i + ((page - 1) * size) + 1;
          m.filter = m[filterType]
          return m
        })
        if (page == 1) this.data = [], this.h5data = []
        if (type) {
          this.h5data = this.h5data.concat(result.records)
        } else {
          this.data = result.records
          this.h5data = result.records
        }
        this.hasChange = Date.now();
        this.loading = false
      }, err => {
        this.loading = false
      })
    },
    seeMore() {
      this.page++
      this.getData('is_h5')
    },
  },
  render() {
    let { data, columns, total, h5, h5columns, h5data, isMain, page, size, pageRanges, h5filterChange } = this
    return (
      <div class="ranking page-nft page-social">
        <div class="rank-title-wrap">
          <div class="title"><span>{this.$t('social.rank_title')}</span></div>
          <div class="sub-title">
            <span class="sub-desc">{this.$t('social.rank_sub_title')}</span>
          </div>
        </div>
        {h5 ?
          <SocialRankH5 data={h5data} columns={h5columns} total={total} onMore={this.seeMore} onFilter={h5filterChange} /> :
          <SocialRankPc data={data} columns={columns} total={total} onPage={this.onHandlePage} onSize={this.onHandlePageSize} change={this.hasChange} onFilter={this.filterChange} />}

        {isMain && <a class="load-more flex-base" href="/social/rank">{this.$t('com.view_all')}<Icon type={ChevronDown} size="20" /></a>}

        {
          !h5 && !isMain ?
            <div class="page-wrap">
              <Page total={total} current={page} pageSize={size} size="small" onChange={this.onHandlePage} ref="page"></Page>
              <div class="page-sizer flex-base">
                <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
                <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
              </div>
            </div> : null
        }
        {
          total > 30 && h5 && !isMain ?
            data.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
            : null
        }
      </div>
    )
  }
};
