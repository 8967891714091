//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
export default {
  props: {
    item: Object
  },
  watch: {
    item: {
      handler(val) {
        this.datas = val.data
        this.renderKline()
      },
      deep: true
    }
  },
  data() {
    return {
      up: false,
      datas: this.item.data || [],
      value: '--',
      rate: '--',
    }
  },
  mounted() {
    window.addEventListener('resize', this.renderKline)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.renderKline)
    clearTimeout(this.timer)
  },
  methods: {
    renderKline() {
      if (!this.datas.length) return
      let { datas } = this
      let v = datas.slice(-2)
      this.value = this.utils.changeUnit1(v[1])
      this.up = v[1] > v[0]
      this.rate = v[1] ? ((((v[1] - v[0]) / v[1]) * 100).toFixed(2) + '%') : '--'
    },
  }
}
