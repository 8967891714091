//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './index.less'
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      list: [
        { label: '中文', icon: 'zh', value: 0 },
        { label: 'EN', icon: 'en', value: 1 },
      ]
    };
  },
  computed: {
    ...mapGetters(['lang', 'theme'])
  },
  methods: {
    ...mapMutations(['setLang']),
    setLangs({ key }) {
      this.setLang(key)
      this.$i18n.locale = key
      this.utils.setCookie('lang', key, 365)
      location.reload()
    },

  },
};
