
import '~/assets/less/ranking.less'
export default {
  layout: 'index',
  render() {
    return (
      <div class="page-social">
        <SocialRank isMain={true} />
        <div class="social-bottom">
          <div class="social-left">
            <Socialkol isMain={true} type="check" />
          </div>
          <div class="social-right">
            <Socialkol isMain={true} type="kol" />
          </div>
        </div>
      </div>
    )
  }
};
