
import '../whale/index.less'
import { mapGetters } from 'vuex'
export default {
  props: {
    limit: { type: Number, default: 10 },
    showViewall: Boolean,
    showPage: Boolean,
    datas: [Array],
    totals: Number,
  },
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      current: '',
      tabs: [
        { name: this.$t('whale.whale_growth'), value: 'active_user' },
        { name: this.$t('whale.whale_increase'), value: 'increase' },
        { name: this.$t('whale.whale_decline'), value: 'decline' },
      ],
      active_user: [
        { title: this.$t('whale.col_name'), key: 'name' },
        { title: this.$t('whale.col_growth_new'), key: 'rank' },
        { title: this.$t('whale.col_growth_change'), key: 'increased_num', },
      ],
      increase: [{ title: this.$t('whale.col_name'), key: 'name' },
      { title: this.$t('whale.col_increase_addcount'), key: 'rank' },
      { title: this.$t('whale.col_increase_addprecent'), key: 'increased_num', },],
      decline: [{ title: this.$t('whale.col_name'), key: 'name' },
      { title: this.$t('whale.col_decline_minuscount'), key: 'rank' },
      { title: this.$t('whale.col_decline_minusprecent'), key: 'increased_num', }
      ],
      h5Colume: [],
      type: 'active_user',
    };
  },
  methods: {
    changeTab(i) {
      if (this.current == i) return
      this.current = i
      this.$refs.whaleTable.page = 1
      this.type = this.tabs[i].value
      this.$nextTick(() => {
        this.$refs.whaleTable.getData()
      })
    },
  },
  render() {
    let { active_user, increase, decline, tabs, changeTab, current, h5, type } = this
    let h5Colume = [...this[type]]
    return (
      <div class="whale-rank">
        {h5 ? <HomeTabs tabs={tabs} onChangeTab={changeTab} activeIndex={current} /> : null}
        <div class="whale-tables">
          <HomeWhaleTable columns={h5Colume} type={type} ref="whaleTable" size={10} showPage={true} />
          {h5 ? null : <HomeWhaleTable columns={increase} type='increase' size={10} showPage={true} />}
          {h5 ? null : <HomeWhaleTable columns={decline} type='decline' size={10} showPage={true} />}
        </div>
      </div>
    )
  }
};
