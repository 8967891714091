//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AlertCircle } from "kui-icons";
export default {
  props: {
    data: Array,
    columns: Array,
    loading: Boolean,
    days: Number
  },
  data() {
    return {
      AlertCircle,
      descShow: false,
      list: [],
      title: '',
    }
  },
  methods: {
    showList(query_name, query_types, count, title) {
      this.title = title
      if (!count) return
      this.$http.get('/api/v1/meta/game/kol/list', { query_name, query_types, days: this.days }, ({ result }) => {
        this.list = result.records
        this.descShow = true
      })
    },
    toTT({ tid }) {
      open(`https://twitter.com/${tid}`)
    },
    rowClick(item) {
      if (!item.is_online) return this.$Message.info('暂未收录');
      window.location.href = '/games/' + item.game_name
    },
  }
}
