//
//
//
//
//

export default {
  data() {
    return {
    };
  },
  mounted() {
    this.game = this.$route.params.id
  },
  methods: {

  },
};
