//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
    
import { mapGetters } from 'vuex'
import { ChevronForward } from 'kui-icons'
export default {
  data() {
    return {
      ChevronForward,
      loading1: true,
      loading2: true,
      date_social: 30,
      volume_count: 0,
      engagement_count: 0,
      kol_count: 0,
      leftColumns: [
        { title: '#', key: 'no', align: 'right', width: 40 },
        { title: this.$t('social.col_name'), key: 'game_name', width: 175, },
        { title: this.$t('social.col_engagement'), key: 'total_engagements', render: (h, p) => h('span', this.utils.changeUnit(p.total_engagements)), width: 150, },
        { title: this.$t('social.col_tweet'), key: 'tweets', render: (h, p) => h('span', this.utils.changeUnit(p.tweets)) },
        { title: this.$t('social.col_like'), key: 'total_likes', render: (h, p) => h('span', this.utils.changeUnit(p.total_likes)) },
        { title: this.$t('social.col_reply'), key: 'total_raplies', render: (h, p) => h('span', this.utils.changeUnit(p.total_raplies)) },
        { title: this.$t('social.col_retweet'), key: 'total_retweets', render: (h, p) => h('span', this.utils.changeUnit(p.total_retweets)) },
        { title: this.$t('social.col_quote'), key: 'total_quotes', render: (h, p) => h('span', this.utils.changeUnit(p.total_quotes)) },
      ],
      rightColumns: [
        { title: '#', key: 'no', align: 'right', width: 40 },
        { title: this.$t('social.col_name'), key: 'game_name' },
        { title: this.$t('social.col_follower1'), key: 'followers_count', render: (h, p) => h('span', this.utils.changeUnit(p.followers_count)) },
      ],
      days: 30,
      kolDays: 30,
      engagement: [],
      follower: [],
      data: [],
      size: 10,
      page: 1,
      total: 0,
      chart: '',
      date: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 90, label: '3M' },
        { value: 365, label: 'All' },
      ],
      dateText:{
        7:'7D',
        30:'30D',
        90:'3M',
        365:'ALL',
      },
      gridText: {
        'Volume': this.$t('social.volume'),
        'Engagement': this.$t('social.engagement'),
        'Kol': this.$t('social.kol'),
      },
      xAxis: [],
      social_volume_list: [],
      social_engagement_list: [],
      mentions_kol_list: [],
      option: {},
      showSocial: true,
      descShow: false,
      tabs: [
        { name: this.$t('com.all'), value: '' },
        { name: 'WHALE', value: 'WHALE' },
        { name: 'PLATFORM', value: 'PLATFORM' },
        { name: 'EXCHANGE', value: 'EXCHANGE' },
        { name: 'BUILDER', value: 'BUILDER' },
        { name: 'VC', value: 'VC' },
        { name: 'PROJECT', value: 'PROJECT' },
        { name: 'NFT', value: 'NFT' },
      ],
      current: 0,
      list: [],
      _list: [],
      WHALE_list: [],
      PLATFORM_list: [],
      EXCHANGE_list: [],
      BUILDER_list: [],
      VC_list: [],
      PROJECT_list: [],
      NFT_list: [],

    }
  },
  computed: {
    ...mapGetters(['h5', 'lang', 'theme'])
  },
  mounted() {
    this.game = this.$route.params.id
    if (this.h5) {
      this.leftColumns[0].fixed = 'left'
      this.leftColumns[1].fixed = 'left'
      // this.$refs.lefttable.$refs.table.width = 900
    }
    this.initMap()
    this.getData()
    this.getFollowers()
    this.getTweets()
  },
  methods: {
    changeTab(i) {
      if (i == this.current) return
      this.current = i
      this.content_type = this.tabs[i].value
      this.list = this[this.content_type + '_list']
    },
    seeAll() {
      this.current = 0
      this.$http.get('/api/v1/meta/game/kol/followers', { query_name: this.game, is_all: 1 }, ({ result }) => {
        this.tabs.map(h => {
          h.name = h.value || this.$t('com.all')
          let list = []
          result.records.map(m => {
            if ((m.content_type || []).findIndex(n => h.value == n) > -1) {
              list.push(m)
            }
          })
          this[h.value + '_list'] = list
          h.name = h.name + '(' + (h.value ? this[h.value + '_list'].length : result.records.length) + ')'
        })
        this.list = result.records
        this._list = result.records
        this.descShow = true
      })
    },
    toTT({ tid }) {
      open(`https://twitter.com/${tid}`)
    },
    getFollowers() {
      this.loading2 = true
      this.$http.get('/api/v1/meta/game/kol/followers', { query_name: this.game }, res => {
        this.loading2 = false
        this.follower = res.result.records.map((m, i) => {
          // m.avatar = m.avatar.replace('https://pbs.twimg.com/', 'https/res.mymetadata.io/')
          m.no = i + 1
          return m
        })
      })
    },
    getTweets() {
      this.loading1 = true
      this.$http.get('/api/v1/meta/game/kol/tweets', { query_name: this.game, days: this.kolDays }, res => {
        this.loading1 = false
        this.engagement = res.result.records.map((m, i) => {
          m.no = i + 1
          return m
        })
      })
    },
    daysChange(days) {
      this.kolDays = days
      this.getTweets()
    },
    getData() {
      let { game, days } = this
      this.$http.get('/api/v1/meta/game/kol/tweets/daily', { query_name: game, days }, ({ result }) => {
        if (!result.date_list.length && days == 30) {
          this.showSocial = false
        }
        let { date_list, social_engagement_list, social_volume_list, mentions_kol_list } = result
        date_list = date_list.reverse().map(x => x.replace(/-/g, '/'))
        social_engagement_list = social_engagement_list.reverse()
        social_volume_list = social_volume_list.reverse()
        mentions_kol_list = mentions_kol_list.reverse()
        this.xAxis = date_list
        this.social_engagement_list = social_engagement_list
        this.mentions_kol_list = mentions_kol_list
        this.social_volume_list = social_volume_list
        this.volume_count = this.utils.getCount(social_volume_list, days)
        this.engagement_count = this.utils.getCount(social_engagement_list, days)
        this.kol_count = result.sum_mentions_kol
        this.renderMap(date_list, social_engagement_list, social_volume_list, mentions_kol_list)
      })

    },
    renderMap(date_list, social_engagement_list, social_volume_list, mentions_kol_list) {
      this.chart.hideLoading()
      let option = {
        xAxis: { data: date_list.slice(-this.days) },
        series: [{
          data: social_engagement_list.slice(-this.days),
        }, {
          data: social_volume_list.slice(-this.days),
        }, {
          data: mentions_kol_list.slice(-this.days),
        }]
      }
      this.chart.setOption(option)
    },
    initMap() {
      let { h5, theme } = this
      let leftColor = theme == 'dark' ? '#717171' : '#999'
      let dashedColor = theme == 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#dddddd60'

      this.option = {
        graphic: [{
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: h5 ? 100 : 200, height: h5 ? 19 : 38 }
        },
        {
          rotation: Math.PI / 2,
          type: 'text', left: '5px', top: 'middle', style: { fill: leftColor, text: '', font: '14px "Inter"' }
        },
        {
          rotation: Math.PI / -2,
          type: 'text', right: '5px', top: 'middle', style: { fill: leftColor, text: '', font: '14px "Inter"' }
        }
        ],
        tooltip: { extraCssText: "z-index:1", trigger: 'axis', backgroundColor: '#121326', borderWidth: 0, textStyle: { color: '#fff' }, axisPointer: { label: { backgroundColor: '#6a7985' } } },

        grid: { borderColor: 'transparent', /*backgroundColor: '#F9F9FB',*/ show: true, left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        xAxis: {
          type: 'category', data: [],
          axisLine: { show: false },
          axisLabel: { color: "#666", formatter: v => moment(new Date(v)).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
        legend: { data: [], icon: 'circle', bottom: 0, textStyle: { color: '#999', fontSize: 12 }, itemHeight: 8, },
        yAxis: [
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor,
                width: 1,
                type: 'dashed'
              }
            },
          },
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: false,
              lineStyle: {
                color: dashedColor,
                width: 1,
                type: 'dashed'
              }
            },
          },
        ],
        series: [
          { type: 'line', name: this.gridText['Engagement'], itemStyle: { color: 'orange' }, symbol: 'circle', yAxisIndex: 0 },
          { type: 'bar', name: this.gridText['Volume'], symbol: 'circle', yAxisIndex: 1, barMaxWidth: 15, itemStyle: { color: '#7266FF' }, },
          { type: 'bar', name: this.gridText['Kol'], itemStyle: { color: 'cyan' }, symbol: 'circle', yAxisIndex: 1, barMaxWidth: 15, }
        ]
      };
      this.chart = echarts.init(document.getElementById('map_social'))
      this.chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' })
      this.chart.setOption(this.option)
      window.addEventListener('resize', () => {
        this.chart.resize()
      })
    },
    dateChange() {
      this.days = this.date_social 
      this.getData()
    },
  },
};
