//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/guild-detail.less'
import { mapGetters } from 'vuex'
export default {
  layout: 'index',
  computed: {
    ...mapGetters(['lang'])
  },
  props: {
    loading: Boolean,
    data: Array,
    type: String
  },
  data() {
    return {
      titles: {
        guild: this.$t('guild.list_title'),
        investor: this.$t('investor.list_title'),
        studio: this.$t('studio.list_title'),
      }
    }
  },
}
