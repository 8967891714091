//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Item from './item.vue'
import HomeItem from './homeitem.vue'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
import './index.less'
export default {
  components: { Item, HomeItem, Swiper, SwiperSlide, directive },
  props: {
    isHome: Boolean,
    type: String
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOption: {
        direction: "vertical",
        loop: false,
        autoplay: {
          delay: 3500
        },
      },

      total: { title: this.$t('kline.total'), tip: this.$t('kline.total_desc'), data: [], date: [] },
      active: { title: this.$t('kline.active'), tip: this.$t('kline.active_desc'), data: [], date: [] },
      volume: { title: this.$t('kline.volume'), tip: this.$t('kline.volume_desc'), data: [], date: [] },
      transactions: { title: this.$t('kline.transactions'), tip: this.$t('kline.trans_desc'), data: [], date: [] },
    }
  },
  mounted() {
    this.getUser()
    this.getTrans()
    this.getVolumes()
    this.getGameCount()
  },
  methods: {
    goKline() {
      window.location.href = '/rank/dashboard'
    },
    getUser() {
      this.$http.get('/api/v1/meta/market/query_users', { timestamp: parseInt(Date.now() / 1000) }, ({ result }) => {
        let datas = result.sort((x, y) => new Date(x[0]) - new Date(y[0]))
        this.$emit('change', { 'users': datas })
        let m = datas.slice(-30)
        this.active.data = m.map(x => x[1])
        this.active.date = m.map(x => x[0])
      })
    },
    getVolumes() {
      this.$http.get('/api/v1/meta/market/query_volumes', { timestamp: parseInt(Date.now() / 1000) }, ({ result }) => {
        let datas = result.sort((x, y) => new Date(x[0]) - new Date(y[0]))
        datas = datas.filter(x => x[1])
        this.$emit('change', { 'volumes': datas })
        let m = datas.slice(-30)
        this.volume.data = m.map(x => x[1])
        this.volume.date = m.map(x => x[0])
      })
    },
    getTrans() {
      this.$http.get('/api/v1/meta/market/query_transactions', { timestamp: parseInt(Date.now() / 1000) }, ({ result }) => {
        let datas = result.sort((x, y) => new Date(x[0]) - new Date(y[0]))
        datas = datas.filter(x => x[1])
        this.$emit('change', { 'transactions': datas })
        let m = datas.slice(-30)
        this.transactions.data = m.map(x => x[1])
        this.transactions.date = m.map(x => x[0])
      })
    },
    getGameCount() {
      this.$http.get('/api/v1/meta/market/query_games', { timestamp: parseInt(Date.now() / 1000) }, ({ result }) => {
        let datas = result.sort((x, y) => new Date(x[0]) - new Date(y[0]))
        datas = datas.slice(0, datas.length - 1)
        this.$emit('change', { 'games': datas })
        let m = datas.slice(-30)//.slice(0,29)
        this.total.data = m.map(x => x[1])
        this.total.date = m.map(x => x[0])
      })
    }
  }
}
