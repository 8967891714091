//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CaretDown } from "kui-icons";
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {

  computed: {
    ...mapGetters(['h5', 'theme'])
  },
  data() {
    return {
      hide: true,
      legendIndex: '',
      vol_pieColors: [],
      count_pieColors: [],
      tansData: [{}],
      vol_count: 0,
      vol_page: 1,
      vol_size: 15,
      columns: [
        { title: '#', key: 'no', width: 50 },
        { title: this.$t('games.col_vol_add'), key: 'addr', width: 400 },
        { title: this.$t('games.col_count'), key: 'trans_amount', render: (h, p) => h('span', this.utils.changeUnit(p.trans_amount) + ' ' + this.symbol), width: this.h5 ? 150 : 200, },
        { title: this.$t('games.col_value'), key: 'trans_vol', width: 200, render: (h, p) => h('span', '$' + this.utils.changeUnit(p.trans_vol)) },
        { title: this.$t('games.col_time'), key: 'last_at', render: (h, p) => h('span', moment(p.last_at * 1000).format("YYYY-MM-DD HH:mm:ss")), width: this.h5 ? 80 : 300 },
      ],
      loading: false,
      volData: [],
      CaretDown,
      colors: ['orange', 'cyan', 'green', 'magenta', 'blue'],
      series_price: [],
      date: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 90, label: '3M' },
        { value: 365, label: 'All' },
      ],
      date1: [
        { value: 1, label: '1D' },
        { value: 7, label: '7D' },
        // { value: 30, label: '30D' },
        // { value: 90, label: '3M' },
        { value: 365, label: 'All' },
      ],
      date2: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 90, label: '3M' },
        { value: 365, label: 'All' },
      ],
      dateText: {
        7: '7D',
        30: '30D',
        90: '3M',
        365: 'ALL',
      },
      date_users: 30,
      date_volume: 30,
      date_transactions: 30,
      date_balance: 30,
      users_total: '',
      volume_total: '',
      transactions_total: '',
      balance_total: '',
      game: 'alien-worlds-1',
      price_total: '0.00',
      symbols: [],
      gridText: {
        'users': this.$t('games.active_user'),
        'volume': this.$t('games.volume'),
        'transactions': this.$t('games.transactions'),
        'balance': this.$t('games.balance'),
      },

      // ranks: {
      //   balance_rank: '-',
      //   trans_rank: '-',
      //   user_rank: '-',
      //   volume_rank: '-',
      // },
      showChaindata: true,
      //暂时
      chains: [],
      xPieVol: [],
      xLineVol: [],
      xPieCount: [],
      xLineCount: [],
      volPie: [],
      volLine: [],
      countPie: [],
      countLine: [],
      list_trans_vol_legend: [],
      list_trans_count_legend: [],
      list_trans_vol_list: [],
      list_trans_count_list: [],
      date_vol_pie: 1,
      date_vol_line: 7,
      date_count_pie: 1,
      date_count_line: 7,
      select_vol_pie: '',
      select_vol_line: '',
      select_count_pie: '',
      select_count_line: '',
      fromType: 'from',
      select_from: '',
      fromto: [
        { value: 'from', label: 'From' },
        { value: 'to', label: 'To' },
      ],
    }
  },
  mounted() {
    let game = this.$route.params.id
    game = game == 'game' || !game ? 'alien-worlds-1' : game;
    this.game = game
    // this.getrank()
    this.init()
  },

  methods: {
    getWalken() {
      let game_name = this.game
      if (game_name != 'walken') return;
      this.$http.get(`/api/v1/meta/game/special/statistics`, { game_name }).then(({ result }) => {
        this.rerender(result)
      })
    },
    rerender({ info, multiple_active_users }) {
      let xAxis = info.map(x => x.date)
      let series_users = [], series_volume = [], series_transactions = [];
      info.forEach(x => {
        let i = x.data
        series_users.push(i.active_users)
        series_volume.push((i.volume * 1).toFixed(2))
        series_transactions.push(i.transfer_count)
      })
      this.showChaindata = true
      this.walken_users = multiple_active_users
      this.users_total = multiple_active_users.days_30
      this.volume_total = series_volume.slice(-30).reduce((a, b) => a * 1 + b * 1, 0)
      this.transactions_total = series_transactions.slice(-30).reduce((a, b) => a + b, 0)

      // this.initMap()
      this.renderMap('users', xAxis, series_users)
      this.renderMap('volume', xAxis, series_volume)
      this.renderMap('transactions', xAxis, series_transactions)



      // this.renderMap('balance', xAxis, series_balance)
    },
    // getrank() {
    //   this.$http.get('/api/v1/meta/game/statistics/rank', { game_name: this.game }, res => {
    //     this.ranks = res.result
    //   })
    // },
    init() {
      this.date_users = 30
      this.date_volume = 30
      this.date_transactions = 30
      this.date_balance = 30;
      this.date_vol_pie = 1
      this.date_vol_line = 7
      this.date_count_pie = 1
      this.date_count_line = 7

      this.initMap()
      this.getSymbol()
    },
    initMap() {
      let { h5, theme } = this
      let leftColor = theme == 'dark' ? '#717171' : '#999'
      let dashedColor = theme == 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#dddddd60'
      let option = {
        graphic: [{
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: h5 ? 100 : 200, height: h5 ? 19 : 38 }
        },
        {
          rotation: Math.PI / 2,
          type: 'text', left: '5px', top: 'middle', style: { fill: leftColor, text: 'Token Price ($)', font: '14px "Inter"' }
        },
        {
          rotation: Math.PI / -2,
          type: 'text', right: '5px', top: 'middle', style: { fill: leftColor, text: 'Token Price ($)', font: '14px "Inter"' }
        }
        ],
        tooltip: { extraCssText: "z-index:1", trigger: 'axis', backgroundColor: '#121326', borderWidth: 0, textStyle: { color: '#fff' }, },
        grid: { borderColor: 'transparent', /*backgroundColor: '#F9F9FB',*/ show: true, left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        xAxis: {
          type: 'category', data: [],
          axisLine: { show: false },
          axisLabel: { color: "#666", formatter: v => moment(new Date(v)).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
        legend: { data: [], icon: 'circle', bottom: 0, textStyle: { color: '#999', fontSize: 12 }, itemHeight: 8, },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => {
                if (v > 0 && v < 1 || v < 0) {
                  return parseFloat(this.utils.changeUnit1(v)).toFixed(2)
                } else {
                  return this.utils.changeUnit1(v)
                }
                // this.utils.changeUnit1(v)
              }

            },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor,
                width: 1,
                type: 'dashed'
              }
            },
            min: value => value.min,
            max: value => value.max,
          },
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor,
                width: 1,
                type: 'dashed'
              }
            },
          }
        ],
        series: [
          {
            name: '',
            yAxisIndex: 0,
            data: [], type: 'bar',
            barMaxWidth: 15,
            barMinHeight: 8,
            itemStyle: { color: '#7266FF' }
          },
          { type: 'line', }, { type: 'line', }, { type: 'line', }, { type: 'line', },
        ]
      };
      ['users', 'volume', 'transactions', 'balance', 'vol_pie', 'vol_line', 'count_pie', 'count_line'].forEach(id => {
        let ele = document.getElementById('map_' + id)
        if (!ele) return;
        this['char_' + id] = chart
        let chart = echarts.init(ele)
        chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' })
        this['char_' + id] = chart
        option.graphic[1].style.text = this.gridText[id]
        if (id == 'vol_pie' || id == "vol_line" || id == "count_pie" || id == "count_line") {
          option.graphic[2].style.text = ''
        }
        option.series[0].name = this.gridText[id]
        let line = {
          type: 'value',
          axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13 },
          splitLine: { show: false, },
        }
        option.yAxis[1] = Object.assign({}, line)
        option.yAxis[2] = Object.assign({}, line, { axisLabel: { show: false } })
        // option.yAxis[3] = Object.assign({}, line, { axisLabel: { show: false } })


        chart.setOption(option)
        window.addEventListener('resize', () => {
          chart.resize()
        })
      })
    },
    renderMap(id, xAxis = [], series = [], index = 0) {
      let chart = this['char_' + id]
      chart.hideLoading()

      let count = 365//new Date(y, m, 0).getDate() //本月有多少天
      let day = 30
      if (xAxis.length < count) {
        let t = count - xAxis.length
        let j = xAxis.slice(0, 1)[0] || Date.now();
        let f = moment(new Date(j)).subtract(t, 'days')
        let nx = []
        for (let i = 0; i < t; i++) {
          nx.push(f.format('YYYY/MM/DD'))
          f.add(1, 'days')
        }
        xAxis = [].concat(nx, xAxis)
      }
      //series
      if (series.length < count) {
        let ns = new Array(count - series.length).fill('0')
        series = [].concat(ns, series)
      }

      xAxis = xAxis.map(x => x.replace(/-/g, '/'))

      let symbol_series = [].concat([], this.symbol_series)
      symbol_series = symbol_series.map(x => {
        x.data = this['series_' + x.name].slice(-day)
        return x
      })
      let option = {
        xAxis: { data: xAxis.slice(-day) },
        series: [{
          yAxisIndex: index,//0,
          data: series.slice(-day)
        }, ...symbol_series],
      }
      this['xAxis_' + id] = xAxis
      this['series_' + id] = series
      chart.setOption(option)
    },

    dateChange(key) {
      let xAxis = this['xAxis_' + key]
      let datas = this['series_' + key]
      let day = this['date_' + key] * -1

      let symbol_series = [].concat([], this.symbol_series)
      symbol_series = symbol_series.map(x => {
        x.data = this['series_' + x.name].slice(day)
        return x
      })
      let day_data = datas.slice(day)
      this['char_' + key].setOption({
        xAxis: { data: xAxis.slice(day) },
        series: [
          { data: day_data, yAxisIndex: 0 },
          ...symbol_series
        ]
      })

      let value = day_data.reduce((a, b) => a * 1 + b * 1, 0)
      if (this.game == 'walken' && key == 'users') {
        let walken = { 7: this.walken_users.days_7, 30: this.walken_users.days_30, 365: this.walken_users.days_all }
        this.users_total = walken[day * -1]
      } else {
        this[key + '_total'] = this.utils.changeUnit1(value)
      }
      this['tip_' + key] = this.utils.changeUnit(value)
    },
    dateChange1(key, chartType, mapKey, day) {
      let data = this.tansData.filter(m => m.chain + '-' + m.address == this['select_' + mapKey])[0], list = []
      if (chartType == 'pie') {
        day = this['date_' + mapKey]
      }
      list = this.setValue(data, key, chartType, day || 7)
      this.renderChart(mapKey, list, day)
    },
    renderChart(id, series = [], days) {
      let chart = this['char_' + id]
      chart.hideLoading()
      let option = {}
      if (id == 'vol_pie' || id == 'count_pie') {
        option.graphic = {
          right: this.h5 ? '43%' : '50%',
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: this.h5 ? 100 : 150, height: this.h5 ? 19 : 28.5 }
        }
        option.tooltip = {
          confine: true,
          trigger: 'item',
          formatter: (p) => {
            let x = p.data, str = ''
            let volTooltip = this.$t('games.trans_vol_tooltip1') + x.name + this.$t('games.trans_vol_tooltip2')
            let countTooltip = this.$t('games.trans_count_tooltip1') + x.name + this.$t('games.trans_count_tooltip2')
            str = `<div  style="">
                    <div  style=";width:100%;">
                      ${p.marker}${id == 'vol_pie' ? volTooltip : countTooltip}
                    </div>
                    <div style="display:flex;justify-content:space-between;">
                       <span>${this.utils.changeUnit(x.value)}</span>
                      <span style="font-size:14px;color:${p.color};margin-left:3px">${x.precent}</span>
                    </div>
            </div>`
            return str
          },
        }
        option.series = [{
          name: '',
          type: 'pie',
          radius: ['55%', '90%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          data: series
        }]
        let colors = chart.getModel().option.color
        this[id + 'Colors'] = series.map((m, n) => {
          colors.map((h, j) => {
            if (n == j) {
              m.color = h
            }
          })
          return m
        })
        chart.on('mouseover', (item) => {
          this.legendIndex = item.dataIndex
        })
        chart.on('mouseout', (item) => {
          this.legendIndex = ''
        })
      } else {
        option.xAxis = {
          type: 'category', data: id == 'vol_count' ? this.xLineVol.slice(-days) : this.xLineCount.slice(-days),
          axisLine: { show: false },
          axisLabel: { color: "#666", formatter: v => v, fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
          option.series = series
      }
      chart.setOption(option)
    },
    legendMouseOver(id, i) {
      this['char_' + id].dispatchAction({
        type: 'highlight',
        dataIndex: i
      });
      this['char_' + id].dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: i,
      });
    },
    legendMouseOut(id, i) {
      this['char_' + id].dispatchAction({
        type: 'downplay',
        dataIndex: i
      });
      this['char_' + id].dispatchAction({
        type: "hideTip",
      });
    },
    setValue(data, key, type, days) {
      let list = [], keys = [], values = []
      if (type == 'pie') {
        days = (days - 0) > 7 ? 7 : days
        keys = Object.keys(data[key]['day_' + days])
        values = Object.values(data[key]['day_' + days])
        let total = values.reduce((a, b) => a + b, 0)
        let remain = 0
        keys.map((m, n) => {
          let c = parseFloat(this.utils.changeUnit1(values[n] * 100 / total))
          if (n == keys.length - 1) {
            c = parseFloat(this.utils.formatNum(100, remain, '-', 2))
          } else {
            c = c < 1 && c > 0 ? (c.toFixed(2) - 0) : c
            remain += c
          }
          list.push({ name: m, value: values[n], precent: c + '%' })
        })
      } else if (type == 'line') {
        let l = data[key].slice(-days).map(m => m['day_1' + (key == 'list_trans_vol' ? '_trans_vol' : '_trans_count')])
        this[key + '_legend'].map((m, n) => {
          let arr = []
          l.map(h => {
            arr.push(h[m])
          })
          list.push({
            name: m,
            type: 'line',
            data: arr,
            total: arr.reduce((a, b) => a + b, 0),
            color: this.colors[n],
            itemStyle: { color: this.colors[n] }
          })
        })
        this[key + '_list'] = list
      }
      return list
    },
    fromChange(type) {
      if (type) this.vol_page = 1
      let data = this.tansData.filter(m => m.chain + '-' + m.address == this.select_from)[0]
      this.symbol = data.symbol
      let list = data['trans_' + this.fromType + '_vol_top100'].map((m, n) => {
        m.no = n + 1
        return m
      })
      this.vol_count = list.length
      this.volData = list.slice((this.vol_page - 1) * this.vol_size, (this.vol_page * this.vol_size))
    },
    pageChange(value) {
      this.vol_page = value
      this.fromChange()
    },
    getTransinfo() {
      this.chains = []
      this.$http.get('/api/v1/meta/game/chain/trans/info', { game_name: this.game }, ({ result }) => {
        this.tansData = result
        if (result.length) {
          result.map((m, n) => {
            this.symbol = result[0].symbol
            let value = m.chain + '-' + m.address
            this.chains.push({ label: value, value })
            m.list_trans_vol.map(m => {
              m.day_1_trans_vol['1k-10k'] = this.utils.deepClone(m.day_1_trans_vol['1k-1w'])
              m.day_1_trans_vol['10k-100k'] = this.utils.deepClone(m.day_1_trans_vol['1w-10w'])
              m.day_1_trans_vol['>100k'] = this.utils.deepClone(m.day_1_trans_vol['10w+'])
              delete m.day_1_trans_vol['1k-1w']
              delete m.day_1_trans_vol['1w-10w']
              delete m.day_1_trans_vol['10w+']
            })
            m.list_trans_count.map(m => {
              m.day_1_trans_count['>10'] = this.utils.deepClone(m.day_1_trans_count['10+'])
              delete m.day_1_trans_count['10+']
            })
            m.summary_trans_count.day_1['>10'] = m.summary_trans_count.day_1['10+']
            delete m.summary_trans_count.day_1['10+']
            m.summary_trans_count.day_7['>10'] = m.summary_trans_count.day_7['10+']
            delete m.summary_trans_count.day_7['10+']
            m.summary_trans_vol.day_1 = {
              "1-100": m.summary_trans_vol.day_1['1-100'],
              "100-1k": m.summary_trans_vol.day_1['100-1k'],
              "1k-10k": m.summary_trans_vol.day_1['1k-1w'],
              "10k-100k": m.summary_trans_vol.day_1['1w-10w'],
              ">100k": m.summary_trans_vol.day_1['10w+']
            }
            m.summary_trans_vol.day_7 = {
              "1-100": m.summary_trans_vol.day_7['1-100'],
              "100-1k": m.summary_trans_vol.day_7['100-1k'],
              "1k-10k": m.summary_trans_vol.day_7['1k-1w'],
              "10k-100k": m.summary_trans_vol.day_7['1w-10w'],
              ">100k": m.summary_trans_vol.day_7['10w+']
            }
            if (n == 0) {
              this.xPieVol = Object.keys(result[0]['summary_trans_vol']['day_' + this.date_vol_pie])
              this.list_trans_vol_legend = Object.keys(result[0].list_trans_vol[0].day_1_trans_vol)
              let volPie = this.setValue(result[0], 'summary_trans_vol', 'pie', 1)
              let volLine = this.setValue(result[0], 'list_trans_vol', 'line', 7)
              this.xLineVol = result[0].list_trans_vol.map(m => m.date)
              this.select_vol_pie = m.chain + '-' + m.address
              this.select_vol_line = m.chain + '-' + m.address
              this.xPieCount = Object.keys(result[0]['summary_trans_count']['day_' + this.date_count_pie])
              this.list_trans_count_legend = Object.keys(result[0].list_trans_count[0].day_1_trans_count)
              this.xLineCount = result[0].list_trans_count.map(m => m.date)
              let countPie = this.setValue(result[0], 'summary_trans_count', 'pie', 1)
              let countLine = this.setValue(result[0], 'list_trans_count', 'line', 7)
              this.select_count_pie = m.chain + '-' + m.address
              this.select_count_line = m.chain + '-' + m.address
              this.renderChart('vol_pie', volPie)
              this.renderChart('vol_line', volLine, 7)
              this.renderChart('count_pie', countPie)
              this.renderChart('count_line', countLine, 7)

              this.select_from = m.chain + '-' + m.address
              this.fromChange()
            }

          })
        }

      })
    },
    getData() {
      if (this.game == 'walken')
        this.getWalken()
      else
        this.getTransinfo()
      this.$http.get('/api/v1/meta/game/statistics', { game_name: this.game, days: 365 }, ({ result }) => {
        // if (result.date_list.length) {
        if (!result.date_list.length) {
          this.showChaindata = false
          return
        }
        try {
          let xAxis = result.date_list || []

          xAxis = xAxis.map(x => moment(new Date(x)).format('YYYY-MM-DD'))

          let { balance_list, date_list } = result.balance_info

          //数据补齐
          let newBalance_lise = new Array(result.date_list.length);
          xAxis.forEach((v, i) => {
            let j = date_list.indexOf(v)
            newBalance_lise[i] = j >= 0 ? balance_list[j] * 1 : 0
          })

          let series_users = result.users_list.reverse()
          let series_volume = result.volume_list.reverse()
          let series_transactions = result.transactions_list.reverse()
          let series_balance = newBalance_lise.reverse()

          let user = series_users.slice(-30).reduce((a, b) => a + b, 0)
          let volume = series_volume.slice(-30).reduce((a, b) => a + b, 0)
          let transactions = series_transactions.slice(-30).reduce((a, b) => a + b, 0)
          let balance = series_balance.slice(-30).reduce((a, b) => a + b, 0)


          this.users_total = this.utils.changeUnit1(user)
          this.volume_total = this.utils.changeUnit1(volume)
          this.transactions_total = this.utils.changeUnit1(transactions)
          this.balance_total = this.utils.changeUnit1(balance)

          xAxis = xAxis.reverse()
          // this.$nextTick(() => {
          this.renderMap('users', xAxis, series_users)
          this.renderMap('volume', xAxis, series_volume)
          this.renderMap('transactions', xAxis, series_transactions)
          this.renderMap('balance', xAxis, series_balance)
        } catch (e) {
          console.log(e)
        }
      })
    },
    getSymbol() {
      this.$http.get('/api/v1/meta/token/price', { game_name: this.game, days: 365 }, ({ result }) => {
        try {
          let lists = result.records || []
          let symbols = [];
          let yAxisIndex = 1;
          let symbol_series = []
          lists.forEach((s, i) => {
            let list = s.rate_list || []
            if (list.length < 365) {
              let nlist = new Array((365 - list.length)).fill('')
              list = [].concat(nlist, list)
            }
            symbols.push({
              title: `${s.symbol} ${this.$t('games.price')}`,
              value: '$' + parseFloat(list.slice(-1)[0] || 0)
            });
            this['series_' + s.symbol] = list
            let items = {
              name: s.symbol,
              showSymbol: false,
              itemStyle: { color: this.colors[i] },
              lineStyle: { width: 2, color: this.colors[i] },
              data: list, yAxisIndex,
            }
            symbol_series.push(Object.assign({}, items))
          })
          this.symbols = symbols
          this.symbol_series = symbol_series
        } catch (e) { console.log(e) }
        this.getData()
      })
    },
  }
}
