
export default {
  layout: 'index',
  data() {
    return {
      titles: {
        dashboard: this.$t('data.title'),
        games: this.$t('games.title'),
        token: this.$t('tokens.title'),
        guilds: this.$t('guild.title'),
        investors: this.$t('investor.title'),
        studios: this.$t('studio.title'),
        newgame: this.$t('newgame.title'),
      },
      sub_titles: {
        dashboard: this.$t('data.sub_title'),
        games: this.$t('games.sub_title'),
        token: this.$t('tokens.sub_title'),
        guilds: this.$t('guild.sub_title'),
        investors: this.$t('investor.sub_title'),
        studios: this.$t('studio.sub_title'),
        newgame: this.$t('newgame.sub_title'),
      },
      tabs: [
        { name: this.$t('GameFiTabs.tab1'), value: 'dashboard' },
        { name: this.$t('GameFiTabs.tab2'), value: 'ranking' },
        { name: this.$t('GameFiTabs.tab3'), value: 'token' },
        { name: this.$t('GameFiTabs.tab4'), value: 'guild' },
        { name: this.$t('GameFiTabs.tab6'), value: 'studio' },
        { name: this.$t('GameFiTabs.tab5'), value: 'investor' },
        { name: this.$t('GameFiTabs.tab7'), value: 'newgame' },
      ],
      current: 0,
      types: 'dashboard|games|token|guilds|studios|investors|newgame',
      id: ''
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      let id = this.$route.params.id
      if (!/dashboard|games|token|guilds|investors|studios|newgame/.test(id)) id = "dashboard"
      this.current = this.types.split('|').findIndex(x => x == id)
      // console.log(this.current, id)
      this.id = id
      return id
    },
    changeTab(i) {
      this.current = i
      // console.log(i)
      let t = this.types.split('|')[i]
      this.$router.push(`/rank/${t}`)
    },

  },
  render() {
    let { tabs, current, titles, sub_titles, id } = this
    id = id || this.init()
    let title = titles[id], sub_title = sub_titles[id]

    return (<div class='page-games ranking'>
      <div class="title">{title}</div>
      <div class="sub-title">{sub_title}</div>

      <HomeTabs tabs={tabs} onChangeTab={this.changeTab} activeIndex={current} />
      {id == 'dashboard' ? <GamesDashboard /> : null}
      {id == 'games' ? <HomeGames limit={30} showSearch={true} showPage={true} /> : null}
      {id == 'guilds' ? <HomeGuild limit={30} showPage={true} /> : null}
      {id == 'investors' ? <HomeInvestor limit={30} showPage={true} /> : null}
      {id == 'token' ? <HomeToken limit={30} showPage={true} /> : null}
      {id == 'studios' ? <HomeStudio limit={30} showPage={true} /> : null}
      {id == 'newgame' ? <HomeNewgame limit={12} showPage={true} /> : null}
    </div>)
  }

}
