//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { AlertCircle } from "kui-icons";
export default {
  props: {
    item: Object
  },
  watch: {
    item: {
      handler(val) {
        this.datas = val.data
        this.date = val.date
        this.renderKline()
      },
      deep: true
    }
  },
  data() {
    return {
      AlertCircle,
      up: false,
      datas: this.item.data || [],
      date: this.item.date || [],
      value: '--',
      rate: '--',
      rect: '',
      points: '',
      t1: '--',
      t2: '--'
    }
  },
  mounted() {
    window.addEventListener('resize', this.renderKline)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.renderKline)
  },
  methods: {
    renderKline() {
      if (!this.datas.length) return
      // 数据
      let w = this.$refs.item.offsetWidth
      if (!w) {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => {
          this.renderKline()
        }, 300);

        return;
      }
      let { datas, date } = this
      //x坐标数组
      let s = w / datas.length;
      //x起始坐标数组
      let x = 0;
      // datas = datas.map(x => parseInt(x))
      //y坐标最小值
      let min = datas.reduce((x, y) => (x > y ? y : x));
      //y坐标最大值
      let max = datas.reduce((x, y) => (x > y ? x : y));
      // console.log(min, max)
      //缩放比例 max-min为曲线幅度
      let rodio = 46 / (max - min)
      // 此处的points 的值就是svg 都polyline 的points 属性的值
      let points = "";
      //统一处理y坐标，垂直向上偏移，也即是y坐标最高点归零
      datas.forEach((y, i) => {
        // console.log(y, i)
        points += `${x} ${46 - (y - min) * rodio}${i != 29 ? ',' : ''}`;
        x += s;
      });
      this.points = points
      let rect = `0 46,${points},${x - s} 46`  //封口
      this.rect = rect


      this.t1 = moment(date[0]).format('MM-DD')
      this.t2 = moment(date.slice(-1)[0]).format('MM-DD')
      // t.t1 = moment().subtract(30, 'day').format('MM-DD')
      // t.t2 = moment().format('MM-DD')
      let v = datas.slice(-2)
      this.value = this.utils.changeUnit1(v[1])
      this.up = v[1] > v[0]
      this.rate = v[1] ? ((((v[1] - v[0]) / v[1]) * 100).toFixed(2) + '%') : '--'
    },
  }
}
