
export default {
  props: {
    value: [Number, String],
  },
  render() {
    let { value } = this
    let count = parseInt(value)
    let paddingRight = ((1 - value % 1) * 16)
    return (
      <div class="hot-wrap">
        {
          new Array(5).fill('').map((m, n) => { 
            return (<div class="hot-item">
              {n < count + 1 && <div class="hot-fire" style={{ 'padding-right': (n == count ? paddingRight : 0) + 'px' }}></div>}
            </div>)
          })
        }
        < div class="hot-score">
          {value.toFixed(1)}
        </div>
      </div>
    )
  }
}
