//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang', 'theme'])
  },
  data() {
    return {
      token_info: {
        RCM: {},
      },
      game: '',
      colors: ['orange', 'cyan', 'green', 'magenta', 'blue'],
      date: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 365, label: 'ALL' },
      ],
      date_users: 30,
      date_RCM: 30,
      date_SNS: 30,
      date_holders: 30,

      new_user: 0,
      totalusers: 0,
      active_user: 0,

      new_user_incrate: 0,
      total_user_incrate: 0,
      active_user_incrate: 0,
      query_name: '',
      gridText: {
        'users': {
          'left': this.$t('subject.totalusers'),
          'mid': this.$t('subject.activeusers'),
          'right': this.$t('subject.newusers'),
          'sum': this.$t('subject.newusers') + ' & ' + this.$t('subject.activeusers'),
        },
        'RCM': {
          'left': this.$t('subject.deposited'),
          'mid': this.$t('subject.withdraw'),
          'right': this.$t('subject.net_deposited'),
        },

        'holders': this.$t('subject.holders'),
      },
      holderIndex: 0,
    }
  },
  mounted() {
    let game = this.$route.params.id
    this.game = game
    this.init()
  },
  methods: {
    init() {
      this.date_users = 30
      this.date_deposite = 30
      this.date_withdraw = 30
      this.initMap()
      this.getUsers()
    },
    initMap() {
      let { h5, theme } = this
      let leftColor = theme == 'dark' ? '#717171' : '#999'
      let dashedColor = theme == 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#dddddd60'
      let _this = this
      let option = {
        graphic: [{
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: h5 ? 100 : 200, height: h5 ? 19 : 38 }
        },
        {
          rotation: Math.PI / 2,
          type: 'text', left: '5px', top: 'middle', style: { fill: leftColor, text: '', font: '14px "Inter"' }
        },
        {
          rotation: Math.PI / -2,
          type: 'text', right: '5px', top: 'middle', style: { fill: leftColor, text: '', font: '14px "Inter"' }
        }
        ],
        tooltip: {
          extraCssText: "z-index:1", trigger: 'axis',
          backgroundColor: '#121326', borderWidth: 0,
          textStyle: { color: '#fff' },
          axisPointer: { label: { backgroundColor: '#6a7985' } },
          formatter: (p) => {
            let str = `<p>${p[0].axisValueLabel}</p>`
            p.forEach((x) => {
              str += `<div class="k-row k-row-flex"><span class="k-col" style="margin-right:30px;flex:1">${x.marker}${x.seriesName}:</span><span class="k-col">${_this.utils.changeUnit(x.data)}</span></div>`
            })
            return str
          },
        },
        grid: { borderColor: 'transparent', /*backgroundColor: '#F9F9FB',*/ show: true, left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        xAxis: {
          type: 'category', data: [],
          axisLine: { show: false },
          axisLabel: { color: "#666", formatter: v => moment(new Date(v)).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
        legend: { data: [], icon: 'circle', bottom: 0, textStyle: { color: '#999', fontSize: 12 }, itemHeight: 8, },
        yAxis: [
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor, //垂直于y轴的线的颜色
                width: 1,
                type: 'dashed' //'dotted'虚线 'solid'实线
              }
            },
            // min: value => value.min,
            // max: value => value.max,
          }
        ],
        series: [
          {
            name: '',
            yAxisIndex: 0,
            data: [], type: 'bar',
            barMaxWidth: 15,
            itemStyle: { color: '#39f' }
          },
          {
            name: '',
            yAxisIndex: 1,
            data: [], type: 'line',
          },
        ]
      };
      ['users', 'RCM', 'holders'].forEach(id => {
        let chart = echarts.init(document.getElementById('map_' + id))
        this['char_' + id] = chart
        chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' });
        if (id == 'users') {
          option.series = [
            {
              name: this.gridText[id]['left'],
              yAxisIndex: 0,
              data: [],
              type: 'bar',
              barMaxWidth: 15,
              itemStyle: { color: '#39f' }
            },
            {
              name: this.gridText[id]['right'],
              yAxisIndex: 1,
              data: [],
              type: 'line',
              itemStyle: { color: 'orange' }
            },
            {
              name: this.gridText[id]['mid'],
              yAxisIndex: 1,
              data: [],
              type: 'line',
              itemStyle: { color: this.colors[1] }
            },
          ];
          ['left', 'sum'].map((m, n) => {
            option.graphic[n + 1].style.text = this.gridText[id][m]
          });
          let line = {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor,
                width: 0,
                type: 'dashed'
              }
            },
          }
          // option.yAxis[0].min = value => value.min
          option.yAxis[1] = Object.assign({}, line)
        } else if (id == 'RCM') {
          option.series = [
            {
              yAxisIndex: 0,
              name: this.gridText[id]['left'],
              data: [],
              type: 'bar',
              barMaxWidth: 15,
              itemStyle: { color: '#39f' }
            },
            {
              yAxisIndex: 0,
              name: this.gridText[id]['mid'],
              data: [],
              type: 'bar',
              barMaxWidth: 15,
              itemStyle: { color: 'orange' }
            },
            {
              yAxisIndex: 1,
              name: this.gridText[id]['right'],
              data: [],
              type: 'line',
              itemStyle: { color: 'cyan' }
            },
          ];
          option.yAxis = [
            {
              type: 'value',
              alignTicks: true,
              axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
              splitLine: {
                show: true,
                lineStyle: {
                  color: dashedColor, //垂直于y轴的线的颜色
                  width: 1,
                  type: 'dashed' //'dotted'虚线 'solid'实线
                }
              },
              // min: value => value.min,
              // max: value => value.max,
            },
            {
              type: 'value',
              alignTicks: true,
              axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
              splitLine: {
                show: true,
                lineStyle: {
                  color: dashedColor, //垂直于y轴的线的颜色
                  width: 0,
                  type: 'dashed' //'dotted'虚线 'solid'实线
                }
              },
              // min: value => value.min,
              // max: value => value.max,
            },
          ],
            option.graphic[1].style.text = ''
          option.graphic[2].style.text = ''
        } else if (id == 'holders') {
          option.series = [
            {
              name: 'RCM ' + this.gridText['holders'],
              data: [],
              type: 'line',
              itemStyle: { color: '#39f' }
            },


          ]
          option.graphic[1].style.text = 'RCM ' + this.$t('subject.holders')
          option.graphic[2].style.text = ''
        }
        chart.setOption(option)
        window.addEventListener('resize', () => {
          chart.resize()
        })
      })
    },
    dateChange(key) {
      try {
        let xAxis = this['xAxis_' + key]
        let datas = this['series_' + key]
        let datas1 = this['series1_' + key]
        let datas2 = this['series2_' + key]
        let day = this['date_' + key] * -1
        let option = {
          xAxis: { data: xAxis.slice(day) },
          series: [
            { data: datas.slice(day) },
            { data: datas1.slice(day) },
          ]
        }
        if (key == 'RCM' || key == "users") {
          option.series.push({ data: datas2.slice(day) })
        }
        this['char_' + key].setOption(option)
      } catch (e) { console.log(e) }
    },
    renderMap(id, xAxis = [], series = [], series1 = [], series2 = []) {
      try {
        let chart = this['char_' + id]
        chart.hideLoading()
        let count = 365
        let day = 30
        if (xAxis.length < count) {
          let t = count - xAxis.length
          let j = xAxis.slice(0, 1)[0] || Date.now();
          let f = moment(new Date(j)).subtract(t, 'days')
          let nx = []
          for (let i = 0; i < t; i++) {
            nx.push(f.format('YYYY/MM/DD'))
            f.add(1, 'days')
          }
          xAxis = [].concat(nx, xAxis)
        }
        //series
        if (series.length < count) {
          let ns = new Array(count - series.length).fill('--')
          series = [].concat(ns, series)
          series1 = [].concat(ns, series1)
          series2 = [].concat(ns, series2)
        }
        xAxis = xAxis.map(x => x.replace(/-/g, '/'))

        let option = {
          xAxis: { data: xAxis.slice(-day) },
          series: [{
            data: series.slice(-day)
          }, {
            data: series1.slice(-day)
          },]
        }
        if (id == 'holders') {
          option.series[0].yAxisIndex = 0
          option.series[1].yAxisIndex = 1
        } else {
          option = {
            xAxis: { data: xAxis.slice(-day) },
            series: [{
              data: series.slice(-day)
            }, {
              data: series1.slice(-day)
            }, {
              data: series2.slice(-day)
            },]
          }
        }
        this['xAxis_' + id] = xAxis
        this['series_' + id] = series
        this['series1_' + id] = series1
        this['series2_' + id] = series2
        chart.setOption(option)
      } catch (e) { console.log(e) }
    },
    getUsers() {
      this.$http.get('/api/v1/meta/game/dune/melody/statistics', { query_name: this.game, days: 365 }, ({ result }) => {
        let { date_list = [], new_user = {}, total_user = {}, active_user = {}, token_info = {}, } = result || {}
        this.new_user_incrate = new_user.new_user_incrate
        this.total_user_incrate = total_user.total_user_incrate
        this.active_user_incrate = active_user.active_user_incrate
        this.new_user = this.utils.changeUnit(new_user.new_user_list[0])
        this.totalusers = this.utils.changeUnit(total_user.total_user_list[0])
        this.active_user = this.utils.changeUnit(active_user.active_user_list[0])
        date_list = date_list.reverse()
        new_user.new_user_list = new_user.new_user_list.reverse()
        total_user.total_user_list = total_user.total_user_list.reverse()
        active_user.active_user_list = active_user.active_user_list.reverse()
        this.renderMap('users', date_list, total_user.total_user_list, new_user.new_user_list, active_user.active_user_list)
        for (let token in token_info) {
          let value = token_info[token]
          value.deposited = value.deposited_list[0]
          value.deposited_list = value.deposited_list.reverse()
          value.net_deposited = value.net_deposited_list[0]
          value.net_deposited_list = value.net_deposited_list.reverse()
          value.withdraw = value.withdraw_list[0]
          value.withdraw_list = value.withdraw_list.reverse()
          value.holders = null
          this.token_info[token] = value
          this.getSymbol(token, date_list)
          this.renderMap(token, date_list, value.deposited_list, value.withdraw_list, value.net_deposited_list)
        }
      })
    },
    // RCM 
    getSymbol(symbol, date_list, ) {
      this.$http.get('/api/v1/meta/game/dune/token', { query_name: this.game, days: 365, symbol }, ({ result }) => {
        this.holderIndex++
        this.token_info[symbol]['holders_incrate'] = result.holders_incrate
        this.token_info[symbol]['holders'] = result.holders
        this.token_info[symbol]['holders_list'] = result.holders_list
        this.$nextTick(() => {
          this.renderMap('holders', date_list, this.token_info.RCM.holders_list.reverse())
        })
      })
    },
  }
}
