//
//
//
//
//
//
//
//
//
//

export default {
  name: 'dateFilter',
  data() {
    return {
      currentDay: this.kolDate ? 7 : 1,
      dayRanges: [
        { label: '24' + this.$t('com.hour'), value: 1 },
        { label: '7' + this.$t('com.day'), value: 7 },
        { label: '30' + this.$t('com.day'), value: 30 }
      ],
    }
  },
  props: {
    kolDate: Boolean,
    active: {
      type: Number
    },
    dateList: {
      type: Array, default: () => []
    }
  },
  watch: {
    active(v) {
      if (v) {
        this.currentDay = v
      }
    },
    dateList(v) {
      if (v) {
        this.dayRanges = v
      }
    }
  },
  methods: {
    dayChange(item) {
      if (item.value == this.currentDay) return
      this.currentDay = item.value
      this.$emit('dayChange', item)
    },
  }
}
