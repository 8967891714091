//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapGetters } from 'vuex'
import { AlertCircle } from "kui-icons";
export default {
  computed: {
    ...mapGetters(['h5'])
  },
  props: {
    data: Array,
    columns: Array,
    change: [Number, String],
    width: Number,
    height: Number,
    loading: Boolean,
    showBar: Boolean,
    showLine: Boolean,
    showPage: Boolean,
    showViewall: Boolean,
  },
  watch: {
    data(v) {
      setTimeout(() => {
        let names = v.map(x => x.game_name)
        if (names.length && (this.showLine || this.showBar)) {
          this.getLine(names)
        }
      }, 300);
    },
  },
  data() {
    return {
      AlertCircle
    }
  },
  mounted() {
    let names = this.data.map(x => x.game_name)
    if (names.length && (this.showLine || this.showBar)) {
      this.getLine(names)
    }
  },
  methods: {
    rowClick(item) {
      this.$router.push('/games/' + item.game_name)
    },
    filterChange(a, b) {
      this.$emit('filter', b)
    },

    getLine(game_names) {
      this.$http.post('/api/v1/meta/game/statistics', { game_names, days: 7 }, ({ result = [] }) => {
        result.forEach(x => {
          let xAxis = x.date_list.reverse()
          let users_list = x.users_list.reverse()
          let volume_list = x.volume_list.reverse()
          let option = {
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                lineStyle: { width: 0 }
              },
              backgroundColor: '#121326',
              borderWidth: 0,
              textStyle: { color: '#fff' },
              formatter: (params, ticket, callback) => {
                let d = params[0].axisValue
                if (!isNaN(Number(d)))
                  d = new Date(d)
                return `${moment(new Date(d)).format('MMM DD')}<br/>${params[0].data}`;
              }
            },
            grid: { left: '5px', right: '0', bottom: '5px', top: '5px', containLabel: true },
            xAxis: {
              type: 'category', data: xAxis,
              axisLine: { show: false, }, //X line bottom
              axisLabel: { show: false }, // label
              axisTick: { show: false } //刻度
            },
            yAxis: {
              type: 'value',
              axisLabel: { show: false }, //x label
              splitLine: { show: false, }, //X
              axisTick: { show: false },//刻度
              min: value => value.min,
              max: value => value.max,
            },
            series: {
              barMaxWidth: 6,
              barMinHeight: 1,
              data: [],
            }
          }
          if (this.showBar) {

            // bar
            let barid = document.getElementById('map_bar_' + x.game_name)

            if (volume_list.length < 7) {
              let bar = echarts.init(barid)
              bar.dispose()
              barid.innerHTML = '--'
              barid.style = ''
            } else {
              let bar = echarts.init(barid)
              bar.clear()
              option.series.data = volume_list;
              option.series.type = 'bar'
              option.series.itemStyle = { color: '#6642FF' };
              option.series.emphasis = {
                itemStyle: { color: '#6642FF' },
              }
              bar.setOption(option)
            }
          }
          if (this.showLine) {
            //line
            let lineid = document.getElementById('map_line_' + x.game_name)
            if (users_list.length < 7) {
              let line = echarts.init(lineid)
              line.dispose()
              lineid.innerHTML = '--'
              lineid.style = ''
            } else {
              let line = echarts.init(lineid)
              line.clear()
              let lineoption = Object.assign({}, option)
              lineoption.series.data = users_list
              lineoption.series.type = 'line'
              let color = users_list[6] > users_list[5] ? '#3cd19b' : '#FF5147';
              lineoption.series.lineStyle = { width: 1, color: color }
              lineoption.series.symbol = 'circle'
              lineoption.series.itemStyle = { color: color }
              lineoption.series.symbolSize = 8
              lineoption.series.showAllSymbol = false
              option.series.emphasis = {
                color: color
              }
              line.setOption(lineoption)
            }
          }
        })
      })
    },
  }
}
