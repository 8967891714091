//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      size: 20,
      page: 1,
      data: [{}, {}, {}, {}],
      loading: true,
      loadmore: true,
      showFinish: false,
      flag: false,
    }
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  mounted() {
    this.getData()
    window.addEventListener('scroll', this.scrollBottom)
  },
  methods: {
    scrollBottom(e) {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight
      if (scrollTop + windowHeight > scrollHeight - 1000 && !this.showFinish && this.flag && this.data.length) {
        this.loadmore = true
        this.page++
        this.getData()
      }
    },
    getData() {
      this.flag = false
      let game = this.$route.params.id
      let { size, page } = this
      this.$http.get('/api/v1/meta/game/mirror/list', { game_name: game, limit: size, offset: (page - 1) * size, }, ({ result }) => {
        if (result.records.length) {
          if (page == 1) {
            this.data = result.records
          } else {
            this.data = this.data.concat(result.records)
          }
        } else {
          if (this.page == 1) {
            this.data = []
            this.showFinish = false
          } else {
            this.showFinish = true
          }
        }
        setTimeout(() => {
          this.loading = false
          this.loadmore = false
          this.flag = true
        }, 300);
      })
    },
    goDetail(item) {
      window.open(`/strategy/${item.mirror_id}`)
    }
  }
}
