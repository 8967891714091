//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      colors: ['orange', 'cyan', 'green', 'magenta', 'blue'],
      date: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 365, label: 'All' },
      ],
      date_growth_hold: 30,
      date_users: 30,
      date_volume: 30,
      date_transactions: 30,
      date_deposite: 30,
      date_withdraw: 30,
      users_total: '',
      volume_total: '',
      growth_total: 0,
      transactions_total: '',
      depositedusers: '',
      depositedusers_rate: '',
      withdrawusers: '',
      withdrawusers_rate: '',
      depositeamounts: '',
      depositeamounts_rate: '',
      withdrawamounts: '',
      withdrawamounts_rate: '',
      game: '',
      gridText: {
        'users': this.$t('games.active_user'),
        'volume': this.$t('games.volume'),
        'transactions': this.$t('games.transactions'),
        'deposite': {
          'left': this.$t('subject.depositedusers'),
          'right': this.$t('subject.withdrawusers'),
        },
        'withdraw': {
          'left': this.$t('subject.depositeamounts'),
          'right': this.$t('subject.withdrawamounts'),
        },
      },
    }
  },
  mounted() {
    this.game = this.$route.params.id

    this.initMap()
    this.getWalken()
    this.getHoldGrowth()
  },
  methods: {
    calcTotal(data) {
      return this.utils.changeUnit(data.slice(-30).reduce((a, b) => a * 1 + b * 1, 0))
    },
    calcRate(data) {
      return ((data.slice(-1)[0] - data.slice(-2)[0]) / data.slice(-2)[0] * 100).toFixed(2)
    },
    getWalken() {
      this.$http.get(`/api/v1/meta/game/special/statistics`, { game_name: this.game }).then(({ result }) => {
        let info = result.info
        this.walken_users = result.multiple_active_users

        let xAxis = info.map(x => x.date)
        let series_users = [], series_volume = [], series_transactions = [],
          rechange_users = [], withdraw_users = [], rechange_amount = [], withdraw_amount = [];

        info.forEach(x => {
          let i = x.data
          series_users.push(i.active_users)
          series_volume.push((i.volume * 1).toFixed(2))
          series_transactions.push(i.transfer_count)
          rechange_users.push(i.rechange_users)
          withdraw_users.push(i.withdraw_users * 1)
          rechange_amount.push((i.rechange_amount * 1).toFixed(2))
          withdraw_amount.push((i.withdraw_amount * 1).toFixed(2))
        })
        //total
        this.users_total = this.utils.changeUnit(this.walken_users.days_30)
        this.volume_total = this.calcTotal(series_volume)
        this.transactions_total = this.calcTotal(series_transactions)

        this.depositedusers = this.utils.changeUnit(rechange_users.slice(-1)[0])
        this.withdrawusers = this.utils.changeUnit(withdraw_users.slice(-1)[0])
        this.depositedusers_rate = this.calcRate(rechange_users)
        this.withdrawusers_rate = this.calcRate(withdraw_users)

        this.depositeamounts = this.utils.changeUnit(rechange_amount.slice(-1)[0])
        this.withdrawamounts = this.utils.changeUnit(withdraw_amount.slice(-1)[0])
        this.depositeamounts_rate = this.calcRate(rechange_amount)
        this.withdrawamounts_rate = this.calcRate(withdraw_amount)

        this.renderMap('users', xAxis, series_users)
        this.renderMap('volume', xAxis, series_volume)
        this.renderMap('transactions', xAxis, series_transactions)
        this.renderMap('deposite', xAxis, rechange_users, withdraw_users)
        this.renderMap('withdraw', xAxis, rechange_amount, withdraw_amount)
        // this.renderMap('growth_hold', xAxis, series_transactions)
      })
    },
    initMap() {
      let { h5, theme } = this
      let leftColor = theme == 'dark' ? '#717171' : '#999'
      let dashedColor = theme == 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#dddddd60'
      let option = {
        graphic: [{
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: h5 ? 100 : 200, height: h5 ? 19 : 38 }
        },
          // {
          //   rotation: Math.PI / 2,
          //   type: 'text', left: '5px', top: 'middle', style: { fill: leftColor, text: 'Token Price ($)', font: '14px "Inter"' }
          // },
          // {
          //   rotation: Math.PI / -2,
          //   type: 'text', right: '5px', top: 'middle', style: { fill: leftColor, text: 'Token Price ($)', font: '14px "Inter"' }
          // }
        ],
        tooltip: { extraCssText: "z-index:1", trigger: 'axis', backgroundColor: '#121326', borderWidth: 0, textStyle: { color: '#fff' }, },
        grid: { borderColor: 'transparent', /*backgroundColor: '#F9F9FB',*/ show: true, left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        xAxis: {
          type: 'category', data: [],
          axisLine: { show: false },
          axisLabel: { color: "#666", formatter: v => moment(new Date(v)).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
        legend: { data: [], icon: 'circle', bottom: 0, textStyle: { color: '#999', fontSize: 12 }, itemHeight: 8, },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => {
                if (v > 0 && v < 1 || v < 0) {
                  return parseFloat(this.utils.changeUnit1(v)).toFixed(2)
                } else {
                  return this.utils.changeUnit1(v)
                }
                // this.utils.changeUnit1(v)
              }

            },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor,
                width: 1,
                type: 'dashed'
              }
            },
            min: value => value.min,
            max: value => value.max,
          },
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor,
                width: 1,
                type: 'dashed'
              }
            },
          }
        ],
        series: [
          {
            name: '',
            yAxisIndex: 0,
            data: [], type: 'bar',
            barMaxWidth: 15,
            barMinHeight: 8,
            itemStyle: { color: '#39f' }
          },
          { type: 'line', }, { type: 'line', }, { type: 'line', }, { type: 'line', },
        ]
      };
      ['users', 'volume', 'transactions', 'deposite', 'withdraw', 'growth_hold'].forEach(id => {
        let ele = document.getElementById('map_' + id)
        if (!ele) return;
        let chart = echarts.init(ele)
        chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' })
        this['char_' + id] = chart
        // option.graphic[1].style.text = this.gridText[id]
        option.series[0].name = this.gridText[id]
        let line = {
          type: 'value',
          axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13 },
          splitLine: { show: false, },
        }
        option.yAxis[1] = Object.assign({}, line)
        option.yAxis[2] = Object.assign({}, line, { axisLabel: { show: false } })
        // option.yAxis[3] = Object.assign({}, line, { axisLabel: { show: false } })
        if (/deposite|withdraw/.test(id)) {
          option.series = [
            {
              name: this.gridText[id]['left'],
              data: [],
              type: 'bar',
              stack: 'deposite',
              barMaxWidth: 15,
              barMinHeight: 8,
              itemStyle: { color: '#39f' }
            },
            {
              name: this.gridText[id]['right'],
              data: [],
              stack: 'deposite',
              type: 'bar',
              barMaxWidth: 15,
              barMinHeight: 8,
              itemStyle: { color: 'orange' }
            },
          ];
          // option.graphic[1].style.text = ''
          // option.graphic[2].style.text = ''
        }

        chart.setOption(option)
        window.addEventListener('resize', () => {
          chart.resize()
        })
      })
    },
    renderMap(id, xAxis = [], series = [], series1 = []) {
      let chart = this['char_' + id]
      chart.hideLoading()

      let count = xAxis.length//new Date(y, m, 0).getDate() //本月有多少天
      let day = 30
      if (xAxis.length < count) {
        let t = count - xAxis.length
        let j = xAxis.slice(0, 1)[0] || Date.now();
        let f = moment(new Date(j)).subtract(t, 'days')
        let nx = []
        for (let i = 0; i < t; i++) {
          nx.push(f.format('YYYY/MM/DD'))
          f.add(1, 'days')
        }
        xAxis = [].concat(nx, xAxis)
      }
      //series
      if (series.length < count) {
        let ns = new Array(count - series.length).fill('0')
        series = [].concat(ns, series)
        if (/deposite|withdraw/.test(id)) series1 = [].concat(ns, series1)
      }

      xAxis = xAxis.map(x => x.replace(/-/g, '/'))

      // let symbol_series = [].concat([], this.symbol_series)
      // symbol_series = symbol_series.map(x => {
      //   x.data = this['series_' + x.name].slice(-day)
      //   return x
      // })
      let option = {
        xAxis: { data: xAxis.slice(-day) },
        series: [{
          // yAxisIndex: index,//0,
          data: series.slice(-day)
        },
        {
          data: series1.slice(-day)
        }
          //, ...symbol_series
        ],
      }
      this['xAxis_' + id] = xAxis
      this['series_' + id] = series
      this['series1_' + id] = series1
      chart.setOption(option)
    },
    dateChange(key) {
      let xAxis = this['xAxis_' + key]
      let datas = this['series_' + key]
      let datas1 = this['series1_' + key]
      let day = this['date_' + key] * -1

      // let symbol_series = [].concat([], this.symbol_series)
      // symbol_series = symbol_series.map(x => {
      //   x.data = this['series_' + x.name].slice(day)
      //   return x
      // })
      let day_data = datas.slice(day)
      let day_data1 = datas1.slice(day)
      this['char_' + key].setOption({
        xAxis: { data: xAxis.slice(day) },
        series: [
          { data: day_data, },
          { data: day_data1 },
          // ...symbol_series
        ]
      })

      let value = day_data.reduce((a, b) => a * 1 + b * 1, 0)
      if (key == 'users') {
        let walken = { 7: this.walken_users.days_7, 30: this.walken_users.days_30, 365: this.walken_users.days_all }
        this.users_total = this.utils.changeUnit(walken[day * -1])
      } else {
        this[key + '_total'] = this.utils.changeUnit(value)
      }
      this['tip_' + key] = this.utils.changeUnit(value)
    },
    getHoldGrowth() {
      this.$http.get('/api/v1/meta/token/holdGrowth', { game_name: this.game, days: 365 }, res => {
        let { date_list = [], num_list = [] } = res.result.records[0] || {}
        if (!date_list.length) {
          // return
        }
        date_list = date_list.reverse()
        num_list = num_list.reverse()
        this.renderMap('growth_hold', date_list, num_list)
        let growth = num_list.slice(-1)[0]//reduce((a, b) => a + b)
        this.growth_total = this.utils.changeUnit(growth)
      })
    },
  }
}
