//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import moment from 'moment'
export default {
  props: {
    trendsTag: Object,
  },
  computed: {
    ...mapGetters(['lang', 'h5'])
  },
  data() {
    return {
      showH5Date: false,
      total: 0,
      date: moment().format('YYYY-MM-DD'),
      news: [],
      loading: true,
      size: 10,
      page: 1
    }
  },
  mounted() {
    this.getNews()
  },
  methods: {
    changeDate(date) {
      this.date = date
      this.getNews()
    },
    changeH5Date(v) {
      this.date = moment(v).format('YYYY-MM-DD')
      this.getNews()
      this.showH5Date = false
    },
    getNews() {
      let { size, page } = this
      this.$http.get('/api/v1/news/list', { limit: size, offset: (page - 1) * size, type: 'trends', date: this.date }).then(({ result }) => {
        this.total = result.count
        let len = this.h5 ? 100 : 100000
        let news = result.records.map((m, i) => {
          m.time = moment(m.create_at).format('HH:mm')
          let text = this.lang == 'en' ? (m.content_en || m.content) : (m.content || m.content_en)
          m.text = this.utils.subString(text, len)
          m.all = text
          m.open = this.utils.getLength(text) > len
          return m
        })
        if (page == 1) this.news = news
        else {
          news = this.news.concat(news)
        }
        setTimeout(() => {
          this.loading = false
        }, 300);
      })
    },
    loadMore() {
      this.page++
      this.getNews()
    },
  }
}
