//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['lang', 'h5'])
  },
  props: {
    loading: Boolean,
    item: Object
  },
  data() {
    return {
    }
  },
  methods: {
    toTT({ tid, msg_id }) {
      open(`https://twitter.com/${tid}/status/${msg_id}`)
    },
  }
}
