
import { ChevronDown } from 'kui-icons'
export default {
  props: {
    tag: String,
    tagList: Array,
  },
  watch: {
    tagList: {
      handler(v) {
        if (v) {
          this.tags = v
        }
      },
      immediate: true,
      deep: true
    },
    tag: {
      handler(v) {
        if (v) {
          this.currentTag = v || 'all'
        }
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      currentTag: 'all',
      tags: [
        { name: 'all', show_name: this.$t('com.alltags'), key: "0" },
        { name: 'card', show_name: 'Card' },
        { name: 'rpg', show_name: 'RPG' },
        { name: 'moba', show_name: 'MOBA' },
        { name: 'idie', show_name: 'IdIe' },
        { name: 'racing', show_name: 'Racing' },
        { name: 'strategy', show_name: 'Strategy' },
        { name: 'simulation', show_name: 'Simulation' },
        { name: 'sandbox', show_name: 'Sandbox' },
        { name: 'fps', show_name: 'FPS' },
        { name: 'fighting', show_name: 'Fighting' },
        { name: 'platform', show_name: 'Platform' },
        { name: 'collectible', show_name: 'Collectible' },
        { name: 'tower-dfense', show_name: 'Tower Dfense' },
        { name: 'pvp', show_name: 'PvP' },
        { name: 'pve', show_name: 'PvE' },
        { name: 'arcade', show_name: 'Arcade' },
        { name: 'casual', show_name: 'Casual' },
        { name: 'building', show_name: 'Building' },
        { name: 'breeding', show_name: 'Breeding' },
        { name: 'sports', show_name: 'Sports' },
        { name: 'aciton', show_name: 'Aciton' },
      ],
      show: false
    }
  },
  methods: {
    change({ key }) {
      this.currentTag = key
      this.$emit('change', key)
    },
    hide() {
      this.show = !this.show
    }
  },
  render() {
    let { tags, currentTag, show } = this
    let item = (!currentTag ? tags[0] : tags.filter(x => x.name == currentTag)[0]) || {}
    const props = {
      props: {
        value: show
      },
      on: {
        click: this.change,
        input: e => this.show = e
      }
    }
    return (
      <Dropdown trigger='normal' {...props}>
        <div class="top-drop flex-base" onClick={this.hide}>
          <GameTab item={item} fixedname={'tags'} />
          {<Icon type={ChevronDown} />}
        </div>
        <Menu slot="content" class="chain-menu">
          {tags.map((item, i) => {
            return (
              <MenuItem key={item.name} >
                <GameTab item={item} actived={currentTag == (item.name)} />
              </MenuItem>
            )
          })}
        </Menu>
      </Dropdown>
    )
  }
}

