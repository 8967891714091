//
//
//
//
//
//

import sprite from '@/assets/svg/sprite.svg'
export default {
  name: 'Chain',
  data() {
    return {
      sprite
    }
  },
  props: {
    name: String,
    // back: { type: String, default: '#00000000' },
    fill: {
      type: String,
      default: '#666'
    },
    width: { type: Number, default: 15 }
  }
}
