//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { AlertCircle } from 'kui-icons'
export default {
  computed: {
    ...mapGetters(['lang'])
  },
  data() {
    return {
      AlertCircle,
      ranks: {},
      data: {},
    }
  },
  mounted() {
    this.getData()
    this.getrank()
  },
  methods: {
    getData() {
      let query_name = this.$route.params.id
      // if (query_name == 'walken') {
      //   this.getWalken(query_name)
      //   return
      // }
      this.$http.get(`/api/v1/meta/game/dune/statistics`, { query_name, days: 365 }).then(({ result }) => {
        this.data = result
      })
    },
    // getWalken(game_name) {
    //   this.$http.get(`/api/v1/meta/game/special/statistics`, { game_name }).then(({ result }) => {
    //     let item = result.slice(-1)[0].data
    //     let prev = result.slice(-2, -1)[0].data

    //     this.data = {
    //       users_count: item.active_users,
    //       user_rate: ((item.active_users - prev.active_users) / prev.active_users * 100).toFixed(2),
    //       volume: item.volume,
    //       volume_rate: ((item.volume - prev.volume) / prev.volume * 100).toFixed(2),
    //       trans_count: item.transfer_count,
    //       trans_rate: ((item.transfer_count - prev.transfer_count) / prev.transfer_count * 100).toFixed(2),
    //       market_cap: '--'
    //     }
    //   })
    // },
    getrank() {
      let query_name = this.$route.params.id
      this.$http.get('/api/v1/meta/game/statistics/rank', { game_name: query_name }, res => {
        this.ranks = res.result
      })
    },
  }
}
