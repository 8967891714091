
import './index.less'
import { mapGetters } from 'vuex'
import { ChevronDown } from 'kui-icons'
export default {
  props: {
    limit: { type: Number, default: 10 },
    showViewall: Boolean,
    showPage: Boolean,
    datas: [Array],
    totals: Number,
  },
  computed: {
    ...mapGetters(['h5'])
  },
  watch: {
    totals(v) {
      this.total = v
    },
    datas(v) {
      this.guildData = v
    }
  },
  data() {
    return {
      page: 1,
      total: this.totals || 0,
      current: 0,
      size: this.limit,
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
      sortKey: ['price', 'price_total', 'social_score'],
      guildData: this.datas || [],
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('guild.col_name'), key: 'name' },
        { title: 'Token', key: 'token', className: 'chain', },
        { title: this.$t('guild.col_price'), key: 'price', sorter: true },
        { title: this.$t('guild.col_market_cap'), key: 'price_total', sorter: true },
        { title: this.$t('guild.col_community'), key: 'social_score', sorter: true },
        { title: this.$t('guild.col_country'), key: 'country' },
      ],
      guildSearch: [],
      loading: true,
    };
  },

  mounted() {
    this.getGuilds()
  },
  methods: {
    filterChange({ key, order }) {
      this.guildSearch = order ? [key + (order == 'asc' ? '_1' : '_2')] : []
      this.getGuilds()
    },

    onHandlePageSize({ value }) {
      this.page = 1
      this.size = value;
      this.getGuilds()
    },
    onHandlePage(page) {
      this.page = page;
      this.getGuilds()
    },
    seeMore() {
      if (this.showPage) {
        this.page++
        this.getGuilds()
      } else {
        window.location.href = '/rank/guilds'
      }
    },
    getGuilds() {
      let { guildSearch, size } = this
      let order_list = JSON.stringify(guildSearch)
      this.loading = true
      this.$http.get('/api/v1/meta/guild/list', { limit: size, offset: (this.page - 1) * size, order_list }, ({ result }) => {
        this.total = result.count
        let guildData = result.records.map((m, i) => {
          m.no = i + ((this.page - 1) * size) + 1
          return m
        })
        if (this.h5) {
          this.guildData = this.guildData.concat(guildData)
        } else {
          this.guildData = guildData
        }
        this.loading = false
      })
    },
  },
  render() {
    let { h5, guildData, columns, size, page, total, pageRanges, loading, showViewall, showPage } = this
    if (h5 && !guildData.length) {
      guildData = [{}, {}, {}, {}, {}]
    }
    return (
      <div class="guild-box">
        {
          h5 ?
            <HomeGuildH5 data={guildData} loading={loading} /> :
            <HomeGuildPc loading={loading} onFilter={this.filterChange} data={guildData} columns={columns} />
        }
        {
          (total > 30 && h5 && showPage) ? (
            guildData.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
          ) : null
        }

        {(!h5 && showPage) &&
          <div class="page-wrap">
            <Page total={total} current={page} pageSize={size} onChange={this.onHandlePage} />
            <div class="page-sizer flex-base">
              <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
              <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
            </div>
          </div>}
        {showViewall && <a href="/rank/guilds"><div class="more">{this.$t('com.view_all')}</div></a>}
      </div>
    )
  }
};
