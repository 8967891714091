//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/data-index.less'
import moment from 'moment'
import maps from './map'
import { mapGetters } from 'vuex';
export default {
  layout: 'index',
  data() {
    return {
      date: [
        { value: '7', label: '7D' },
        { value: '30', label: '30D' },
        { value: '365', label: 'ALL' },
      ],
      date2: [
        { value: '7', label: '7D' },
        { value: '30', label: '30D' },
      ],
      date_games: '365',
      date_game_chain: '365',
      date_game_chain_rate: '365',
      date_chain_gamers: '365',
      date_chain_gamers_rate: '365',
      date_users: '365',
      date_volume: '365',
      date_volume_chains: '365',
      date_volume_chains_rate: '365',
      date_transactions: '365',
      date_transactions_chains: '365',
      date_transactions_chains_rate: '365',
    }
  },
  computed: {
    ...mapGetters(['theme'])
  },
  mounted() {
    this.initMap()
    this.getChainVolume()
    this.getGameChains()
    this.getGamersChainsRate()
    this.getTransactionChains()
  },
  methods: {

    initMap() {
      ['users', 'volume', 'transactions', 'games'].forEach(id => {
        let ele = document.getElementById('map_' + id)
        if (ele) {
          let chart = this['chart_' + id] || echarts.init(ele)

          let maskColor = this.theme == 'dark' ? '#2c2c2c' : '#fff'
          chart.showLoading({ text: '', spinnerRadius: 15, maskColor })
          if (!this['chart_' + id]) {
            this['chart_' + id] = chart
            window.addEventListener('resize', () => {
              setTimeout(() => {
                chart.resize()
              }, 600);
            })
          }
        }
      })
    },
    renderMap(id, xAxis = [], series = []) {
      let { h5 } = this
      let chart = this['chart_' + id]
      if (!chart) return;
      this['xAxis_' + id] = xAxis
      this['series_' + id] = series
      let option = maps.getLineOpions(id, h5, xAxis, series, this.utils);
      chart.setOption(option)
      chart.hideLoading()
    },
    initEvent(chart, id, chain, date) {
      chart.hideLoading()
      if (!this['chart_' + id]) {
        this['chart_' + id] = chart
        this['dates_' + id] = date
        window.addEventListener('resize', () => {
          setTimeout(() => {
            chart.resize()
          }, 600);
        })
      }
      if (id) this['series_' + id] = chain
    },
    getPieData(chains, days, minPercent = 1.2) {
      let data = [], total = 0;

      for (let key in chains) {
        let value = 0, chain = chains[key];
        if (days) {
          chain = chain.slice(days)
        }
        // console.log(chain, days)
        value = chain.reduce((a, b) => a + b)
        total += value
        data.push({
          name: key,
          value
        })
      }
      let result = [], other = 0;
      data.forEach((a, b) => {
        if ((a.value / total) * 100 > minPercent) {
          result.push(a)
        } else {
          // other += a.value
        }
      })
      // console.log(data, total)
      // result.push({
      //   name: 'Other',
      //   value: other
      // })
      return result
    },
    getBarData(chains, days, min = 1) {
      let series = [], total = 0;
      for (let key in chains) {
        let data = chains[key]
        let value = data.reduce((a, b) => a + b);
        if (days) {
          data = data.slice(days)
        }
        total += value;
        // console.log(value)
        series.push({
          name: key,
          type: 'bar',
          large: true,
          stack: 'total',
          total: value,
          barMaxWidth: 15,
          // label: { show: true },
          emphasis: { focus: 'series' },
          data: data//.slice(-30)
        })
      }
      let result = [], others = [];
      series.forEach((a, b) => {
        if ((a.total / total) * 100 > min) {
          delete a.total
          result.push(a)
        } else {
          // if (!others.length) {
          //   others = a.data
          // } else {
          //   if (others.length > a.data.length) {
          //     others = others.map((x, i) => x = x * 1 + (a.data[i] || 0) * 1)
          //   } else {
          //     others = a.data.map((x, i) => x = (x) * 1 + (others[i] || 0) * 1)
          //   }
          // }
        }
      })

      // result.push({
      //   name: 'Other',
      //   type: 'bar', large: true,
      //   stack: 'total',
      //   barMaxWidth: 15,
      //   // label: { show: true },
      //   emphasis: { focus: 'series' },
      //   data: others
      // })

      return result
    },
    getGamesPieData(chain, days) {
      let data = []
      let other = 0
      for (let key in chain) {
        let value = chain[key].slice(-1)[0]

        if (value >= 50) {
          data.push({
            name: key,
            value: value
          })
        } else {
          other += value * 1
        }
      }
      data.push({
        name: 'Other',
        value: other
      })
      return data
    },
    dateChange(key) {
      let day = this['date_' + key] * -1
      if (!this['chart_' + key]) return;
      if (key == 'game_chain' || key == 'chain_gamers' || key == 'volume_chains' || key == 'transactions_chains') {
        // let series = []
        // let chain = this['series_' + key]
        // let dates = this['dates_' + key]
        // let type = key == 'volume_chains' ? 'line' : 'bar'
        // for (let key in chain) {
        //   series.push({
        //     name: key,
        //     // type,
        //     //  stack: 'total', 
        //     // data: chain[key].slice(day), smooth: true, showSymbol: false, lineStyle: { width: 1 }
        //     data: chain[key].slice(day)
        //   })
        // }
        // let series = this.getBarData(chain, day)
        let dataZoom = [
          {
            type: 'slider',
            start: 100 - (Math.abs(day) / 365) * 100,
            end: 100
          }
        ]
        this['chart_' + key].setOption({ dataZoom })
        return;
      }
      if (key == "game_chain_rate" || key == 'chain_gamers_rate' || key == 'volume_chains_rate' || key == 'transactions_chains_rate') {
        let chain = this['series_' + key], percent = 2;
        if (key == 'transactions_chains_rate') percent = 1;
        let result = this.getPieData(chain, day, percent)
        this['chart_' + key].setOption({ series: { data: result } })
        return;
      }

      let xAxis = this['xAxis_' + key] || []
      let datas = this['series_' + key] || []
      xAxis = xAxis.slice(day)
      let series = datas.slice(day)

      this['chart_' + key].setOption({
        xAxis: { data: xAxis },
        series: [
          { data: series },
          // { name: this.symbol, data: symbol },
        ]
      })
    },
    changeRender(item) {
      let key = Object.keys(item)[0]
      let result = item[key]
      switch (key) {
        case 'users':
          let user = result.map(x => x[1])
          let xAxis_user = result.map(x => moment(x[0]).format('YYYY/MM/DD'))
          this.renderMap('users', xAxis_user, user)
          break;
        case 'volumes':
          let volume = result.map(x => x[1])
          let xAxis_volume = result.map(x => moment(x[0]).format('YYYY/MM/DD'))
          this.renderMap('volume', xAxis_volume, volume)
          break;
        case 'transactions':
          let transactions = result.map(x => x[1])
          let xAxis_tran = result.map(x => moment(x[0]).format('YYYY/MM/DD'))
          this.renderMap('transactions', xAxis_tran, transactions)
          break;

        case 'games':
          let data_games = result.map(x => x[1])
          let xAxis_games = result.map(x => moment(x[0]).format('YYYY/MM/DD'))
          this.renderMap('games', xAxis_games, data_games)
          break;
        default:
          break;
      }
    },
    //各公链交易额度/占比
    getChainVolume() {
      let maskColor = this.theme == 'dark' ? '#2c2c2c' : '#fff'
      let barsMap = this['chart_volume_chains'] || echarts.init(document.getElementById('map_volume_chains'))
      barsMap.showLoading({ text: '', spinnerRadius: 15, maskColor })

      let pieMap = this['chart_volume_chains_rate'] || echarts.init(document.getElementById('map_volume_chains_rate'))
      pieMap.showLoading({ text: '', spinnerRadius: 15, maskColor })

      this.$http.get('/api/v1/meta/market/query_volume_chains', { timestamp: parseInt(Date.now() / 1000) }, ({ result }) => {
        let date = [], chain = {};
        result.sort()
        result.forEach(item => {
          let [a, b, c] = item
          c = (c * 1).toFixed(2) * 1
          if (date.indexOf(a) < 0) { date.push(a) }
          if (chain[b]) { chain[b].push(c) } else { chain[b] = [c] }
        })

        let series = this.getBarData(chain, null, 0.1)

        date = date.map(x => moment(x).format('MMM DD'))

        let { h5 } = this
        let options = maps.getBarsOptions(h5, this.utils, series, date);

        barsMap.setOption(options)
        this.initEvent(barsMap, 'volume_chains', chain, date)

        {
          let result = this.getPieData(chain, null, 0.1)
          let options = maps.getPieOptions(result)
          pieMap.setOption(options)
          this.initEvent(pieMap, 'volume_chains_rate', chain)
        }
      })
    },
    //各公链项目走势/占比
    getGameChains() {
      let maskColor = this.theme == 'dark' ? '#2c2c2c' : '#fff'
      let barsMap = this['chart_game_chain'] || echarts.init(document.getElementById('map_game_chain'))
      barsMap.showLoading({ text: '', spinnerRadius: 15, maskColor })

      let pieMap = this['chart_game_chain_rate'] || echarts.init(document.getElementById('map_game_chain_rate'))
      pieMap.showLoading({ text: '', spinnerRadius: 15, maskColor })

      this.$http.get('/api/v1/meta/market/query_games_chains', { timestamp: parseInt(Date.now() / 1000) }, ({ result }) => {
        let date = [], chain = {};

        result.sort()

        result.forEach(item => {
          let [a, b, c] = item
          if (date.indexOf(a) < 0) { date.push(a) }
          if (chain[b]) { chain[b].push(c) } else { chain[b] = [c] }
        })
        let series = [], others = [];

        for (let key in chain) {
          let data = chain[key]
          let value = data.slice(-1)
          if (value >= 15) {
            series.push({
              name: key,
              type: 'bar', large: true,
              stack: 'total',
              barMaxWidth: 15,
              // label: { show: true },
              emphasis: { focus: 'series' },
              data: data
            })
          } else {
            if (!others.length) {
              others = data
            } else {
              if (others.length > data.length) {
                others = others.map((x, i) => x = x * 1 + (data[i] || 0) * 1)
              } else {
                others = data.map((x, i) => x = (x) * 1 + (others[i] || 0) * 1)
              }

            }
          }
        }
        // console.log(others)

        series.push({
          name: 'Other',
          type: 'bar', large: true,
          stack: 'total',
          barMaxWidth: 15,
          // label: { show: true },
          emphasis: { focus: 'series' },
          data: others
        })

        date = date.map(x => moment(x).format('MMM DD'))

        let { h5 } = this
        let options = maps.getBarsOptions(h5, this.utils, series, date);

        barsMap.setOption(options)
        this.initEvent(barsMap, 'game_chain', chain, date)


        {

          // let total = data.map(x => x.value*1).reduce((a, b) => a + b)
          // data = data.map(x => {
          //   x.value = ((x.value / total) * 100).toFixed(2)
          //   return x
          // })
          let data = this.getGamesPieData(chain)
          let options = maps.getPieOptions(data)

          pieMap.setOption(options)
          this.initEvent(pieMap, 'game_chain_rate', data)
        }
      })
    },
    //各公链活跃用户/占比
    getGamersChainsRate() {
      let maskColor = this.theme == 'dark' ? '#2c2c2c' : '#fff'
      let barsMap = this['chart_chain_gamers'] || echarts.init(document.getElementById('map_chain_gamers'))
      barsMap.showLoading({ text: '', spinnerRadius: 15, maskColor })

      let pieMap = this['chart_chain_gamers_rate'] || echarts.init(document.getElementById('map_chain_gamers_rate'))
      pieMap.showLoading({ text: '', spinnerRadius: 15, maskColor })

      this.$http.get('/api/v1/meta/market/query_gamers_chains', { timestamp: parseInt(Date.now() / 1000) }, ({ result }) => {
        let date = [], chain = {};
        // result = result.slice(-30)
        result.reverse()
        result.forEach(item => {
          let [a, b, c] = item
          if (date.indexOf(a) < 0) { date.push(a) }
          if (chain[b]) { chain[b].push(c) } else { chain[b] = [c] }
        })

        let series = this.getBarData(chain, null, 0.3)

        date = date.map(x => moment(x).format('MMM DD'))

        let { h5 } = this
        let options = maps.getBarsOptions(h5, this.utils, series, date);

        barsMap.setOption(options)
        this.initEvent(barsMap, 'chain_gamers', chain, date)
        //
        {
          let result = this.getPieData(chain, null, 0.3)
          let options = maps.getPieOptions(result)
          pieMap.setOption(options)
          this.initEvent(pieMap, 'chain_gamers_rate', chain)
        }
      })
    },
    //各公链交易次数走势/占比
    getTransactionChains() {
      let maskColor = this.theme == 'dark' ? '#2c2c2c' : '#fff'
      let barsMap = this['chart_transactions_chains'] || echarts.init(document.getElementById('map_transactions_chains'))
      barsMap.showLoading({ text: '', spinnerRadius: 15, maskColor })

      let pieMap = this['chart_transactions_chains_rate'] || echarts.init(document.getElementById('map_transactions_chains_rate'))
      pieMap.showLoading({ text: '', spinnerRadius: 15, maskColor })

      this.$http.get('/api/v1/meta/market/query_transactions_chains', { timestamp: parseInt(Date.now() / 1000) }, ({ result }) => {
        let date = [], chain = {};
        // result = result.slice(-30)
        result.forEach(item => {
          let [a, b, c] = item
          if (date.indexOf(a) < 0) { date.push(a) }
          if (chain[b]) { chain[b].push(c) } else { chain[b] = [c] }
        })

        // let series = []
        // for (let key in chain) {
        //   series.push({
        //     name: key,
        //     type: 'bar', large: true,
        //     stack: 'total',
        //     barMaxWidth: 15,
        //     // label: { show: true },
        //     emphasis: { focus: 'series' },
        //     data: chain[key]//.slice(-30)
        //   })
        // }
        let series = this.getBarData(chain, null, 0.2)

        date = date.map(x => moment(x).format('MMM DD'))//.slice(-30)

        let { h5 } = this
        let options = maps.getBarsOptions(h5, this.utils, series, date);

        barsMap.setOption(options)
        this.initEvent(barsMap, 'transactions_chains', chain, date)
        //
        {
          let result = this.getPieData(chain, null, 0.2)
          let options = maps.getPieOptions(result)
          pieMap.setOption(options)
          this.initEvent(pieMap, 'transactions_chains_rate', chain)
        }
      })
    }
  }
}
