//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['h5'])
  },
  props: {
    type: String,
    data: Array,
    columns: Array,
    total: Number,
  },
  methods: {
    rowClick(item) {
      if (this.type == 'check') {
        this.$router.push(`/games/${item.game_name}?type=community`)
      } else {
        open(`https://twitter.com/${item.twitter_id}`)
      }
    },
    filterChange(a, b) {
      this.$emit('filter', b)
    },
  }
}
