import { render, staticRenderFns } from "./h5.vue?vue&type=template&id=f6eca648&"
import script from "./h5.vue?vue&type=script&lang=js&"
export * from "./h5.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DateFilter: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/dateFilter.vue').default,Rates: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/Rates.vue').default,Social: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/social/index.vue').default})
