import { render, staticRenderFns } from "./game.vue?vue&type=template&id=3ea3ed0d&"
import script from "./game.vue?vue&type=script&lang=js&"
export * from "./game.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeader: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/layout/header.vue').default,LayoutFooter: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/layout/footer.vue').default})
