import { render, staticRenderFns } from "./view.vue?vue&type=template&id=21afa91f&"
import script from "./view.vue?vue&type=script&lang=js&"
export * from "./view.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SubjectsViewitem: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/viewitem.vue').default})
