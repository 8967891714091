//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  props: {
    showChaindata: Boolean
  },
  computed: {
    ...mapGetters(['h5', 'theme'])
  },
  data() {
    return {
      colors: ['orange', 'cyan', 'green', 'magenta', 'blue'],
      game: 'alien-worlds-1',
      date: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 90, label: '3M' },
        { value: 365, label: 'All' },
      ],
      date_growth_hold: 30,
      // date_growth_rate: 30,
      symbols: [],
      price_total: '--',
      growth_total: 0,
      // growth_rate_total: 0,
      symbol_series: [],
      gridText: {
        'growth_hold': this.$t('games.token_holder'),
        // 'growth_rate': this.$t('games.token_holder_rate'),
      },
      showToken: true,
    }
  },
  mounted() {
    let game = this.$route.params.id
    game = game == 'game' || !game ? 'alien-worlds-1' : game;
    this.game = game
    this.init()
  },
  methods: {
    init() {
      this.date_growth_hold = 30
      // this.date_growth_rate = 30

      this.initMap()
      this.getSymbol()
    },
    initMap() {
      let { h5, theme } = this
      let leftColor = theme == 'dark' ? '#717171' : '#999'
      let dashedColor = theme == 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#dddddd60'

      let option = {
        graphic: [{
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: h5 ? 100 : 200, height: h5 ? 19 : 38 }
        },
        {
          rotation: Math.PI / 2,
          type: 'text', left: '5px', top: 'middle', style: { fill: leftColor, text: 'Token Price ($)', font: '14px "Inter"' }
        },
        {
          rotation: Math.PI / -2,
          type: 'text', right: '5px', top: 'middle', style: { fill: leftColor, text: 'Token Price ($)', font: '14px "Inter"' }
        }
        ],
         tooltip: { extraCssText: "z-index:1", trigger: 'axis', backgroundColor: '#121326', borderWidth: 0, textStyle: { color: '#fff' }, axisPointer: { label: { backgroundColor: '#6a7985' } } },
        // tooltip: {
        //   extraCssText: "z-index:1", trigger: 'axis',
        //   backgroundColor: '#121326', borderWidth: 0,
        //   textStyle: { color: '#fff' },
        //   axisPointer: { label: { backgroundColor: '#6a7985' } },
        //   formatter: (p) => {
        //     let str = `<p>${p[0].axisValueLabel}</p>`

        //     p.forEach((x) => {
        //       let bfh = x.seriesName == this.gridText.growth_rate ? '%' : ''
        //       str += `<div class="k-row k-row-flex"><span class="k-col" style="margin-right:30px;flex:1">${x.marker}${x.seriesName}:</span><span class="k-col">${x.data}${bfh}</span></div>`
        //     })
        //     // console.log(p)
        //     return str
        //   },
        //   // valueFormatter: (v) => 'aaa' + v + '%'
        // },
        grid: { borderColor: 'transparent', /*backgroundColor: '#F9F9FB',*/ show: true, left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        xAxis: {
          type: 'category', data: [],
          axisLine: { show: false },
          axisLabel: { color: "#666", formatter: v => moment(new Date(v)).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
        legend: { data: [], icon: 'circle', bottom: 0, textStyle: { color: '#999', fontSize: 12 }, itemHeight: 8, },
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => {

                if (v > 0 && v < 1 || v < 0) {
                  return parseFloat(this.utils.changeUnit1(v)).toFixed(2)
                } else {
                  return this.utils.changeUnit1(v)
                }

              }

            },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor,
                width: 1,
                type: 'dashed'
              }
            },
            min: value => value.min,
            max: value => value.max,
          },
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor,
                width: 1,
                type: 'dashed'
              }
            },
          },
        ],
        series: [
          {
            name: '',
            yAxisIndex: 0,
            data: [], type: 'bar',
            barMaxWidth: 15,
            barMinHeight: 8,
            itemStyle: { color: '#7266FF' }
          },
          { type: 'line', }, { type: 'line', }, { type: 'line', }, { type: 'line', },
        ]
      };
      ['growth_hold'].forEach(id => {
        let chart = echarts.init(document.getElementById('map_' + id))
        this['char_' + id] = chart
        chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' })
        option.graphic[1].style.text = this.gridText[id]
        option.series[0].name = this.gridText[id]
        // option.yAxis[0].name = this.gridText[id]

        let line = {
          type: 'value',
          axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13 },
          splitLine: { show: false, },
        }
        option.yAxis[1] = Object.assign({}, line)
        option.yAxis[2] = Object.assign({}, line, { axisLabel: { show: false } })
        option.yAxis[3] = Object.assign({}, line, { axisLabel: { show: false } })
        chart.setOption(option)
        window.addEventListener('resize', () => {
          chart.resize()
        })
      })
    },
    dateChange(key) {
      try {
        let xAxis = this['xAxis_' + key]
        let datas = this['series_' + key]
        let day = this['date_' + key] * -1

        let symbol_series = [].concat([], this.symbol_series)
        symbol_series = symbol_series.map(x => {
          x.data = this['series_' + x.name].slice(day)
          return x
        })

        this['char_' + key].setOption({
          xAxis: { data: xAxis.slice(day) },
          series: [
            { data: datas.slice(day) },
            ...symbol_series
          ]
        })
      } catch (e) { console.log(e) }
    },
    renderMap(id, xAxis = [], series = [], index = 0) {
      try {
        let chart = this['char_' + id]
        chart.hideLoading()
        // let n = new Date(), y = n.getFullYear(), m = n.getMonth() + 1;
        let count = 365//new Date(y, m, 0).getDate() //本月有多少天
        let day = 30
        if (xAxis.length < count) {
          let t = count - xAxis.length
          let j = xAxis.slice(0, 1)[0] || Date.now();
          let f = moment(j).subtract(t, 'days')
          let nx = []
          for (let i = 0; i < t; i++) {
            nx.push(f.format('YYYY/MM/DD'))
            f.add(1, 'days')
          }
          xAxis = [].concat(nx, xAxis)
        }
        //series
        if (series.length < count) {
          let ns = new Array(count - series.length).fill('0')
          series = [].concat(ns, series)
        }

        xAxis = xAxis.map(x => x.replace(/-/g, '/'))

        let symbol_series = [].concat([], this.symbol_series)
        symbol_series = symbol_series.map(x => {
          x.data = this['series_' + x.name].slice(-day)
          return x
        })
        let option = {
          xAxis: { data: xAxis.slice(-day) },
          series: [{
            yAxisIndex: index,//0,
            data: series.slice(-day)
          }, ...symbol_series]
        }
        // let chart = this['char_' + id] || echarts.init(document.getElementById('map_' + id))
        this['xAxis_' + id] = xAxis
        this['series_' + id] = series
        chart.setOption(option)
      } catch (e) { console.log(e) }
    },
    getSymbol() {
      this.$http.get('/api/v1/meta/token/price', { game_name: this.game, days: 365 }, ({ result }) => {
        if (!result.records.length) {
          this.showToken = false
          return
        }
        try {
          let lists = result.records || []
          let symbols = [];
          let yAxisIndex = 1;
          let symbol_series = []

          lists.forEach((s, i) => {
            let list = s.rate_list || []
            list = list.map(x => parseFloat(x || 0))
            if (list.length < 365) {
              let nlist = new Array((365 - list.length)).fill('')
              list = [].concat(nlist, list)
            }
            symbols.push({
              title: `${s.symbol} ${this.$t('games.price')}`,
              value: '$' + parseFloat(list.slice(-1)[0] || 0)
            });
            this['series_' + s.symbol] = list
            // console.log(this['series_' + s.symbol] )
            let items = {
              name: s.symbol, showSymbol: false,
              itemStyle: { color: this.colors[i] },
              lineStyle: { width: 2, color: this.colors[i] }, data: list, yAxisIndex,
            }
            symbol_series.push(Object.assign({}, items))
          })
          // console.log(symbols)
          this.symbols = symbols
          this.symbol_series = symbol_series
        } catch (e) { console.log(e) }
        this.getHoldGrowth()
        // this.getHoldGrowthRate()
      })
    },
    getHoldGrowth() {
      this.$http.get('/api/v1/meta/token/holdGrowth', { game_name: this.game, days: 365 }, res => {
        let { date_list = [], num_list = [] } = res.result.records[0] || {}
        if (!date_list.length) {
          // return
        }
        date_list = date_list.reverse()
        num_list = num_list.reverse()
        this.renderMap('growth_hold', date_list, num_list)
        let growth = num_list.slice(-1)[0]//reduce((a, b) => a + b)
        this.growth_total = this.utils.changeUnit(growth)
      })
    },
    getHoldGrowthRate() {
      this.$http.get('/api/v1/meta/token/holdGrowth/rate', { game_name: this.game, days: 365 }, res => {
        let { date_list = [], num_list = [] } = res.result.records[0] || {}
        date_list = date_list.reverse()
        num_list = num_list.reverse()
        // num_list = num_list.map(m => {
        //   m = this.utils.multiply(m,1)
        //   return m
        // })
        this.renderMap('growth_rate', date_list, num_list)
        let growth_rate = num_list.slice(-1)[0]
        this.growth_rate_total = this.utils.changeUnit(growth_rate)
      })
    },
  }
}
