//
//
//
//
//
//
//
//
//

export default {
  name: 'Tag',
  data() {
    return {
      tagData: this.$store.state.tagData,
    }
  },
  props: {
    tags: Array,
  }
}
