
import '~/assets/less/ranking.less'
import { mapGetters } from 'vuex'
import { ChevronDown } from "kui-icons";
export default {
  layout: 'index',
  props: {
    isMain: Boolean,
    type: String,
  },
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      search: [],
      rowIndex: 1,
      loading: false,
      page: 1,
      total: 0,
      data: [],
      size: this.isMain ? 10 : 30,
      item: {},
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('social.col_tw'), key: 'name' },
        { title: this.$t('social.col_follower'), key: 'kol_followers', sorter: true, render: (h, p) => h('span', this.utils.changeUnit(p.kol_followers)) },
      ],
      checkColumns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('social.col_game'), key: 'name' },
        { title: this.$t('social.col_follower'), key: 'kol_count', sorter: true, render: (h, p) => h('span', this.utils.changeUnit(p.kol_count)) },
      ],
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    filterChange({ key, order }) {
      this.data = this.data.sort((a, b) =>  //按年龄大小排序
        order == 'asc' ?
          a[key] - b[key] :
          b[key] - a[key]
      )
      this.data.forEach((m, n) => {
        m.no = n + ((this.page - 1) * this.size) + 1;
      });
    },
    onHandlePageSize({ value }) {
      this.size = value
      this.initPage()
    },
    onHandlePage(page) {
      this.page = page;
      this.getData()
    },
    initPage() {
      this.page = 1
      this.$refs.page.toPage(1)
    },
    getData(platform) {
      let { size, search, page, type } = this
      this.loading = true
      let url = type == 'check' ? '/api/v1/meta/kol/games' : '/api/v1/meta/kol/twitter';
      let sortList = JSON.stringify(search)
      this.$http.get(url, { limit: size, offset: (page - 1) * size, order_list: sortList }, ({ result }) => {
        this.total = result.count
        result.records.map((m, i) => {
          m.no = i + ((page - 1) * size) + 1;
          return m
        })
        if (page == 1) this.data = []
        if (platform) {
          this.data = this.data.concat(result.records)
        } else {
          this.data = result.records
        }

        this.loading = false
      }, err => {
        this.loading = false
      })
    },
    seeMore() {
      this.page++
      this.getData('is_h5')
    },
  },
  render() {
    let { data, columns, total, h5, filterChange, isMain, page, size, pageRanges, type, checkColumns } = this
    let title = 'social.' + type + '_title'
    let sub_title = 'social.' + type + '_sub_title'
    return (
      <div class="ranking page-nft page-social">
        <div class="rank-title-wrap">
          <div class="title ellipsis"><span>{this.$t(title)}</span></div>
          <div class="sub-title">
            <span class="sub-desc">{this.$t(sub_title)}</span>
          </div>
        </div>
        <SocialkolTable data={data} columns={type == 'check' ? checkColumns : columns} total={total} onPage={this.onHandlePage} type={type} onSize={this.onHandlePageSize} onFilter={filterChange} onMore={this.seeMore} />

        {isMain && <a class="load-more flex-base" href={'/social/' + type}>{this.$t('com.view_all')}<Icon type={ChevronDown} size="20" /></a>}
        {
          !h5 && !isMain ?
            <div class="page-wrap">
              <Page total={total} current={page} pageSize={size} size="small" onChange={this.onHandlePage} ref="page"></Page>
              <div class="page-sizer flex-base">
                <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
                <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
              </div>
            </div> : null
        }
        {
          total > 30 && h5 && !isMain ?
            data.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
            : null
        }
      </div>
    )
  }
};
