
export default {
  props: {
    actived: Boolean,
    item: Object,
    icon: Boolean,
    fixedname: String//固定的图标
  },
  methods: {
    click(e) {
      this.$emit('click', e)
    }
  },
  mounted() {
  },
  render(h) {
    let { actived, icon, item, fixedname } = this
    return (
      <div class={['tab flex-base', { 'active': actived }]} onClick={this.click}>
        {icon ? <Chain name={item.name} fill={actived ? '#6642FF' : '#999'} /> : null}
        {fixedname ? <Chain name={fixedname} fill={'#6642FF'} /> : null}
        <div class="symbol">{item.show_name}</div>
      </div >
    )
  }
}
