import { render, staticRenderFns } from "./card.vue?vue&type=template&id=050cfc10&"
import script from "./card.vue?vue&type=script&lang=js&"
export * from "./card.vue?vue&type=script&lang=js&"
import style0 from "./card.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Rates: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/Rates.vue').default})
