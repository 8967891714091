//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/layout/index.less'
import { mapGetters } from 'vuex'
export default {
  head({ $store, $router }) {
    if ($store.state.notfound) {
      return $router.replace(`/notfound`)
    }
  },
  data() {
    return {
      root: ''
    }
  },
  computed: {
    ...mapGetters(['menuActiveKey', 'h5'])
  },
  mounted() {
  },
  methods: {

  }
}
