
import './index.less'
export default {
  props: {
    icon: String,
    text: String
  },
  render() {
    let { icon, text } = this
    if (text == 'binance-smart-chain') text = 'BSC'
    if (text == 'ethereum') text = 'ETH'
    if (text == 'okexchain') text = 'OEC'
    if (icon == 'ethereum') icon = 'eth'
    if (icon == 'binance-smart-chain') icon = 'bsc'
    return (
        <Tooltip showArrow={false}>
          <span class='my-tag tag-tooltip'>{icon ? <Chain name={icon} /> : null}</span>
          <span slot="title" class="icon-tooltip">{text}</span>
        </Tooltip>
    )
  }
}
