//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['lang', 'h5'])
  },
  data() {
    return {
      news: [],
      loading: true
    }
  },
  mounted() {
    this.getNews()
  },
  methods: {
    getNews() {
      let query_name = this.$route.params.id
      if (query_name == 'melody' || query_name == 'read2n') {
        this.$http.get('/api/v1/news/list', { limit: 100, offset: 0, type: 'trends', query_name }).then(({ result }) => {
          let len = 100
          this.news = result.records.filter(m => m.content || m.content_en).map((m, i) => {
            let text = this.lang == 'en' ? (m.content_en || m.content) : (m.content || m.content_en)
            m.text = this.utils.subString(text, len)
            m.all = text
            m.open = this.utils.getLength(text) > len
            return m
          })
          setTimeout(() => {
            this.loading = false
          }, 300);
        })
      } else {
        this.$http.get('/api/v1/news/game/news', { limit: 38, offset: 0, query_name }).then(({ result }) => {
          let len = 100
          this.news = result.records.map((m, i) => {
            let text = this.lang == 'en' ? m.title_en || m.title : m.title
            m.text = this.utils.subString(text, len)
            m.all = text
            m.open = this.utils.getLength(text) > len
            return m
          })
          setTimeout(() => {
            this.loading = false
          }, 300);
        })
      }
    },
  }
}
