
import { ChevronDown } from 'kui-icons'
export default {
  data() {
    return {
      current:  '',
      tabs: [
        { name: 'all', show_name: this.$t('com.alltabs'), key: "0" },
      ],
      show: false
    }
  },
  props: {
    black_chain: String,
  },
  watch: {
    black_chain: {
      handler(v) {
        if (v) {
          this.current = v || 'all'
        }
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    this.getChain()
  },
  methods: {
    hide() {
      this.show = !this.show
    },
    setChain({ key }) {
      this.current = key
      this.$emit('change', key)
    },
    getChain() {
      this.$http.get('/api/v1/meta/chain/list', { limit: 50, offset: 0 }, ({ result }) => {
        this.tabs = this.tabs.concat(result.records)
      })
    },
  },
  render() {
    let { tabs, current, show } = this
    let item = (!current ? tabs[0] : tabs.filter(x => x.name == current)[0]) || {}
    const props = {
      props: {
        value: show
      },
      on: {
        click: this.setChain,
        input: e => this.show = e
      }
    }
    return (
      <Dropdown trigger='normal' {...props} >
        <div class="top-drop flex-base" onClick={this.hide}>
          <GameTab item={item} icon={true} />
          {<Icon type={ChevronDown} />}
        </div>
        <Menu slot="content" class="chain-menu">
          {tabs.map((item, i) => {
            return (
              <MenuItem key={item.name} >
                <GameTab icon={true} item={item} actived={current == item.name} />
              </MenuItem>
            )
          })}
        </Menu>
      </Dropdown>
    )
  }
}

