
import "./index.less";
import { mapGetters, mapMutations } from "vuex";
import { ChevronDown, Search } from "kui-icons";
// let h5 = /(Android|webOS|iPhone|iPod|tablet|BlackBerry|Mobile)/i.test(window.navigator.userAgent)
export default {
  props: {
    // loading: Boolean,
    showSearch: Boolean,
    showViewall: Boolean,
    showPage: Boolean,
    sorter: { type: Boolean, default: true },
    showDate: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters(["h5", "paramsStatus"]),
  },
  data() {
    return {
      hide: true,
      search: [],
      name: "",
      rowIndex: 1,
      days: 1,
      black_chain: "",
      load: true,
      page: 1,
      total: 0,
      data: [],
      size: 10,
      pageRanges: [
        { label: "10", value: 10 },
        { label: "30", value: 30 },
        { label: "50", value: 50 },
      ],
      all: { name: "all", show_name: this.$t("com.alltabs") },
      tabs: [{ name: "all", show_name: this.$t("com.alltabs"), key: "0" }],
      columns: [
        {
          title: "#",
          key: "game_id",
          align: "right",
          fixed: "left",
          width: 60,
        },
        {
          title: this.$t("games.col_name"),
          key: "name",
          fixed: "left",
          width: 320,
        },
        {
          title: this.$t("games.col_new_users"),
          key: "new_users",
          width: 150,
          className: "value",
        },
        {
          title: this.$t("games.col_active_user"),
          key: "active",
          sorter: this.sorter,
          width: 150,
        },
        {
          title: this.$t("games.col_active_user_7d"),
          key: "active_user_7d",
          width: 150,
        },
        {
          title: this.$t("games.col_total_users_v0"),
          key: "total_users_v0",
          width: 150,
          className: "value",
        },

        {
          title: this.$t("games.col_volume"),
          key: "volume",
          sorter: this.sorter,
          width: 150,
        },
        { title: this.$t("games.col_volume_7d"), key: "volume_7d", width: 150 },

        {
          title: this.$t("games.token_symbol"),
          key: "token_symbol",
          width: 150,
        },
        { title: this.$t("games.token_price"), key: "token_price", width: 150 },
        {
          title: this.$t("games.token_change_rate_24h"),
          key: "token_change_rate_24h",
          width: 150,
        },
        { title: "Token Holders", key: "token_holders", width: 150 },
        {
          title: this.$t("games.col_community"),
          key: "social",
          sorter: this.sorter,
          width: 150,
        },
        { title: "Twitter", key: "twitter", width: 150 },
        { title: "Discord", key: "discord", width: 150 },
        { title: "Telegram", key: "telegram", width: 150 },

        {
          title: this.$t("games.twitter_hot"),
          key: "total_engagements",
          className: "value",
          width: 150,
        },
        {
          title: "Mentions KOL",
          key: "mentions_kol",
          width: 150,
          className: "value mentions-kol",
          render: (h, p) => h("span", this.utils.changeUnit(p.mentions_kol)),
        },
        {
          title: this.$t("games.col_transfers"),
          key: "hot",
          sorter: this.sorter,
          width: 150,
        },
        { title: this.$t("games.col_tag"), key: "tags", width: 150 },
      ],
      baseColumns: [],
      basedata: [],
      order: "desc", //排序
      hasChange: "",
      currentTag: 0,
      tag: "",
      width: 1700,
      show: true,
      showBar: true,
      showLine: true,
      showTag: true,
      engagements: 0,
      engagement: 0,
      time: null,
      flag: true,
      height: null,
      adGame: {},
    };
  },
  mounted() {
    this.baseColumns = [...this.columns];
    if (this.h5) {
      this.width = 1350;
      this.columns[0].width = 55;
      this.columns[1].width = 170;
    }
    //part 一部分游戏 重新弄个组件 后期要改的时候再把此组件重构下
    // this.getData();
    const storeBtn = JSON.parse(localStorage.getItem("btn") || "[]");
    if (storeBtn.length) {
      this.customize(storeBtn);
    } else {
      this.getData();
    }
  },
  methods: {
    ...mapMutations(["setParamsStatus"]),
    dayChange(item) {
      this.days = item.value;
      this.page = 1;
      this.getData();
    },
    filterChange({ key, order }) {
      if (order) {
        this.search = order ? [key + (order == "asc" ? "_1" : "_2")] : [];
      }
      this.page = 1;
      this.getData();
    },
    next() {
      this.current++;
      this.page = 1;
      this.black_chain = this.tabs[this.current].name;
    },
    onHandlePage(page) {
      this.page = page;
      this.getData();
    },
    seeMore() {
      this.page++;
      this.getData();
    },
    changeTab(key) {
      this.black_chain = key;
      this.size = 10;
      this.page = 1;
      this.getData();
    },

    changeTag(key) {
      this.tag = key;
      this.currentTag = key;
      this.size = 10;
      this.page = 1;
      this.getData();
    },

    getData() {
      let { size, black_chain, search, page, tag, name } = this;
      this.load = true;
      tag = tag == "all" ? "" : tag;
      black_chain = black_chain == "all" ? "" : black_chain;
      let sortList = JSON.stringify(search);
      this.$http.get(
        "/api/v1/meta/game/list",
        {
          tag,
          name,
          black_chain,
          limit: size,
          offset: (page - 1) * size,
          days: this.days,
          order_list: sortList,
        },
        async ({ result }) => {
          this.total = result.count;
          //要重置 page 的 pageCount
          let data = result.records.map((m, i) => {
            let { new_user, total_user } = m.chain_users;
            m.new_users = this.utils.changeUnit(new_user);
            m.total_users_v0 = this.utils.changeUnit(total_user);
            m.no = i + (page - 1) * size + 1;
            return m;
          });
          if (page == 1) {
            this.data = [];
          }
          if (this.h5 && page != 1) {
            data = [].concat(this.data, data);
          }
          let query_names = data.map((m) => m.game_name) || [];
          if (query_names.length) {
            let tokens = await this.$http.post("/api/v1/meta/game/list/token", {
              query_names,
            });
            let kols = await this.$http.post("/api/v1/meta/game/list/kol", {
              query_names,
            });
            this.engagements = Math.max.apply(
              Math,
              (kols.result || []).map((m) => {
                return m.total_engagements || 0;
              })
            );
            this.engagement = this.engagements ? this.engagements / 5 : 0;
            kols.result.map((m) => {
              let unit = this.engagement
                ? (m.total_engagements || 0) / this.engagement
                : 0;
              m.starCount = Math.ceil(unit);
              m.count = parseInt(unit);
              m.paddingRight = (1 - (unit % 1)) * 16;
            });
            this.data = data.map((m, n) => {
              let item = tokens.result[n] || [];
              let itemKol = kols.result[n] || {};
              m.token_price = item.length
                ? item.map((h) => (h.price ? parseFloat(h.price) : "--"))
                : ["--"];
              m.token_logo = item.length
                ? item.map((h) => (h.logo ? h.logo : ""))
                : [];
              m.token_holders = item.length
                ? item.map((h) =>
                    h.holders ? this.utils.changeUnit(h.holders) : "--"
                  )
                : ["--"];
              m.token_change_rate_24h = item.length
                ? item.map((h) =>
                    h.change_rate_24h ? h.change_rate_24h : null
                  )
                : [null];
              m.token_symbol = item.length
                ? item.map((h) => (h.symbol ? h.symbol : "--"))
                : ["--"];
              m = { ...m, ...itemKol };
              return m;
            });
          } else {
            this.data = data;
          }
          this.load = false;
          setTimeout(() => {
            this.$nextTick(() => {
              let ktable = document.getElementsByClassName("k-table-body")[0];
              if (ktable) ktable.style.overflow = "auto hidden";
              let table = document.getElementsByTagName("tbody")[0];
              this.height = table.offsetHeight + 5;
            });
          }, 300);
        },
        (err) => {
          this.load = false;
        }
      );
    },
    onSearch(e) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.name = e.target.value;
        this.page = 1;
        this.getData();
      }, 300);
    },
    customize(selectBtns) {
      this.columns = [
        {
          title: "#",
          key: "game_id",
          align: "right",
          fixed: "left",
          width: 60,
        },
        {
          title: this.$t("games.col_name"),
          key: "name",
          fixed: "left",
          width: 320,
        },
      ];
      this.$nextTick(() => {
        let showLine = selectBtns.filter((h) => "active_user_7d" == h.key);
        let showBar = selectBtns.filter((h) => "volume_7d" == h.key);
        let showTag = selectBtns.filter((h) => "tags" == h.key);
        this.showLine = showLine && showLine.length ? true : false;
        this.showBar = showBar && showBar.length ? true : false;
        this.showTag = showTag && showTag.length ? true : false;
        if (!this.showTag) {
          this.tag = "all";
        }
        let width = 0;
        selectBtns.map((m) => {
          let column = this.baseColumns.filter((h) => m.key == h.key)[0];
          this.columns.push(column);
          width += column.width || 150;
        });
        this.width = width < 1320 ? 1320 : 1700;
        this.getData();
      });
    },
  },
  render() {
    let {
      data,
      load,
      columns,
      showViewall,
      h5,
      total,
      page,
      size,
      pageRanges,
      dayChange,
      width,
      showSearch,
      showPage,
      showDate,
      customize,
      hide,
      showLine,
      showBar,
      height,
      init,
      black_chain,
      tag,
    } = this;
    return (
      <div class="game-box">
        <div class="tabs flex-base">
          {this.$slots.tab}
          <div class="select-chain flex-base">
            {showSearch ? (
              <div class="search-box" slot="search">
                <Input
                  type="search"
                  shape="circle"
                  theme="light"
                  onKeyup={(e) => this.onSearch(e)}
                  placeholder={this.$t("menu.search")}
                >
                  <Icon type={Search} slot="suffix" />
                </Input>
              </div>
            ) : null}
            <HomeGamesChain
              onChange={this.changeTab}
              black_chain={black_chain}
            />
            <HomeGamesTags onChange={this.changeTag} tag={tag} />
          </div>
          {showDate && <DateFilter onDayChange={dayChange} />}
        </div>
        <HomeGamesPc
          shows={this.show}
          loading={load}
          data={data}
          columns={columns}
          onFilter={this.filterChange}
          change={this.hasChange}
          width={width}
          height={height}
          ref="table"
          showLine={showLine}
          showBar={showBar}
          showPage={showPage}
          showViewall={false}
        />

        <a href="/rank/games">
          <div class="more">{this.$t("com.view_all")}</div>
        </a>
        {total > 30 && h5 && showPage ? (
          data.length < total ? (
            <div class="load-more flex-base" onClick={this.seeMore}>
              {this.$t("com.more")}
              <Icon type={ChevronDown} size="20" />
            </div>
          ) : (
            <div class="load-more flex-base">{this.$t("com.over")}</div>
          )
        ) : null}
      </div>
    );
  },
};
