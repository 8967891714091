
import '~/assets/less/ido.less'
import { mapGetters } from 'vuex'
import moment from 'moment';
import { ChevronDown } from "kui-icons";
export default {
  layout: "index",
  computed: {
    ...mapGetters(['h5',])
  },
  data() {
    return {
      gamefiData: {},
      showH5Date: false,
      dateType: 0,
      page: 1,
      size: 8,
      total: 0,
      show: false,
      date: [],
      start_time: '',
      end_time: '',
      idos: [],
      ido_type: 'ido',
      curIdo: 0,
      h5StarText: this.$t('ido.start_time'),
      h5EndText: this.$t('ido.end_time'),
      idoTypes: [
        { value: 'ido', name: 'IDO' },
        // { value: 'ieo', name: 'IEO' },
        { value: 'whitelist', name: this.$t('ido.whitelist'), },
      ],
      current: '0',
      status: [
        { show_name: this.$t('com.all'), name: 'all' },
        { show_name: this.$t('ido.preview'), name: '1' },
        { show_name: this.$t('ido.ongoing'), name: '2' },
        { show_name: this.$t('ido.finished'), name: '3' }
      ],

    };
  },
  asyncData({ $http, app }) {
    return $http.get('/api/v1/meta/ido/list', {
      limit: 8, offset: 0,
      status: '', start_time: '', end_time: '',
      ido_type: 'ido', utc: app.i18n.locale == 'en' ? '' : '8'
    }).then(({ result }) => {
      let idos = result.records.map(m => {
        // m.social = m.social.filter(h => h.title == 'telegram' || h.title == 'twitter')
        return m
      })
      return {
        idos,
        total: result.count
      }
    })
  },
  mounted() {
    this.getCount()
  },
  methods: {
    close() {
      document.body.style = ''
      this.showH5Date = false
    },
    setH5Date(v) {
      let str = moment(v).format('YYYY-MM-DD')
      if (this.dateType == 0) {
        this.h5StarText = str
        this.start_time = str
      } else {
        this.h5EndText = str
        this.end_time = str
      }
      this.showH5Date = false
      this.getIdo()
      this.close()
    },
    changeIdo(i) {
      if (i == this.curIdo) return
      this.date = []
      this.curIdo = i
      this.ido_type = this.idoTypes[i].value
      this.page = 1
      this.getIdo()
    },
    change(value) {
      this.end_time = value[1]
      this.start_time = value[0]
      this.getIdo()
    },
    statusChange(item) {
      this.current = item == 'all' ? '0' : item
      this.page = 1
      this.getIdo()
    },
    onHandlePage(page) {
      this.page = page
      this.getIdo()
    },
    seeMore() {
      this.page++
      this.getIdo('is_h5')
    },
    getCount() {
      this.$http.get('/api/v1/meta/game/total', {}, ({ result }) => {
        this.gamefiData = result
      })
    },
    getIdo(type) {
      let { end_time, start_time, page, size } = this
      this.$http.get('/api/v1/meta/ido/list', { limit: size, offset: (page - 1) * size, status: this.current, start_time, end_time, ido_type: this.ido_type, utc: this.$i18n.locale == 'en' ? '' : '8' }, ({ result }) => {
        this.total = result.count
        let idos = result.records.map(m => {
          // m.social = m.social.filter(h => h.title == 'telegram' || h.title == 'twitter')
          return m
        })
        if (type) {
          this.idos = this.idos.concat(idos)
        } else {
          this.idos = idos
        }
      })
    },
    showDate(dateType) {
      this.dateType = dateType;
      this.showH5Date = true
    }
  },
  render() {
    let { curIdo, h5, idos, idoTypes, status, current, date, showH5Date, h5StarText, h5EndText, total, page, size, gamefiData } = this
    return (
      <div class="ido ranking" >
        <div class="title">{this.$t('airdrop.title')}</div>
        <div class="sub-title">{this.$t('airdrop.sub_title1')}<span class="value"> 100+ </span>{this.$t('airdrop.sub_title2')}<span class="value"> {gamefiData.game_count} </span>{this.$t('airdrop.sub_title3')}</div>
        <div class="ido-head flex-base">
          <div class="left">
            {!h5 ? <HomeTabs tabs={idoTypes} onChangeTab={this.changeIdo} activeIndex={curIdo} />
              : <div class="h5-head">
                {idoTypes.map((item, i) => {
                  return (
                    <span onClick={() => this.changeIdo(i)} class={{ 'active': curIdo == i }} key={i}>{item.name}</span>
                  )
                })
                }</div>}
          </div>
          <div class="right flex-base">
            <HomeGamesTags onChange={this.statusChange} tagList={status} />
            {!h5 ? <DatePicker value={date} format="YYYY-MM-DD" mode="range" onChange={this.change} placeholder={[this.$t('ido.start_time'), this.$t('ido.end_time')]} />
              : <div class="filter-date">
                <Button onClick={() => this.showDate(0)}>{h5StarText}</Button>
                <span>-</span>
                <Button onClick={() => this.showDate(1)}>{h5EndText}</Button>
              </div>}
          </div>
        </div>
        {
          idos.length ? <Row type="flex" class="idos" gutter={22}>
            {
              idos.map((item, i) => {
                return (
                  <Col span={24} key={i}>
                    {h5 ? <HomeIdoItem data={item} /> : <HomeIdoNewitem data={item} />}
                  </Col>
                )
              })
            }
            {
              total > 8 && h5 ? (
                (idos.length < total ? <div class="load-more flex-base" onClick={this.seeMore} >{this.$t('com.more')}<Icon type={ChevronDown} size={20} /></div>
                  : <div class="load-more flex-base">{this.$t('com.over')}</div>)
              ) : null
            }
          </Row> :
            <Empty description={this.$t('com.empty')} />
        }

        {
          !h5 && total > 8 ? <div class="page-wrap">
            <Page total={total} current={page} pageSize={size} size="small" onChange={this.onHandlePage} ref="page"></Page>
          </div > : null
        }

        <Drawer value={showH5Date} closable={false} footer={null} title={null} height={420} placement="bottom" onClose={this.close} >
          <DateCalendar class="h5-calendar" onChange={this.setH5Date} />
        </Drawer >
      </div >
    )
  }
};
