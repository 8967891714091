
export default {
  layout: 'index',
  data() {
    return {
      titles: {
        change: this.$t('data.title'),
        deal: this.$t('games.title'),
      },
      sub_titles: {
        change: this.$t('data.sub_title'),
        deal: this.$t('games.sub_title'),
      },
      tabs: [
        { name: this.$t('whaleTabs.tab1'), value: 'change' },
        // { name: this.$t('whaleTabs.tab2'), value: 'deal' },
      ],
      current: 0,
      types: 'change|deal',
      id: '',
      count:{}
    }
  },
  created() {
    this.init()
  },
  mounted(){
    this.getCount()
  },
  methods: {
    init() {
      let id = this.$route.params.id
      if (!/change|deal/.test(id)) id = "change"
      this.current = this.types.split('|').findIndex(x => x == id)
      // console.log(this.current, id)
      this.id = id
      return id
    },
    changeTab(i) {
      this.current = i
      let t = this.types.split('|')[i]
      this.$router.push(`/whale/${t}`)
    },
    getCount() {
      this.$http.get('/v2/game/whale/track_count', {  noEncrypt: true }, ( result ) => {
        this.count = result
      })
    },

  },
  render() {
    let { tabs, current, id,count } = this
    id = id || this.init()
    return (<div class='page-games ranking'>
      <div class="title">{this.$t('whale.title')}</div>
      <div class="sub-title">
        {this.$t('whale.sub_title1')}<span class="value">{count.game_count}</span>{this.$t('whale.sub_title2')}<span class="value">{count.whale_count}</span>{this.$t('whale.sub_title3')}
      </div>
      <HomeTabs tabs={tabs} onChangeTab={this.changeTab} activeIndex={current} />
      {id == 'change' ? <HomeWhalechange limit={10} showSearch={false} showPage={true} /> : null}
      {id == 'deal' ? <HomeWhaledeal limit={10} showSearch={false} showPage={true} /> : null}
    </div>)
  }

}
