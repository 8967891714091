
import '../index.less'
import { mapGetters } from 'vuex'
import { ChevronDown, Search } from 'kui-icons'
export default {
  props: {
    oDatas: [Array],
    totals: Number,
  },
  computed: {
    ...mapGetters(['h5','lang'])
  },
  data() {
    return {
      page: 1,
      total: this.totals || 0,
      current: 0,
      size: 30,
      loading: true,
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
      sortKey: ['price', 'price_total', 'social_score'],
      guildData: this.oDatas || [],
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('trend.investor_time'), key: 'date', className: 'chain' },
        { title: this.$t('trend.investor_game'), key: 'name', className: 'text-left' },
        { title: this.$t('trend.investor_raised'), key: 'money', className: 'chain',render: (h, p) => h('span', this.utils.moneyUnit(p.money, this.lang) || '--'),  },
        { title: this.$t('trend.investor_round'), key: 'rounds', className: 'chain',render: (h, p) => h('span', this.utils.getRounds(p.rounds, this.lang) || '--') },
        { title: this.$t('trend.investor_investor'), key: 'investor_name' },
        { title: this.$t('trend.investor_link'), key: 'social' },
      ],
      guildSearch: [],
      timer: null,
      query_name: ''
    };
  },

  mounted() {
    this.getInvestor()
  },
  methods: {
    setInvestor(item) {
      this.guildData = this.guildData = this.guildData.map(m => {
        if (m.project_name == item.project_name) {
          m.show_investor_list = item.expand ? this.utils.deepClone(m.investor_list) : this.utils.deepClone(m.hide_investor_list)
        }
        return m
      })
    },
    onHandlePageSize({ value }) {
      this.page = 1
      this.size = value;
      this.getInvestor()
    },
    onHandlePage(page) {
      this.page = page;
      this.getInvestor()
    },
    seeMore() {
      this.page++
      this.getInvestor()
    },
    getInvestor() {
      let { guildSearch, size, query_name } = this
      let order_list = JSON.stringify(guildSearch)
      this.loading = true
      this.$http.get('/api/v1/meta/investor/investment/newest', { limit: size, offset: (this.page - 1) * size, order_list, query_name }, ({ result }) => {
        this.total = result.count
        this.loading = false
        let guildData = result.records.map((m, i) => {
          if (m.investor_list.length) {
            m.money = m.investor_list[0].money || '--'
            m.rounds = m.investor_list[0].rounds || '--'
            m.hide_investor_list = m.investor_list.slice(0, 2)
            m.show_investor_list = m.hide_investor_list
            m.expand = false
          }
          m.no = i + ((this.page - 1) * size) + 1
          m = { ...m, ...m.investment_info }
          return m
        })
        if (this.h5) {
          this.guildData = this.guildData.concat(guildData)
        } else {
          this.guildData = guildData
        }
      })
    },
    onSearch(e) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.query_name = e.target.value
        this.page = 1
        this.getInvestor()
      }, 300);
    }
  },
  render() {
    let { h5, guildData, columns, size, page, total, pageRanges, setInvestor, loading } = this
    if (h5 && !guildData.length) {
      guildData = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
    }
    return (
      <div class="trend-box">
        {!h5 ? <Input type="search" class="search-input" shape="circle" theme="light" onKeyup={(e) => this.onSearch(e)} placeholder={this.$t('menu.search')}>
          <Icon type={Search} slot="suffix" />
        </Input> : null}
        {
          h5 ?
            <HomeTrendItem data={guildData} type='vc-investments' loading={loading && page == 1} /> :
            <HomeTrendInvestorPc loading={loading} data={guildData} columns={columns} onSetInvestor={setInvestor} />
        }
        {
          (total > 30 && h5) ? (
            guildData.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
          ) : null
        }

        {!h5 &&
          <div class="page-wrap">
            <Page total={total} current={page} pageSize={size} onChange={this.onHandlePage} />
            <div class="page-sizer flex-base">
              <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
              <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
            </div>
          </div>}
      </div>
    )
  }
};
