
import '~/assets/less/news.less'
import { mapGetters } from 'vuex'
import { ChevronDown } from "kui-icons";
export default {
  layout: 'index',
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  data() {
    return {
      newList: [],
      size: 10,
      page: 1,
      total: 0,
      pageType: 'research',
      pageTabs: [
        { name: this.$t('menu.gamefi'), value: 'research' },
        { name: this.$t('menu.news'), value: 'news' },
      ],
      currentIndex: '',
    };
  },
  asyncData({ $http }) {
    return $http.get('/api/v1/news/list', { limit: 10, offset: 0, type: 'strategy' }).then(({ result }) => {
      return {
        newList: result.records,
        total: result.count,
      }
    })
  },
  render() {
    let { utils,newList, total, lang, goDetail, pageTabs, changePageTab, currentIndex } = this
    return (
      <div class="news ranking">
        <HomeTabs tabs={pageTabs} onChangeTab={changePageTab} activeIndex={currentIndex} />
        <div class="news-wrap">
          <div class="news-content-wrap">
            {
              newList.map((item, i) => {
                return (
                  <div class="news-item" key={i} onClick={() => goDetail(item)}>
                    <div class="news-cover" style={{ 'background-image': 'url(' + item.cover + ')' }}>
                    </div>
                    <div class="right ">
                      <div class="news-title">
                        {lang == 'en' ? (item.title_en || item.title) : item.title}
                      </div>
                      <div class="news-content">
                        {lang == 'en' ? (item.sub_title_en || item.sub_title) : item.sub_title}
                      </div>
                      <div class="news-time research-time">
                        {utils.T(item.create_at, lang)}
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {
              (newList.length < total) ? <div class="load-more flex-base" onClick={this.seeMore}>
                {this.$t('com.view_more')}
                <Icon type={ChevronDown} size="20" />
              </div> : null
            }
          </div>
        </div>
      </div>
    )
  },
  methods: {
    changePageTab(i) {
      if (this.currentIndex == i) return
      this.currentIndex = i
      this.$router.push('/' + this.pageTabs[i].value)
    },
    seeMore() {
      this.page++
      this.getNews()
    },
    getNews() {
      this.$http.get('/api/v1/news/list', { limit: 10, type: 'strategy', offset: (this.page - 1) * 10 }).then(({ result }) => {
        this.newList = this.newList.concat(result.records)
      })
    },
    goDetail(item) {
      if (item.source_open) {
        window.open(this.lang == 'en' ? (item.origin || item.source_web) : item.source_web)
      } else {
        window.open(`/news/${item.news_id}`)
      }
    }
  },
};
