
import './index.less'
import { mapGetters } from 'vuex'

export default {
  layout: "index",
  computed: {
    ...mapGetters(['h5', 'lang', 'gamefiData', 'trendIndex'])
  },
  data() {
    return {
      page: 1,
      size: 8,
      total: 0,
      data: [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}],
      loading: true,
      tabs: [
        // { name: this.$t('trend.hot'), value: 'kols-discussing' },
        { name: this.$t('trend.follower'), value: 'kols-following' },
        { name: this.$t('trend.investor'), value: 'vc-investments' },
      ],
      current: '',
      type: 'kols-discussing',
      apis: {
        'kols-following': '/api/v1/meta/kol/games/followers/rank',
        // 'kols-discussing': '/api/v1/meta/kol/games/hot/rank',
        'vc-investments': '/api/v1/meta/investor/investment/newest',
      },
    };
  },
  mounted() {
    this.current = this.trendIndex || 0
    this.getData(this.current)
  },
  methods: {
    getData(i) {
      let url = this.apis[this.tabs[i].value]
      this.loading = true
      this.type = this.tabs[i].value
      this.$http.get(url, { limit: 10, offset: 0 }, ({ result }) => {
        if (i != this.current) return
        this.data = result.records
        this.loading = false
      }, err => {
        this.loading = false
      })
    },
    changeTab(i) {
      if (this.current == i) return
      this.current = i
      this.utils.setCookie('trendIndex', i)
      this.getData(i)
    },
    onHandlePage(page) {
      this.page = page
      this.getData()
    },
    seeMore() {
      this.page++
      this.getData('is_h5')
    },
  },
  render() {
    let { data, h5, total, page, size, tabs, changeTab, current, type, gamefiData, loading } = this
    return (
      <div class="hot-game home-item">
        <div class="page-center">
          <div class="title">
            {this.$t('trend.title')}
          </div>
          <div class="sub-title">
            {this.$t('trend.sub_title1')}<span class="value">{gamefiData.kol_tweet_count}</span> {this.$t('trend.sub_title2')}<span class="value"> {gamefiData.sum} </span>{this.$t('trend.sub_title3')}
          </div>
          <HomeTabs tabs={tabs} onChangeTab={changeTab} activeIndex={current} />
          <HomeTrendItem data={data} type={type} loading={loading} />
          <a href={'trend/' + type} ><div class="more">{this.$t('com.view_all')}</div></a>
        </div>
      </div>
    )
  }
};
