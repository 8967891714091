import { render, staticRenderFns } from "./overview.vue?vue&type=template&id=64fb5f70&"
import script from "./overview.vue?vue&type=script&lang=js&"
export * from "./overview.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GamesScreenshot: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/screenshot.vue').default,Title: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/title/index.vue').default,Tags: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/tags/index.vue').default,HomeGamesTag: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/home/games/tag.vue').default,Description: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/description.vue').default,SubjectsViewitem: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/viewitem.vue').default,GamesViewpoint: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/viewpoint.vue').default})
