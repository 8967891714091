
import '../trend/index.less';
import './index.less'
import { mapGetters } from 'vuex'
import { ChevronDown } from 'kui-icons'

export default {
  layout: "index",
  computed: {
    ...mapGetters(['h5', 'lang', 'newgameIndex'])
  },
  props: {
    showViewall: Boolean,
    showPage: Boolean,
    limit: Number,
  },
  data() {
    return {
      ChevronDown,
      page: 1,
      size: this.limit || 8,
      total: 0,
      data: [],
      // data: this.showPage ? [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},] : [{}, {}, {}, {}, {}, {}, {}, {},],
      loading: (this.showPage || !(this.h5 && !this.showPage)) ? true : false,
      tabs: [
        { name: this.$t('newgame.recommend'), value: 'recommend' },
        { name: this.$t('newgame.all'), value: 'all' },
      ],
      current: 0,
      type: 'recommend',
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
    };
  },
  mounted() {
    this.current = localStorage.getItem('newgameIndex') || 0
    this.getData(this.current)
  },
  methods: {
    getData(i) {
      if (this.showPage || !(this.h5 && !this.showPage)) {
        this.loading = true
      }
      this.type = this.tabs[i].value
      this.$http.get('/api/v1/meta/game/newGames/list', {
        limit: this.size, offset: (this.page - 1) * this.size, g_type
          : this.type
      }, ({ result }) => {
        result.records.map(m => {
          if (m.screenshot && m.screenshot.length) {
            m.logo = m.screenshot[0]
          } else if (m.video) {
            let url = m.video.split('=')[1] || m.video.split('/').slice(-1)[0] || ''
            m.logo = 'https://pbs.mymetadata.io/vi/' + url + '/0.jpg'
          }
          let num = result.records.length % 4;
          if (num !== 0 && !this.h5) {
            for (let i = 0; i < (4 - num); i++) {
              result.records.push({ visibility: true })
            }
          }
        })
        this.total = result.count
        // this.data = result.records
        if (this.page == 1) this.data = []
        if (this.h5) {
          this.data = this.data.concat(result.records)
        } else {
          this.data = result.records
        }
        this.$nextTick(() => {
          this.loading = false
        })
      }, err => {
        this.loading = false
      })
    },
    changeTab(i) {
      if (this.current == i) return
      this.current = i
      this.page = 1
      localStorage.setItem('newgameIndex', i)
      this.getData(i)
    },
    onHandlePage(page) {
      this.page = page
      this.getData(this.current)
    },
    seeMore() {
      this.page++
      this.getData(this.current)
    },
  },
  render() {
    let { data, tabs, changeTab, current, type, loading, showPage, ChevronDown, h5, pageRanges, size, total, page } = this
    return (
      <div class="home-item newgame">
        <div class="title">
          {this.$t('newgame.title')}
        </div>
        <div class="page-center">
          <HomeTabs tabs={tabs} onChangeTab={changeTab} activeIndex={current} />
          <HomeNewgameContainer data={data} type={type} loading={loading} showPage={showPage} />

          {(!h5 && showPage && data.length) ?
            <div class="page-wrap">
              <Page total={total} current={page} pageSize={size} onChange={this.onHandlePage} />
            </div> : ''}

          {
            (total > 12 && h5 && showPage ?
              data.length < total ?
                <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
                <div class="load-more flex-base">{this.$t('com.over')}</div>
              : null)
          }
          {!showPage ? <a href='/rank/newgame'  ><div class="more">{this.$t('com.view_all')}</div></a> : ''}
        </div>
      </div>
    )
  }
};
