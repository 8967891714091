//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default ({
  layout: 'index',
  data() {
    return {
      type: 'up',
      levs: {
        1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10',
      },
      lev: 5,
      pks: {
        1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8', 9: '9', 10: '10', 11: '11', 12: '12'
      },
      pk: 9,
      wins: {
        10: '10%', 30: '30%', 50: '50%', 80: '80%', 90: '90%', 100: '100%',
      },
      pk_lev: 3,
      pk_levs: {
        1: 'I', 2: 'II', 3: 'III', 4: 'IV', 5: 'V', 6: 'VI'
      },
      win: 50,
      buy: 250,
      lev_gem: [0, 7, 11, 32, 46, 82, 130, 137, 247, 445, 801],
      lev_wlkn: [0, 1, 3, 20, 52, 123, 200, 210, 415, 780, 1250],
      lev_win: [0, 0.1, 0.19, 0.45, 1.03, 2.02, 5.14],
      wlkn: 0.063708
    }
  },
  computed: {
    win_pk() {
      let v = this.pk_lev
      return this.pk * this.win * this.lev_win[v] * 0.01
    },
    need_gems() {
      let v = this.lev
      return this.lev_gem.slice(0, v + 1).reduce((a, b) => a + b, 0)
    },
    need_wlkn() {
      let v = this.lev
      return this.lev_wlkn.slice(0, v + 1).reduce((a, b) => a + b, 0)
    }
  }
})
