
import { mapGetters } from 'vuex'
import { ChevronDoubleForward } from 'kui-icons'
export default {
  computed: {
    ...mapGetters(['h5'])
  },
  props: {
    data: Array,
    types: Object
  },
  data() {
    return {

    }
  },
  methods: {
    showDesc(item) {
      this.$emit('show', item)
    }
  },
  render() {
    let { data = [], types, utils, h5 } = this
    return (
      <div>
        {data.length ?
          data.map((item, i) => {
            const html = (item.content || '').replace(/<\/?.+?>/g, "");
            return (
              <div class="activity-content flex-base" key={i}>
                <div class="content-left">
                  {(item.start_date && item.st_today) && <span>{utils.formatTime(item.start_date)} {this.$t('event.start')}</span>}
                  {(item.et_today && item.end_date) && <span class="finish">{utils.formatTime(item.end_date)} {this.$t('event.end')}</span>}
                </div>
                <div class="content-mid" onClick={() => this.showDesc(item, i)}>
                  <Icon class="icon-more" type={ChevronDoubleForward} size={16} />
                  <div class="content-title">{utils.subString(item.title, h5 ? 40 : 46)}</div>
                  <div class="content-desc" domPropsInnerHTML={html}></div>
                </div>
                <div class="content-right">
                  <div class={'content-btn tag-' + (types[item.type] ? item.type : 'other')}>
                    {types[item.type] || types['other']}
                  </div>
                </div >
              </div >
            )
          })
          :
          <Empty description={this.$t('com.empty')} />}
      </div>

    )
  }
}
