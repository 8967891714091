//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import './header.less';
import { mapGetters, mapMutations } from 'vuex';
import { ChevronDown } from "kui-icons";
export default {
  head({ $store, $router }) {
    // if ($store.state.notfound) {
    //   return $router.replace(`/notfound`)
    // }
    let theme = $store.state.theme

    let color = theme == 'dark' ? '#1b1b1b' : '#fff'
    return {
      meta: [
        { name: "msapplication-TileColor", content: color },
        { name: "theme-color", content: color },
      ],
      bodyAttrs: {
        'theme-mode': theme
      },
    }
  },
  data() {
    return {
      ChevronDown,
      searchData: {
        games_list: [],
        guilds_list: [],
        tokens_list: [],
        vc_list: [],
      },
      open: false,
      langList: [
        { label: '中文', icon: 'zh', value: 0 },
        { label: 'EN', icon: 'en', value: 1 },
      ],
      rankLink: [
        { name: this.$t('data.title'), link: '/rank/dashboard', icon: 'dashboard', key: '_rank_dashboard' },
        { name: this.$t('games.title'), link: '/rank/games', icon: 'rank', key: '_rank_games' },
        { name: this.$t('tokens.title'), link: '/rank/token', icon: 'tokens', key: '_rank_token' },
        { name: this.$t('guild.title'), link: '/rank/guilds', icon: 'flag', key: '_rank_guilds' },
        { name: this.$t('studio.title'), link: '/rank/studios', icon: 'studio', key: '_rank_studios' },
        { name: this.$t('investor.title'), link: '/rank/investors', icon: 'investor', key: '_rank_investors' },
        { name: this.$t('newgame.title'), link: '/rank/newgame', icon: 'newgame', key: '_rank_newgame' },
        // { name: 'NFT', link: '/nft', icon: 'hot', key: '_rank_nft' },
      ],
      trendLink: [
        { name: this.$t('trend.hot'), link: '/trend/kols-discussing', icon: 'hot_kols', key: '_trend_kols_discussing' },
        { name: this.$t('trend.follower'), link: '/trend/kols-following', icon: 'hot_followers', key: '_trend_kols_following' },
        { name: this.$t('trend.investor'), link: '/trend/vc-investments', icon: 'hot_investors', key: '_trend_vc_investments' },
      ],
      timer: null,
      name: '',
      gamelist: [],
      showSearch: false,
      timer: null,
      menuShow: false,
      activeKeys: [].concat(this.$store.state.menuActiveKey),
      isDark: false
    };
  },
  computed: {
    ...mapGetters(['h5', 'menuActiveKey', 'lang', 'theme'])
  },
  mounted() {
    window.addEventListener('resize', e => {
      clearTimeout(this.renderTimer)
      this.renderTimer = setTimeout(() => {
        this.rereder()
      }, 300);
    })
    this.isDark = this.theme == 'dark';
  },
  methods: {
    ...mapMutations(['setPlatform', 'setLang', 'setTheme', 'setMenuActiveKey']),
    setThemes() {
      const body = document.body;
      let theme = body.getAttribute('theme-mode') || ''
      theme = theme == 'dark' ? 'light' : 'dark';

      body.setAttribute('theme-mode', theme);

      this.utils.setCookie('theme', theme)
      this.setTheme(theme)
      //刷新echart的border颜色
      if (this.menuActiveKey[0].indexOf('_games_') > -1) {
        location.reload()
      }
    },
    setLangs(key) {
      this.setLang(key)
      this.$i18n.locale = key
      this.utils.setCookie('lang', key, 365)
      location.reload()
    },
    showMenu() {
      let menu = this.$refs.menu
      // menu.style.display = this.menuShow ? 'none' : 'block'
      this.menuShow = !this.menuShow

    },
    rereder() {
      let width = document.body.clientWidth
      let h5 = width <= 600
      if (h5 != this.h5) {
        this.setPlatform(h5)
      }
      if (!h5) {
        this.$refs.menu.style = ''
      }
    },
    goUrl(item) {
      if (item.link == '/rank') return
      window.location.href = item.link
    },
    onkeyup(e) {
      let name = e.target.value.trim()
      if (name) {
        this.search(name)
      }
    },
    blur() {
      setTimeout(() => {
        this.showSearch = false
      }, 300);
      // this.showMenu = false
      this.open = false
    },
    focus() {
      if (this.name && this.searchData.games_list.length || this.searchData.guilds_list.length || this.searchData.tokens_list.length || this.searchData.vc_list.length) {
        this.showSearch = true
      }
      if (this.h5) {
        this.open = true
        this.menuShow && this.showMenu()
      }
    },
    search(name) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.getData(name)
      }, 300);
    },
    getData(query_name) {
      this.$http.get('/api/v1/meta/home/search', { query_name }, ({ result }) => {
        this.searchData = result
        this.loading = false
        this.showSearch = true
      }, err => {
        this.showSearch = true
        this.loading = false
      })
    },
  },
};
