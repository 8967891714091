//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      list: [],
      size: this.type? 99: 20,
      page: 1,
      loading: true,
      query_name: '',
      loadmore: false,
      showFinish: false,
      flag: false,
      days: 7,
      sort_by: '',
      tagList: [
        { name: 'all', show_name: this.$t('view.default'), key: "0" },
        { name: 'fc', show_name: this.$t('view.like') },
        { name: 'rc', show_name: this.$t('view.reply') },
      ]
    };
  },
  props: {
    type: String,
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  mounted() {
    this.query_name = this.$route.params.id
    this.getData()
    window.addEventListener('scroll', this.scrollBottom)
  },
  methods: {
    change(item) {
      this.sort_by = item
      this.page = 1
      this.showFinish = false
      this.getData()
    },
    dayChange(item) {
      this.days = item.value
      this.page = 1
      this.showFinish = false
      this.getData()
    },
    scrollBottom(e) {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let windowHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight
      if ((scrollTop + windowHeight > scrollHeight - 1000) && !this.showFinish && this.flag && this.list.length) {
        this.loadmore = true
        this.page++
        this.getData()
      }
    },
    seeMore() {
      this.page++
      this.getData()
    },
    getData() {
      this.flag = false
      let { size, page, query_name, sort_by, days } = this
      this.$http.get('/api/v1/meta/game/dune/kolTweets', { limit: size, offset: (page - 1) * size, query_name, sort_by: sort_by == 'all' ? '' : sort_by, days }, ({ result }) => {
        result.records.map(m => {
          m.msg = this.utils.replaceText(m.msg)
          // if (m.media && m.media.length) {
          //   m.msg += `<img src="${m.media[0].replace('https://pbs.twimg.com', 'https://res.mymetadata.io/twimg')}" class="img" />`
          // }
        })

        if (result.records.length) {
          if (page == 1) {
            this.list = result.records
          } else {
            this.list = this.list.concat(result.records)
          }
        } else {
          if (this.page == 1) {
            this.list = []
            this.showFinish = false
          } else {
            this.showFinish = true
          }
        }
        setTimeout(() => {
          this.loading = false
          this.loadmore = false
          this.flag = true
        }, 300);
      })
    },

  }
};
