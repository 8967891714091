
export default {
  props: {
    value: [String, Number]
  },
  render() {
    let { value } = this
    if (value === null || value === undefined || value === '') {
      return (<span class="rate">--</span>)
    } else {
      value = parseFloat(value)
      // if (value > 200 || value < -200) {
      //   value = value.toString().slice(0, 2)
      //   value = parseFloat(value)
      //   value = value.toFixed(2)
      //   value = parseFloat(value)
      // }
      return <span class={'rate ' + (value >= 0 ? 'red' : 'green')}>{value > 0 ? '+' : ''}{value != 0 ? value : 0}%</span>
    }
  }
}
