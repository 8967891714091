//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { ChevronBackCircle, ChevronForwardCircle } from 'kui-icons'
import { Swiper, SwiperSlide, } from 'vue-awesome-swiper';
export default {
  props: {
    screenshots: { type: Array, default: () => [] }
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  components: { Swiper, SwiperSlide },
  data() {
    return {
      ChevronBackCircle,
      ChevronForwardCircle,

      swiperOptionTop: {
        loop: this.screenshots.length == 1 ? false : true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loopedSlides: 5,
      },
      h5swiperOptionTop: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOptionThumbs: {
        spaceBetween: 13,
        slidesPerView: 3,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        loop: this.screenshots.length == 1 ? false : true,
        loopedSlides: 4,
        // spaceBetween: 13,
        // // slidesPerView: 3,
        // centeredSlides: true,
        // slidesPerView: 'auto',
        // touchRatio: 0.2,
        // slideToClickedSlide: true,
        // loopAdditionalSlides: 100,
      },
    };
  },
  created() { },
  methods: {},
  mounted() {
    this.$nextTick(() => {
      const swiperTop = this.$refs.swiperTop.$el.swiper;
      const swiperThumbs = this.$refs.swiperThumbs.$el.swiper;
      swiperTop.controller.control = swiperThumbs;
      swiperThumbs.controller.control = swiperTop;
    });
  },
};
