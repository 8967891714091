//
//
//
//
//
//
//
//
//

import '~/assets/less/game.less'
export default {
}
