//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
    
import { mapGetters } from 'vuex'
import { ChevronForward, LogoAndroid, Tv, LogoApple } from 'kui-icons'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
export default {
  components: { Swiper, SwiperSlide },
  directives: {
    swiper: directive
  },
  data() {
    return {
      LogoAndroid,
      LogoApple,
      Tv,
      platforms: {
        android: LogoAndroid,
        ios: LogoApple,
        pc: Tv,
      },
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 10,
      },
      showView: false,
      game: '',
      ChevronForward,
      list: [],
      size: 20,
      page: 1,
      data: []
    }
  },
  computed: {
    ...mapGetters(['h5', 'lang', 'gameInfo']),
  },
  watch: {
    gameInfo(v) {
      if (v && v.tags && v.tags.length) {
        this.getGames()
      }
    },
  },
  mounted() {
    this.game = this.$route.params.id
    this.getData()
    if (this.gameInfo.game_name) {
      this.getGames()
    }
  },
  methods: {
    go(item) {
      this.$router.push(`/games/${item.game_name}?type=overview`)
    },
    seeView() {
      this.showView = true
    },
    seeInfo() {
      this.$refs.overviewDes.visible = true
    },
    getData() {
      let { size, page, game } = this
      this.$http.get('/api/v1/meta/game/dune/kolTweets', { limit: size, offset: (page - 1) * size, query_name: game, sort_by: '', days: 7 }, ({ result }) => {
        this.list = result.records.map(m => {
          m.msg = this.utils.replaceText(m.msg)
          return m
        })
      })
    },
    getGames() {
      this.data = []
      let tag = this.gameInfo.tags
      let random = Math.round(Math.random() * tag.length)
      this.$http.get('/api/v1/meta/game/list', { limit: 99, offset: 0, tag: tag[random] }, ({ result }) => {
        for (let i = 0; i < result.records.length; i += 3) {
          this.data.push(result.records.slice(i, i + 3));
        }
      })
    },

  },
};
