//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
export default {
  components: { Swiper, SwiperSlide },
  directives: {
    swiper: directive
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  props: {
    data: Array,
    loading: Boolean,
    showPage: Boolean
  },
  mounted() {
    // setTimeout(() => {
    //   document.getElementsByClassName('swiper-wrapper')[0].style.transform = 'translate3d(0px, 0px, 0px) !important'
    // }, 4000)
  },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        initialSlide: 0,
        // spaceBetween: 10,
      },
    }
  },
}
