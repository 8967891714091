import { render, staticRenderFns } from "./newitem.vue?vue&type=template&id=0670eda7&"
import script from "./newitem.vue?vue&type=script&lang=js&"
export * from "./newitem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WebIcon: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/WebIcon.vue').default,Social: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/social/index.vue').default,Tags: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/tags/index.vue').default,KolModal: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/kolModal/index.vue').default})
