//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/trends.less'
import '~/assets/less/game.less'
export default {
  layout: 'index',
  data() {
    return {
      trendsTag:{
        'whitelist':'Whitelist',
        'airdrop':'Airdrop',
        'giveaway':'Giveaway',
        'nft':'NFT Mint',
        'other':this.$t('trends.other'),
      },
      tabs: [
        { title: this.$t('trends.game'), key: 'trends' },
        { title: 'Whitelist', key: 'whitelist' },
        { title: 'Airdrop', key: 'airdrop' },
        { title: 'Giveaway', key: 'giveaway' },
        { title: 'NFT Mint', key: 'nft' },
        { title: this.$t('trends.other'), key: 'other' },
        // { title: this.$t('trends.IDO'), key: 'IDO' },
      ],
      tabActive: 'trends',
    };
  },
  mounted() {
  },
  methods: {
    change(key, i) {
      this.tabActive = key
      this.setScroll(i)
      this.tabIndex = i
      this.$emit('change', key)
      // this.$router.push('/trends/' + key)
    },
    ScrollTo(target, number = 0, time) {
      if (!time) {
        target.scrollTop = target.scrollLeft = number;
        return number;
      }
      const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
      let spacingInex = time / spacingTime; // 计算循环的次数
      let nowLeft = target.scrollLeft; // 获取当前滚动条位置
      let everLeft = (number - nowLeft) / spacingInex; // 计算每次滑动的距离
      let scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
          spacingInex--;
          this.ScrollTo(target, nowLeft += everLeft);
        } else {
          clearInterval(scrollTimer); // 清除计时器
        }
      }, spacingTime);
    },
    setScroll(i) {
      if (!this.$refs.tabList) return;
      let box = this.$refs.tabList.$el
      let item = box.children[i]//e.target
      if (!item) return;
      let w = box.offsetWidth, w2 = box.scrollWidth
      let { offsetLeft, offsetWidth } = item
      let half = offsetLeft + (offsetWidth / 2)
      if (half > w / 2) {
        // box.scrollLeft = half - w / 2
      } else {
        // box.scrollLeft = 0
      }
      this.ScrollTo(box, half > w / 2 ? half - w / 2 : 0, 300)
    },

  },
};
