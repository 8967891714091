//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AlertCircle } from 'kui-icons'
export default {
  props: {
    title: String, value: [String, Number],
    rate: [String, Number],
    desc: String,
    color: { type: String, default: '#39f' },
  },
  data() {
    return {
      AlertCircle
    }
  }
}
