//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './footer.less';
import { mapGetters } from 'vuex';
import { LogoTwitter, LogoDiscord, LogoTelegram } from 'kui-icons'
export default {
  computed: {
    ...mapGetters(['theme', 'lang', 'h5'])
  },
  data() {
    return {
      icons: {
        twitter: LogoTwitter,
        discord: LogoDiscord,
        telegram: LogoTelegram
      },
      socials: [
        {
          title: this.$t('footer.product'), sub_title: [
            { name: this.$t('footer.rank'), url: '/rank/games' },
            { name: this.$t('footer.hot'), url: '/trend/kols-discussing' },
            { name: this.$t('footer.airdrop'), url: '/airdrops' },
            {
              name: 'Discord Bot',
              blank: 1,
              url: `https://mymetadata.gitbook.io/my-metadata-bot-jie-shao${this.lang == 'en' ? '/my-metadata-bot-en' : ''}`
            }
          ]
        },
        {
          title: this.$t('footer.business'), sub_title: [
            { name: this.$t('footer.about'), url: '/about' },
            { name: this.$t('footer.contact'), url: '/about' },
            { name: this.$t('footer.included'), url: '/submit' }]
        },
        {
          title: this.$t('footer.social'), sub_title: [
            { name: 'Twitter', url: 'https://twitter.com/MyMetadata_io', blank: 1, },
            { name: 'Discord', url: 'https://discord.gg/7BGJ7YCBz7', blank: 1, },
            { name: 'Telegram', url: 'https://t.me/my_metadata', blank: 1, }]
        },
      ],
      h5socials: [
        { title: 'Telegram', name: 'telegram', url: 'https://t.me/my_metadata' },
        { title: 'Twitter', name: 'twitter', url: 'https://twitter.com/MyMetadata_io' },
        { title: 'Discord', name: 'discord', url: 'https://discord.gg/7BGJ7YCBz7' },
      ]
    }
  },
}
