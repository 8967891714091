//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import '~/assets/less/event-calendar.less'
import { mapGetters } from 'vuex'
import { ChevronBack, CalendarOutline, ChevronForward } from 'kui-icons'
export default {
  layout: 'index',
  computed: {
    ...mapGetters(['h5']),
    weeks() {
      let { date } = this
      const monday = moment(date).startOf('Week')//.format("YYYY-MM-DD");
      const sunday = moment(date).endOf('Week')//.format("YYYY-MM-DD")
      let days = [{ m: monday, d: monday.format('DD') }]
      let s = moment(monday)
      for (let i = 1; i < 7; i++) {
        days.push({ d: s.add(1, 'days').format('DD'), m: moment(s) })
      }
      days.push[{ m: sunday, s: sunday.format('DD') }]
      return days
    },
    dateStr() {
      return moment(this.date).format('YYYY-MM-DD')
    },
    current() {
      let { date } = this
      return moment(date).isoWeekday() == 7 ? 0 : moment(date).isoWeekday()
    }
  },
  data() {
    return {
      ChevronBack, CalendarOutline, ChevronForward,
      showH5Date: false,
      descShow: false,
      total: 0,
      minDate: new Date(2021, 0, 1),
      maxDate: new Date(2023, 12, 31),
      show: false,
      types: {
        all: this.$t('event.all'), whitelist: this.$t('event.white_list'), ido: 'IDO',
        ieo: 'IEO', airdrop: this.$t('event.airdrop'),
        ama: 'AMA', live: this.$t('event.live'),
        staking: this.$t('event.staking'), other: this.$t('event.others')
      },
      // current: 0,
      type: 'all',
      // weeks: [],
      weekCount: 0,
      start_st: '',
      end_st: '',
      activityList: [],
      date: new Date(),
      week: '',
      weekSort: [this.$t('event.day_sun'), this.$t('event.day_mon'), this.$t('event.day_tues'), this.$t('event.day_wed'), this.$t('event.day_thur'), this.$t('event.day_fri'), this.$t('event.day_sat'), this.$t('event.day_sun')],
      page: 1,
      size: 30,
      data: {},
      top: 0,
      left: 0,
    };
  },
  asyncData({ $http, lang }) {
    let date = new Date()
    let st = moment(date).format('YYYY-MM-DD 00:00:00')
    let et = moment(date).format('YYYY-MM-DD 23:59:59')
    // if (this.activityList && this.activityList.length) return;
    return $http.get('/api/v1/meta/activity/list', {
      limit: 30,
      offset: 0,
      st, et,
      type: '',
      utc: lang == 'en' ? '' : '8'
    }).then(({ result }) => {
      let activityList = result.records.map((m, n) => {
        m.et_today = moment(m.end_date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')
        m.st_today = moment(m.start_date).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD')
        m.open = false
        return m
      })
      return {
        activityList,
        total: result.count
      }
    })
  },
  methods: {
    close() {
      document.body.style = ''
    },
    setH5Date(value) {
      this.date = new Date(value)
      this.showH5Date = false
      this.close()
      this.getActivity()
    },
    today() {
      this.date = new Date()
      this.getActivity()
    },
    showDesc(item, i) {
      this.data = item
      this.descShow = true
    },
    changeTime(time) {
      this.date = new Date(time)
      this.page = 1
      this.getActivity()
    },
    changeDate(item, i) {
      if (this.current == i) return
      this.date = item.m.format('YYYY-MM-DD')
      this.page = 1
      this.getActivity()
    },
    changeTab(value, key) {
      if (this.type == key) return
      this.type = key
      this.page = 1
      this.getActivity()
    },
    getActivity(more) {
      let { page, size } = this
      let st = moment(this.date).format('YYYY-MM-DD 00:00:00')
      let et = moment(this.date).format('YYYY-MM-DD 23:59:59')
      this.$http.get('/api/v1/meta/activity/list', { limit: size, offset: (page - 1) * size, st, et, type: this.type == 'all' ? '' : this.type, utc: this.$i18n.locale == 'en' ? '' : '8' }, ({ result }) => {
        this.total = result.count
        let activityList = result.records.map((m, n) => {
          m.et_today = moment(m.end_date).format('YYYY-MM-DD') == moment(this.date).format('YYYY-MM-DD')
          m.st_today = moment(m.start_date).format('YYYY-MM-DD') == moment(this.date).format('YYYY-MM-DD')
          m.open = false
          return m
        })
        if (more) {
          this.activityList = this.activityList.concat(activityList)
        } else {
          this.activityList = activityList
        }
      })
    },
    onHandlePage(page) {
      this.page = page
      this.getActivity()
    },
    seeMore() {
      this.page++
      this.getActivity()
    },
    getWeeks(next) {
      let { date } = this
      date = new Date(date)
      this.date = date.setDate(date.getDate() + (next ? 7 : -7))
      this.getActivity()
    },
  },
};
