
import './index.less'
import { mapGetters } from 'vuex'

export default {
  layout: "index",
  computed: {
    ...mapGetters(['h5', 'lang', 'gamefiData'])
  },
  data() {
    return {
      active_user: [
        { title: '#', key: 'rank', width: 70 },
        { title: this.$t('home.col_name'), key: 'name' },
        { title: this.$t('alpha.alpha_new_active_user'), key: 'increased_num', width: 120 },
      ],
      holder_token: [
        { title: '#', key: 'rank', width: 70 },
        { title: this.$t('home.col_name'), key: 'name' },
        { title: this.$t('alpha.alpha_new_holder_token'), key: 'increased_num', width: 150 },
      ],
      increase: [
        { title: '#', key: 'rank', width: 70 },
        { title: this.$t('home.col_name'), key: 'name' },
        { title: this.$t('home.col_price_24'), key: 'increased_num', className: 'chain', width: 140 },
      ],
      apis: {
        active_user: '/api/v1/meta/game/rank/users/increased',
        holder_token: '/api/v1/meta/game/rank/holders/increased',
        increase: '/api/v1/meta/game/rank/token/increased',
        decline: '/api/v1/meta/game/rank/token/increased'
      },
      userData: [{}, {}, {}, {}, {}],
      tokenData: [{}, {}, {}, {}, {}],
      increseData: [{}, {}, {}, {}, {}],
      increseType: 'increase',
      loading_active_user: true,
      loading_holder_token: true,
      loading_increase: true,
      tabs: [
        { name: this.$t('alpha.alpha_active_user'), value: 'active_user' },
        { name: this.$t('alpha.alpha_holder_token'), value: 'holder_token' },
        { name: this.$t('alpha.alpha_increase'), value: 'increase' },
        { name: this.$t('alpha.alpha_decline'), value: 'decline' },
      ],
      current: '',
      type: 'active_user',
    };
  },
  mounted() {
    this.getData('active_user')
    if (!this.h5) {
      this.getData('holder_token')
      this.getData('increase')
    }
  },
  methods: {
    changeType(type) {
      this.increseType = type
      this.getData('increase')
    },
    getData(type) {
      let url = this.apis[type]
      let sort = this.increseType == 'decline' ? 'asc' : ''
      this['loading_' + (this.h5 ? 'active_user' : type)] = true
      this.$http.get(url, { limit: 5, offset: 0, sort }, ({ result }) => {
        if (this.h5) {
          this.loading_active_user = false
          this.userData = result
        } else {
          this['loading_' + type] = false
          if (type == 'active_user') {
            this.userData = result
          } else if (type == 'holder_token') {
            this.tokenData = result
          } else {
            this.increseData = result
          }
        }
      })
    },
    changeTab(i) {
      if (this.current == i) return
      this.current = i
      this.increseType = this.tabs[i].value
      this.type = this.tabs[i].value

      this.getData(this.tabs[i].value)
    },
  },
  render() {
    let { h5, active_user, holder_token, loading_active_user, loading_holder_token,
      loading_increase, increase, userData, tokenData, increseData, changeType, increseType, gamefiData, tabs, changeTab, current, type } = this
    return (
      <div class="alpha-games home-item">
        <div class="page-center">
          <div class="title">
            {this.$t('alpha.title')}
          </div>
          <div class="sub-title">
            {this.$t('alpha.sub_title1')} <span class="value">{gamefiData.game_count} </span>{this.$t('alpha.sub_title2')}
          </div>
          {h5 ? <HomeTabs tabs={tabs} onChangeTab={changeTab} activeIndex={current} /> : null}
          <div class="alpha-tables">
            <HomeAlphaTable loading={loading_active_user} data={userData} columns={active_user} type={type} />
            <HomeAlphaTable loading={loading_holder_token} data={tokenData} columns={holder_token} type="holder_token" />
            <HomeAlphaTable loading={loading_increase} data={increseData} columns={increase} type={increseType} onChangeType={changeType} needRank={true} />
          </div>
          <a href="/rank/games"><div class="more">{this.$t('com.view_all')}</div></a>
        </div>
      </div>
    )
  }
};
