//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  props: {
    line: { type: Number, default: 2 },
    info: Object,
    type: String,
    showTags: Boolean
  },
  data() {
    return {
      data:{},
      visible: false,
      descHeight: 0
    }
  },
  watch: {
    info: {
      handler(v) {
        let data = this.utils.deepClone(v)
        //中文  英文 详情字段处理
        if (this.type == 'game') {
          data.description_en = (data.description ? data.description : data.description_cn) || data.description_en
          data.description = (data.description_cn ? data.description_cn : data.description) || data.description
        } else if (this.type == 'guild') {
          data.name_en = data.name_en ? data.name_en : data.name
          data.description_en = data.description_en ? data.description_en : data.description
        }
        data.content = this.utils.replaceTag(data.description || data.desc || data.desc_en)
        data.content_en = this.utils.replaceTag(data.description_en || data.desc_en || data.desc)
        this.data = data
        this.$nextTick(() => {
          let height = this.$refs.descHeight
          this.descHeight = height.clientHeight
        })
      },
      immediate: true
    }
  },
}
