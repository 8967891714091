
import './index.less'
import { mapGetters } from 'vuex'
import { ChevronDown } from 'kui-icons'
export default {
  props: {
    limit: { type: Number, default: 10 },
    showViewall: Boolean,
    showPage: Boolean,
    datas: [Array],
    totals: Number,
  },
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      width: 1317,
      loading: true,
      hasChange: '',
      page: 1,
      total: this.totals || 0,
      current: 0,
      size: this.limit,
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
      tokenData: this.datas || [],
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60, fixed: 'left' },
        { title: 'Token', key: 'symbol', className: 'chain', fixed: 'left' },
        { title: this.$t('tokens.col_name'), key: 'project_name', className: 'chain', },
        { title: this.$t('tokens.col_price'), key: 'price', sorter: true, },
        { title: this.$t('tokens.col_chg'), key: 'change_rate_24h', sorter: true, className: 'chain', },
        { title: this.$t('tokens.col_market_cap'), key: 'market_cap', sorter: true },
        { title: this.$t('tokens.col_price_7d'), key: 'price_7d' },
      ],
      h5columns: [
        { title: '#', key: 'no', align: 'right', className: 'tf tf1' },
        { title: 'Token', key: 'symbol', className: 'chain', className: 'tf tf2' },
        { title: this.$t('tokens.col_name'), key: 'project_name', className: '', className: 'chain', },
        { title: this.$t('tokens.col_price'), key: 'price', sorter: true, },
        { title: this.$t('tokens.col_chg'), key: 'change_rate_24h', sorter: true, className: 'chain', },
        { title: this.$t('tokens.col_market_cap'), key: 'market_cap', sorter: true },
        { title: this.$t('tokens.col_price_7d'), key: 'price_7d' },
      ],
      order: 'desc',//排序
      search: [],
    };
  },

  mounted() {
    if (this.h5) {
      this.width = 900
    }
    this.getData()
  },
  methods: {
    dayChange(item) {
      if (item.value == this.currentDay) return
      this.currentDay = item.value
      this.days = item.value
      this.page = 1
      this.getData()
    },
    filterChange({ key, order }) {
      this.search = order ? [key + (order == 'asc' ? '_1' : '_2')] : []
      this.page = 1
      this.getData()
    },
    next() {
      this.current++
      this.initPage()
      this.black_chain = this.tabs[this.current].name
    },
    onHandlePageSize({ value }) {
      this.page = 1
      this.size = value;
      this.getData()
    },
    onHandlePage(page) {
      this.page = page;
      this.getData()
    },
    seeMore() {
      if (this.showPage) {
        this.page++
        this.getData()
      } else {
        window.location.href = '/rank/token'
      }
    },
    getData() {
      let { search, size, page } = this
      let order_list = JSON.stringify(search)
      this.loading = true
      this.$http.get('/api/v1/meta/token/rank', { limit: size, offset: (this.page - 1) * size, order_list }, ({ result }) => {
        this.total = result.count
        this.loading = false
        //要重置 page 的 pageCount
        let tokenData = result.records.map((m, i) => {
          m.symbol = m.symbol ? m.symbol.toUpperCase() : ''
          m.no = i + ((page - 1) * size) + 1
          return m
        })
        if (page == 1) {
          this.tokenData = []
        }
        if (this.h5 && page != 1) {
          tokenData = [].concat(this.tokenData, tokenData)
        }
        this.tokenData = tokenData
        this.hasChange = Date.now();
      })
    },
  },
  render() {
    let { h5, tokenData, columns, size, page, total, pageRanges, loading, h5columns, width, showViewall, showPage } = this
    return (
      <div class="token-box">
        <HomeTokenPc loading={loading} onFilter={this.filterChange} data={tokenData} columns={h5 ? h5columns : columns} change={this.hasChange} width={width} />
        {
          (total > 30 && h5 && showPage) ? (
            tokenData.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
          ) : null
        }

        {(!h5 && showPage) &&
          <div class="page-wrap">
            <Page total={total} current={page} pageSize={size} onChange={this.onHandlePage} />
            <div class="page-sizer flex-base">
              <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
              <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
            </div>
          </div>}
        {showViewall && <a href="/rank/token"><div class="more">{this.$t('com.view_all')}</div></a>}
      </div>
    )
  }
};
