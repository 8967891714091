
export default {
  layout: 'index',
  render() {
    return (
      <div class="page-social">
        <SocialRank  />
      </div>
    )
  }
};
