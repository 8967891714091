//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { ChevronDown } from 'kui-icons'
export default {
  computed: {
    ...mapGetters(['h5','lang'])
  },
  props: {
    showPage: Boolean,
    type: String,
    columns: Array,
    size: { type: Number, default: 5 }
  },
  data() {
    return {
      loading: false,
      total: 0,
      ChevronDown,
      titles: {
        active_user: this.$t('whale.whale_growth'),
        increase: this.$t('whale.whale_increase'),
        decline: this.$t('whale.whale_decline'),
      },
      data: this.showPage ? [{}, {}, {}, {}, {}, {}, {}, {}, {}, {},] : [{}, {}, {}, {}, {}],
      page: 1,
      urls: {
        active_user: '/v2/game/whale/increased_ranking',
        increase: '/v2/game/whale/token/increased_ranking',
        decline: '/v2/game/whale/token/decreased_ranking',
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let { size, page } = this
      this.loading = true
      let url = this.urls[this.type]
      this.$http.get(url, { limit: size, offset: (this.page - 1) * size, noEncrypt: true }, ({ count, list }) => {
        this.total = count
        this.loading = false
        //要重置 page 的 pageCount
        let data = list.map((m, i) => {
          m.count = m.count_increase || m.increased_whale_count || m.decreased_whale_count || 0
          m.precent = m.token_sum_cp_ratio || m.increased_whale_token_ratio || m.decreased_whale_token_ratio || 0
          return m
        })
        if (page == 1) {
          this.data = []
        }
        if (this.h5 && page != 1) {
          data = [].concat(this.data, data)
        }
        this.data = data
      })
    },
    rowClick(item) {
      if (!item.id && this.loading) return
      localStorage.setItem('game_id', item.id)
      this.$router.push('/games/' + item.slug + '?type=whale')
    },
    onHandlePage(page) {
      this.page = page;
      this.getData()
    },
    seeMore() {
      this.page++
      this.getData()
    },
  }
}
