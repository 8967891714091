
import './index.less'
import { mapGetters } from 'vuex'
import { ChevronDown } from 'kui-icons'
export default {
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      width: 1317,
      loading: false,
      page: 1,
      total: 0,
      size: 30,
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
      data: [
        {
          "name": "ChainGames",
          "logo": "https://res.mymetadata.io/img/games/realchaingames.jpg",
          "chain": "ethereum",
          "address": "0xC4C2614E694cF534D407Ee49F8E44D125E4681c4",
          "timestamp": 1665284699,
          "from_address": "0x60a531d4055fb1a8ed226d311275704da9596cab",
          "to_address": "0x0d0707963952f2fba59dd06f2b425ace40b492fe",
          "token_amount": "378544.2920237254",
          "token_value": "55606.263776825144",
          "token_symbol": "CPOOL",
          "flow": "out"
        }
      ],
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60, fixed: 'left',className: 'bold', },
        { title: this.$t('whale.col_name'), key: 'name', className: 'bold', fixed: 'left', width: 200, },
        { title: this.$t('whale.col_time'), key: 'timestamp', className: 'bold', },
        { title: this.$t('whale.col_address'), key: 'address',className: 'bold', },
        { title: this.$t('whale.col_behaviour'), key: 'flow', className: 'bold', },
        { title: this.$t('whale.col_count'), key: 'token_amount', className: 'bold', },
        { title: this.$t('whale.col_value'), key: 'token_value',className: 'bold', },
        { title: this.$t('whale.col_precent'), key: 'precent',className: 'bold', },
      ],
    };
  },

  mounted() {
    if (this.h5) {
      // this.width = 900
    }
    // this.getData()
  },
  methods: {
    onHandlePageSize({ value }) {
      this.page = 1
      this.size = value;
      this.getData()
    },
    onHandlePage(page) {
      this.page = page;
      this.getData()
    },
    seeMore() {
      this.page++
      this.getData()
    },
    getData() {
      let { size, page } = this
      this.loading = true
      this.$http.get('/v2/game/whale/latest_transfer', { limit: size, offset: (this.page - 1) * size, noEncrypt: true }, ({ count, list }) => {
        this.total = count
        this.loading = false
        let data = list.map((m, i) => {
          m.no = i + ((page - 1) * size) + 1
          return m
        })
        if (page == 1) {
          this.data = []
        }
        if (this.h5 && page != 1) {
          data = [].concat(this.data, data)
        }
        this.data = data
      }, err => {
        this.loading = false
      })
    },
  },
  render() {
    let { h5, data, columns, size, page, total, pageRanges, loading, width } = this
    return (
      <div class="token-box">
        <HomeWhaledealPc loading={loading} data={data} columns={columns} width={width} />
        {
          (total > 30 && h5) ? (
            data.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
          ) : null
        }
        {!h5 &&
          <div class="page-wrap">
            <Page total={total} current={page} pageSize={size} onChange={this.onHandlePage} />
            <div class="page-sizer flex-base">
              <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
              <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
            </div>
          </div>}
      </div>
    )
  }
};
