//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  props: {
    data: Array,
    columns: Array,
    width: Number,
    loading: Boolean,
    type: String
  },
  methods: {
    filterChange(a, b) {
      this.$emit('filter', b)
    },
    rowClick(item) {
      if (this.type) return
      window.location.href = '/studio/' + item.query_name
    },
  }
}
