
import './index.less'
export default {
  props: {
    icon: String,
    text: String
  },
  render() {
    let { icon, text } = this
    if (text == 'binance-smart-chain') text = 'BSC'
    if (text == 'ethereum') text = 'ETH'
    if (text == 'okexchain') text = 'OEC'
    if (icon == 'ethereum') icon = 'eth'
    if (icon == 'binance-smart-chain') icon = 'bsc'
    return (
      <span class={['my-tag', { 'my-tag-with-text': text }]}>{icon ? <Chain name={icon} /> : null}{text}</span>
    )
  }
}
