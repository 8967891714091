//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './index.less'
import { mapGetters } from 'vuex'
import { ChevronDoubleForward } from 'kui-icons'
export default {
  computed: {
    ...mapGetters(['h5'])
  },
  props: {
    data: Object,
    loading: false
  },
  data() {
    return {
      ChevronDoubleForward,
      visible: false,
      status: {
        status1: this.$t('ido.preview'),
        status2: this.$t('ido.ongoing'),
        status3: this.$t('ido.finished')
      },
    };
  },
  methods: {
    openKol(data){
      this.$refs.HomeKolModal.showList(data.ido_id,data.kol_count,'KOL Followers')
    },
  },
};
