//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { ChevronDown } from "kui-icons";
export default {
  computed: {
    ...mapGetters(['lang'])
  },
  props: {
    data: Array,
    columns: Array,
    total: Number,
  },
  data() {
    return {
      ChevronDown,
      filterRanges: [
        { label: this.$t('social.col_volume'), value: 'social_volume' },
        { label: this.$t('social.col_engagement'), value: 'social_engagement' },
        { label: this.$t('social.col_kol'), value: 'mentions_kol' },
      ],
      filterType: 'social_volume',
      filterLabels: {
        social_volume: this.$t('social.col_volume'),
        social_engagement: this.$t('social.col_engagement'),
        mentions_kol: this.$t('social.col_kol'),
      },
    }
  },
  methods: {
    rowClick(item) {
      this.$router.push(`/games/${item.game_name}?type=community`)
    },
    h5sortChange(a, b) {
      this.$emit('sort', b)
    },
    h5filterChange(item) {
      this.filterType = item.key
      this.$emit('filter', item)
    }
  },
}
