//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  props: {
    data: Array,
    type: String,
    loading: Boolean
  },
  data() {
    return {
      // loading: true,
      types: {
        'kols-following': this.$t('trend.kol_follower'),
        'kols-discussing': this.$t('trend.table_hot'),
        'vc-investments': '',
      }
    }
  },
  methods: {
    rowClick(item) {
      if (!item.is_online) return this.$Message.info('暂未收录');
      if (this.type == 'vc-investments') {
        if (item.is_active) {
          this.$router.push(`/games/${item.query_name}`)
        } else {
          this.$Message.info('暂未收录');
        }
      } else {
        this.$router.push(`/games/${item.game_name}?type=community`)
      }
    },
  },
}
