
import '~/assets/less/home.less'
import { mapGetters } from 'vuex'
export default {
  layout: 'index',
  data() {
    return {
      pageType: 'ranking',
      pageTabs: [
        { name: this.$t('GameFiTabs.tab2'), value: 'ranking' },
        { name: this.$t('GameFiTabs.tab3'), value: 'token' },
        { name: this.$t('GameFiTabs.tab4'), value: 'guild' },
        { name: this.$t('GameFiTabs.tab6'), value: 'studio' },
        { name: this.$t('GameFiTabs.tab5'), value: 'investor' },
      ],
      currentIndex: '',
      showSum:false,//等banner加载完再显示
    };
  },
  mounted() {
    this.currentIndex = this.rankgameIndex || 0
    this.pageType = this.pageTabs[this.currentIndex].value
    // setTimeout(() => {
    //     this.showSum = 
    // }, 1000);
  },
  computed: {
    ...mapGetters(['h5', 'gamefiData', 'rankgameIndex'])
  },
  methods: {
    changePageTab(i) {
      if (this.currentIndex == i) return
      this.currentIndex = i
      this.utils.setCookie('rankgameIndex', i)
      this.pageType = this.pageTabs[i].value
    },
  },
  render() {
    let { pageType, pageTabs, changePageTab, currentIndex, gamefiData, h5, showSum } = this
    return (
      <div class="home">
        {h5 ? <KLine type="swipper" /> : null}
        {!h5 && showSum ? <KLine isHome={true} /> : null}
        <HomeTop />
        <HomeTrend />
        <HomeNewgame />
        <HomeAlpha />
        <HomeAirdrop />
        {/* <HomeWhale /> */}
        <div class="game-box home-item">
          <div class="page-center">
            <div class="title">
              {this.$t('rank.title')}
            </div>
            <div class="sub-title">
              {this.$t('rank.sub_title1')}<span class="value"> {gamefiData.game_count} </span> {this.$t('rank.sub_title2')}<span class="value"> {gamefiData.token_count} </span> {this.$t('rank.sub_title3')}<span class="value"> {gamefiData.guild_count} </span> {this.$t('rank.sub_title4')}<span class="value"> {gamefiData.investor_count} </span> {this.$t('rank.sub_title5')}
            </div>
            <HomeTabs tabs={pageTabs} onChangeTab={changePageTab} activeIndex={currentIndex} />
            <div class="tab-content">
              {pageType == 'ranking' ? <HomeGamesPart  /> : null}
              {pageType == 'token' ? <HomeToken showViewall={true} /> : null}
              {pageType == 'guild' ? <HomeGuild showViewall={true} /> : null}
              {pageType == 'investor' ? <HomeInvestor showViewall={true} /> : null}
              {pageType == 'studio' ? <HomeStudio showViewall={true} /> : null}
            </div>
          </div >
        </div >
      </div >
    )
  }
};
