//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['lang'])
  },
  props: {
    data: Array,
    columns: Array,
    change: [Number, String]
  },
  watch: {
    change(v) {
      this.$nextTick(() => {
        this.getLine()
      })
    }
  },
  methods: {
     rowClick(item) {
      this.$router.push(`/games/${item.game_name}?type=community`)
    },
    filterChange(a, b) {
      this.page = 1
      this.$emit('filter', b)
    },
    getLine() {
      this.data.forEach(x => {
        let date = [],data_list = []
        let lineid = document.getElementById('map_line_' + x.game_name)
        x.social_volume_7d.map(m=>{
           date.unshift(m.date)
           data_list.unshift(m.daily_social_volume)
        })
        let xAxis = date
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: { width: 0 }
            },
            backgroundColor: '#121326',
            borderWidth: 0,
            textStyle: { color: '#fff' },
            formatter: (params, ticket, callback) => {
              let param = params[0]
              return `${param.axisValue}<br/>${param.data}`;
            }
          },
          grid: { left: '5px', right: '0', bottom: '5px', top: '5px', containLabel: true },
          xAxis: {
            type: 'category', data: xAxis,
            axisLine: { show: false, }, //X line bottom
            axisLabel: { show: false }, // label
            axisTick: { show: false } //刻度
          },
          yAxis: {
            type: 'value',
            axisLabel: { show: false }, //x label
            splitLine: { show: false, }, //X
            axisTick: { show: false },//刻度
            min: value => value.min,
            max: value => value.max,
          },
          series: {
            data: [],
          }
        }
        if (data_list.length < 7) {
          let line = echarts.init(lineid)
          line.dispose()
          lineid.innerHTML = '--'
          lineid.style = ''
        } else {
          let line = echarts.init(lineid)
          line.clear()
          let lineoption = Object.assign({}, option)
          lineoption.series.data = data_list
          lineoption.series.type = 'line'
          let color = data_list[6] > data_list[5] ? '#3cd19b' : '#FF5147';
          lineoption.series.lineStyle = { width: 1, color: color }
          lineoption.series.symbol = 'circle'
          lineoption.series.itemStyle = { color: color }
          lineoption.series.symbolSize = 8
          lineoption.series.showAllSymbol = false
          option.series.emphasis = {
            color: color
          }
          line.setOption(lineoption)
        }
      })
    },
  },
  data() {
    return {
      page: 1,
    }
  }
}
