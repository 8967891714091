//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  props: {
    data: Array,
    columns: Array,
    width: Number,
    loading: Boolean,
    type: String
  },
  data() {
    return {
      flow: {
        out: this.$t('whale.out'),
        in: this.$t('whale.in'),
      },
      moment,
    }
  },
  methods: {
    rowClick(item) {
      if (this.type) return
      window.location.href = '/investor/' + item.query_name
    },
  }
}
