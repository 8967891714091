//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { Reload, Close } from 'kui-icons'
import { mapGetters } from 'vuex'
import vuedraggable from 'vuedraggable';
export default {
  components: { vuedraggable },
  props: {
  },
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      selectBtns: [],
      chooseBtns: [],
      names: {
        1: this.$t('games.col_active_user'),
        2: this.$t('games.col_active_user_change'),
        3: this.$t('games.col_volume'),
        4: this.$t('games.col_volume_change'),
        5: this.$t('games.col_transfers'),
        6: this.$t('games.col_tag'),
        7: this.$t('games.token_symbol'),
        8: this.$t('games.token_price'),
        9: this.$t('games.token_change_rate_24h'),
        10: 'Token Holders',
        11: this.$t('games.col_community'),
        12: 'Twitter',
        13: 'Discord',
        14: 'Telegram',
        15: this.$t('games.twitter_hot'),
        16: this.$t('games.kol_follower'),
        17: this.$t('games.col_new_users'),
        18: this.$t('games.col_total_users_v0'),
      },
      titles: {
        1: this.$t('games.transaction_title'),
        2: this.$t('games.token_data'),
        3: this.$t('games.col_community'),
        4: this.$t('games.twitter_hot'),
      },
      list: [],
      defaultBaseCustom: [
        {
          title: this.$t('games.transaction_title'), id: 1, btns: [
            { name: this.$t('games.col_new_users'), key: 'new_users', icon: 'customize-rank', active: false, id: 17 }, { name: this.$t('games.col_active_user'), key: 'active', icon: 'customize-chart', active: false, id: 1 },
            { name: this.$t('games.col_active_user_change'), key: 'active_user_7d', icon: 'customize-change', active: false, id: 2 },
            { name: this.$t('games.col_total_users_v0'), key: 'total_users_v0', icon: 'customize-rank', active: false, id: 18 },
            { name: this.$t('games.col_volume'), key: 'volume', icon: 'customize-rank', active: false, id: 3 }, { name: this.$t('games.col_volume_change'), key: 'volume_7d', icon: 'customize-chart', active: false, id: 4 }, { name: this.$t('games.col_transfers'), key: 'hot', icon: 'customize-rank', active: false, id: 5 },
            { name: this.$t('games.col_tag'), key: 'tags', icon: 'customize-rank', active: false, id: 6 },
          ]
        },
        { title: this.$t('games.token_data'), id: 2, btns: [{ name: this.$t('games.token_symbol'), key: 'token_symbol', icon: 'customize-change', active: false, id: 7 }, { name: this.$t('games.token_price'), key: 'token_price', icon: 'customize-change', active: false, id: 8 }, { name: this.$t('games.token_change_rate_24h'), key: 'token_change_rate_24h', icon: 'customize-change', active: false, id: 9 }, { name: 'Token Holders', key: 'token_holders', icon: 'customize-change', active: false, id: 10 }] },
        {
          title: this.$t('games.col_community'), id: 3, btns: [{ name: this.$t('games.col_community'), key: 'social', icon: 'customize-change', active: false, id: 11 }, { name: 'Twitter', key: 'twitter', icon: 'customize-change', active: false, id: 12 }, { name: 'Discord', key: 'discord', icon: 'customize-change', active: false, id: 13 },
          // { name: '活跃数', key: '', icon: 'customize-change', active: false }, 
          { name: 'Telegram', key: 'telegram', icon: 'customize-change', active: false, id: 14 },]
        },
        { title: this.$t('games.twitter_hot'), id: 4, btns: [{ name: this.$t('games.twitter_hot'), key: 'total_engagements', icon: 'customize-rank', active: false, id: 15 }, { name: this.$t('games.kol_follower'), key: 'mentions_kol', icon: 'customize-rank', active: false, id: 16 }] },
      ],
      baseList: [
        {
          title: this.$t('games.transaction_title'), id: 1, btns: [
            { name: this.$t('games.col_new_users'), key: 'new_users', icon: 'customize-rank', active: true, id: 17 }, { name: this.$t('games.col_active_user'), key: 'active', icon: 'customize-chart', active: true, id: 1 },
            { name: this.$t('games.col_active_user_change'), key: 'active_user_7d', icon: 'customize-change', active: false, id: 2 },
            { name: this.$t('games.col_total_users_v0'), key: 'total_users_v0', icon: 'customize-rank', active: false, id: 18 },
            { name: this.$t('games.col_volume'), key: 'volume', icon: 'customize-rank', active: true, id: 3 }, { name: this.$t('games.col_volume_change'), key: 'volume_7d', icon: 'customize-chart', active: false, id: 4 }, { name: this.$t('games.col_transfers'), key: 'hot', icon: 'customize-rank', active: true, id: 5 },
            { name: this.$t('games.col_tag'), key: 'tags', icon: 'customize-rank', active: true, id: 6 },
          ]
        },
        { title: this.$t('games.token_data'), id: 2, btns: [{ name: this.$t('games.token_symbol'), key: 'token_symbol', icon: 'customize-change', active: true, id: 7 }, { name: this.$t('games.token_price'), key: 'token_price', icon: 'customize-change', active: true, id: 8 }, { name: this.$t('games.token_change_rate_24h'), key: 'token_change_rate_24h', icon: 'customize-change', active: true, id: 9 }, { name: 'Token Holders', key: 'token_holders', icon: 'customize-change', active: true, id: 10 }] },
        {
          title: this.$t('games.col_community'), id: 3, btns: [{ name: this.$t('games.col_community'), key: 'social', icon: 'customize-change', active: true, id: 11 }, { name: 'Twitter', key: 'twitter', icon: 'customize-change', active: false, id: 12 }, { name: 'Discord', key: 'discord', icon: 'customize-change', active: false, id: 13 },
          // { name: '活跃数', key: '', icon: 'customize-change', active: false }, 
          { name: 'Telegram', key: 'telegram', icon: 'customize-change', active: false, id: 14 },]
        },
        { title: this.$t('games.twitter_hot'), id: 4, btns: [{ name: this.$t('games.twitter_hot'), key: 'total_engagements', icon: 'customize-rank', active: true, id: 15 }, { name: this.$t('games.kol_follower'), key: 'mentions_kol', icon: 'customize-rank', active: true, id: 16 }] },
      ],
      Close,
      Reload,
      showCustom: false,
      type: "2",
      okType: null,
      options: [
        { label: this.$t('games.custom_type'), value: '1' },
        { label: this.$t('games.custom_default'), value: '2' },
      ],
      custom: {},
      dragOptions: { ghostClass: 'dragChoose', chosenClass: 'active', animation: 250, scrollSensitivity: 30, ghostClass: "ghost" },
      storeBtn: [],
      storeCustom: [],
    }
  },
  mounted() {
    if (!this.h5) {
      let hasClear = localStorage.getItem('hasClear')
      if (hasClear != 6) {
        localStorage.setItem('hasClear', 6)
        this.utils.delCookie('btn')
        this.utils.delCookie('custom')
        localStorage.setItem('btn', '[]')
        let str = {
          type: "2", list: []
        }
        localStorage.setItem('custom', JSON.stringify(str))
      }
      this.storeBtn = JSON.parse(localStorage.getItem('btn') || '[]')
      this.storeCustom = JSON.parse(localStorage.getItem('custom') || '[]')
      //默认的样式
      this.custom = this.storeCustom
      this.type = this.custom.type || this.type
      this.okType = this.type
      if (this.okType == 1) {
        this.chooseBtns = this.utils.deepClone(this.storeBtn)
        this.selectBtns = this.utils.deepClone(this.chooseBtns)
        this.list = this.utils.deepClone(this.custom.list)
      } else {
        this.selectBtns = this.getDefalutBtn()
        this.chooseBtns = this.utils.deepClone(this.selectBtns)
        this.list = this.utils.deepClone(this.baseList)
      }

      this.getActive(this.list)
      this.$nextTick(() => {
        this.$emit('setCustomize', this.chooseBtns)
      })
    }
  },
  methods: {
    getDefalutBtn() {
      let selectBtns = [], tagActive = false
      this.baseList.map(m => {
        m.btns.map(h => {
          if (h.active) {
            if (h.key == 'tags') {
              tagActive = true
            } else {
              selectBtns.push(h)
            }
          }
        })
      })
      if (tagActive) {
        selectBtns.push({ name: this.$t('games.col_tag'), key: 'tags', icon: 'customize-rank', active: true, id: 6 },)
      }
      return selectBtns
    },
    changePos() {
      this.type = '1'
    },
    //防止乱码
    getActive(list) {
      this.chooseBtns = this.chooseBtns.map(h => {
        h.name = this.names[h.id]
        return h
      })
      this.selectBtns = this.selectBtns.map(h => {
        h.name = this.names[h.id]
        return h
      })
      this.list = this.list.map(m => {
        m.title = this.titles[m.id]
        m.btns.map(h => {
          h.name = this.names[h.id]
          return h
        })
        return m
      })
    },
    changeBtn({ value }) {
      this.type = value || this.okType
      if (this.type == '2') {
        //type == 2  取默认模式的
        this.list = this.utils.deepClone(this.baseList)
        this.selectBtns = this.utils.deepClone(this.getDefalutBtn())
      } else {
        //type == 1  有缓存取缓存  没缓存 为空
        this.list = this.okType == 2 ? this.utils.deepClone(this.defaultBaseCustom) : (this.utils.deepClone(this.custom.list) || this.utils.deepClone(this.defaultBaseCustom))
        //默认是type ==2  切换到1的时候置空
        this.selectBtns = this.okType == 2 ? [] : (this.utils.deepClone(this.chooseBtns) || this.utils.deepClone(this.storeBtn))
        //防止乱码
        this.$nextTick(() => {
          this.getActive(this.list)
        })
      }
    },
    ok() {
      this.chooseBtns = this.utils.deepClone(this.selectBtns)
      this.$emit('setCustomize', this.chooseBtns)
      this.showCustom = false
      this.okType = this.type
      if (this.okType == '2') {
        localStorage.setItem('btn', '[]')
        this.custom.list = this.utils.deepClone(this.defaultBaseCustom)
      }
      if (this.okType == '1') {
        this.custom.list = this.list
        localStorage.setItem('btn', JSON.stringify(this.chooseBtns))
      }
      let str = {
        type: this.type, list: this.list
      }
      localStorage.setItem('custom', JSON.stringify(str))
    },
    close() {
      setTimeout(() => {
        this.type = this.okType
        if (this.type == '1') {
          this.list = this.utils.deepClone(this.custom.list)
        } else {
          this.list = this.utils.deepClone(this.baseList)
        }
        this.selectBtns = this.utils.deepClone(this.chooseBtns)
        this.getActive(this.list)
      }, 1000);
    },
    changeActive(item, i, j) {
      this.type = '1'
      let isActive = this.list[i].btns[j].active
      let inArr = this.selectBtns.findIndex(m => m.name == item.name)
      if (inArr > -1) {
        this.selectBtns.splice(inArr, 1)
      } else {
        let has_new_users = this.selectBtns.findIndex(h => h.key == 'active')
        if (item.key == 'total_users_v0' && has_new_users > -1) {
          this.selectBtns.splice(has_new_users+1,0,item)
        } else {
          this.selectBtns.push(item)
        }
      }
      this.list[i].btns[j].active = !isActive
    },

  }
}
