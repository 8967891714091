import { render, staticRenderFns } from "./description.vue?vue&type=template&id=02737f2e&"
import script from "./description.vue?vue&type=script&lang=js&"
export * from "./description.vue?vue&type=script&lang=js&"
import style0 from "./description.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Tags: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/tags/index.vue').default,HomeGamesTag: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/home/games/tag.vue').default})
