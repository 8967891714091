import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=73aea2f5&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GamesBase: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/base.vue').default,GamesAbout: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/about.vue').default,GamesChainData: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/chainData.vue').default,GamesCommunity: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/community.vue').default,GamesWhale: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/whale.vue').default,GamesGuide: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/guide.vue').default,GamesRoi: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/roi.vue').default,GamesViewpoint: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/viewpoint.vue').default,GamesStrategy: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/strategy.vue').default,GamesOverview: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/overview.vue').default})
