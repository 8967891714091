import { render, staticRenderFns } from "./social.vue?vue&type=template&id=92ad8f44&"
import script from "./social.vue?vue&type=script&lang=js&"
export * from "./social.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Legend: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/Legend.vue').default,Title: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/title/index.vue').default,SocialTable: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/social/table.vue').default,HomeTabs: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/home/tabs/index.vue').default})
