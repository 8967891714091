//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { CaretDown, CaretUp } from "kui-icons";
export default {
  computed: {
    ...mapGetters(['h5'])
  },
  props: {
    data: Array,
    loading: Boolean,
    type: String,
    needRank: Boolean,
    columns: [],
  },
  data() {
    return {
      CaretDown, CaretUp,
      titles: {
        active_user: this.$t('alpha.alpha_active_user'),
        holder_token: this.$t('alpha.alpha_holder_token'),
        increase: this.$t('alpha.alpha_increase'),
        decline: this.$t('alpha.alpha_decline'),
      },
    }
  },
  methods: {
    changeType() {
      this.$emit('changeType', this.type == 'increase' ? 'decline' : 'increase')
    },
    rowClick(item) {
      if(!item.game_name && this.loading) return
      this.$router.push('/games/' + item.game_name)
    },
  }
}
