//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  layout: 'game',
  data() {
    return {
      game: 'alien-worlds-1',
      base: {},
      active: this.h5 ? 'overview' : 'community'
    }
  },
  created() {
    !this.h5 && this.setShowBanner(true)
    let type = this.$route.query.type || (this.h5 ? 'overview' : 'community')
    let game = this.$route.params.id
    if (game == 'stepn' || game == 'walken' || game == 'melody'|| game == 'read2n' ) {
      this.$router.replace('/subject/' + game)
    }
    if (/about|chain|token|community|event|ido|guide|social|roi|viewpoint|strategy|whale|overview/.test(type)) {
      this.active = type
    }
  },
  methods: {
    ...mapMutations(['setShowBanner']),
    change(key) {
      this.active = key
    },
  }
}
