//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      info: {
        team: [],
        jigou: [],
        play: '',
        model: '',
      },
      empty: false,
      loading: true,
      canPush: false,
    }
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      let game = this.$route.params.id
      this.$http.get('/api/v1/meta/game/investor/list', { game_name: game }, a => {
        this.$http.get('/api/v1/meta/game/project/info', { game_name: game }, ({ result }) => {
          if (result.project_info && a.result.records.length) {
            this.canPush = true
            result.project_info.jigou = a.result.records
          }
          this.info = JSON.stringify(result.project_info) == '{}' ? '' : result.project_info
          this.loading = false
          this.empty = this.info && !this.info.model
        })

      })

    }
  }
}
