//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/news.less'
import '~/assets/less/layout/mmd.less'
import moment from 'moment';
import { mapGetters } from 'vuex';

export default {
  layout: 'index',
  data() {
    return {
      moment,
      data: {},
      id: '',
      imgs: [],
      game: '',
      list: []
    };
  },
  head() {
    return {
    }
  },
  computed: {
    ...mapGetters(['h5'])
  },
  async asyncData({ $http, route }) {
    let mirror_id = route.params.id || ''
    return $http.get('/api/v1/meta/game/mirror/info', { mirror_id }).then(({ result }) => {
      result.content = result.content ? result.content.replace(/<button/g, '<button style="display:none;"') : ''
      return {
        data: result,
      }
    })
  },
  mounted() {
    this.game = this.$route.query.game || 'fitr'
    this.getList()
    this.$nextTick(() => {
          let el_img = document.querySelectorAll('.strategy-content img')
          let urls = [];
          el_img.forEach((el,i)=>{
             urls.push(el.getAttribute('src'))
              el.onclick=()=>{
                this.$Image.show({
                  data:urls,index:i
                })
              }
          })
        })  
  },
  watch: {
    $route: {
      handler(v) {
        this.getList()
        this.getContent()
      },
      deep: true
    },
  },
  methods: {
    getList() {
      this.$http.get('/api/v1/meta/game/mirror/list', { game_name: this.game, limit: 5, offset: 0, }, ({ result }) => {
        this.list = result.records
      })
    },
    // getContent() {
    //   this.$http.get('/api/v1/meta/game/mirror/info', { mirror_id: this.$route.params.id }, ({ result }) => {
    //     this.data = result
        
    //   })
    // },
    getImg(str) {
      let patt = /<img[^>]+src=['"]([^'"]+)['"]+/g;
      let result = [],
        temp;
      while ((temp = patt.exec(str)) != null) {
        result.push({ 'url': temp[1] });
      }
      return unescape(result[0].url).split("url=")[1].split("&amp")[0]
    },
    go(item) {
      this.$router.push(`/strategy/${item.mirror_id}`)
    },
  },
};
