
import './index.less'
import { mapGetters } from 'vuex'

export default {
  layout: "index",
  computed: {
    ...mapGetters(['h5', 'lang', 'gamefiData'])
  },
  data() {
    return {
      idos: [{}, {}, {}, {}],
      loading: true,
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.$http.get('/api/v1/meta/ido/list', {
        limit: 4, offset: 0,
        status: '', start_time: '', end_time: '',
        utc: this.lang == 'en' ? '' : '8'
      }, ({ result }) => {
        this.loading = false
        this.idos = result.records.map(m => {
          m.description_en = m.description_en ||  m.description || ''
          m.description = m.description ||  m.description_en || ''
          // m.social = m.social.filter(h => h.title == 'telegram' || h.title == 'twitter')
          return m
        })
      })
    }
  },
  render() {
    let { idos, gamefiData, loading } = this
    const idoDom = idos.map(item => {
      return (<HomeIdoItem data={item} loading={loading} />)
    })
    return (
      <div class="airdrop home-item">
        <div class="page-center">
          <div class="title">
            {this.$t('airdrop.title')}
          </div>
          <div class="sub-title">
            {this.$t('airdrop.sub_title1')}<span class="value"> 100+ </span>{this.$t('airdrop.sub_title2')}<span class="value"> {gamefiData.game_count} </span>{this.$t('airdrop.sub_title3')}
          </div>
          <div class="airdrop-wrap flex-base">
            {idos.length ? idoDom : <Empty description={this.$t('com.empty')} />}
          </div>
          <a href="/airdrops"><div class="more">{this.$t('com.view_all')}</div></a>
        </div>
      </div>
    )
  }
};
