//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      newList: [{}],
      size: 10,
      page: 1,
      total: 0,
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  mounted() {
    this.game = this.$route.params.id
    this.getNews()
  },
  methods: {
    seeMore() {
      this.page++
      this.getNews()
    },
    getNews() {
      let { size, page, game } = this
      this.$http.get('/api/v1/news/game/news', { limit: size, offset: (page - 1) * size, query_name: game }, ({ result }) => {
        this.total = result.count
        if (page == 1) this.newList = []
        this.newList = this.newList.concat(result.records)
        setTimeout(() => {
          this.loading = false
        }, 300);
      })

    },
  },
};
