
import '~/assets/less/ranking.less'
import { mapGetters, mapMutations } from 'vuex';
export default {
  layout: 'index',
  data() {
    return {
      tabs: [
        { name: this.$t('trend.hot'), value: 'hot' },
        { name: this.$t('trend.follower'), value: 'follower' },
        { name: this.$t('trend.investor'), value: 'investor' },
      ],
      types: 'kols-discussing|kols-following|vc-investments',
      id: '',
      titles: {
        'kols-following': this.$t('trend.kol_title'),
        'kols-discussing': this.$t('trend.hot_title'),
        'vc-investments': this.$t('trend.investor_title'),
      },
      sub_titles: {
        'kols-following': this.$t('trend.kol_sub_title'),
        'kols-discussing': this.$t('trend.hot_sub_title'),
        'vc-investments': ''//this.$t('trend.investor_sub_title1') + this.gamefiData.sum + this.$t('trend.investor_sub_title2'),
      },
      sum: ''
    };
  },
  computed: {
    ...mapGetters(['gamefiData'])
  },
  created() {
    this.init()
    this.getCount()
  },
  methods: {
    ...mapMutations(['setGamefiData']),
    getCount() {
      this.$http.get('/api/v1/meta/game/total', {}, ({ result }) => {
        result.sum = result.guild_count + result.investor_count
        for (let key in result) {
          result[key] = this.utils.changeUnit(result[key])
        }
        this.setGamefiData(result)
      })
    },
    init() {
      let id = this.$route.params.id
      if (!/kols-following|kols-discussing|vc-investments/.test(id)) id = "kols-discussing"
      this.current = this.types.split('|').findIndex(x => x == id)
      // console.log(this.current, id)
      this.id = id
      return id
    },
    changeTab(i) {
      this.current = i
      let t = this.types.split('|')[i]
      this.$router.push(`/trend/${t}`)
    },
  },
  render() {
    let { tabs, id, current, changeTab, titles, sub_titles } = this
    id = id || this.init()
    let title = titles[id], sub_title = sub_titles[id]
    if (id == 'vc-investments') {
      sub_title = this.$t('trend.investor_sub_title1') + (this.gamefiData.sum || '') + this.$t('trend.investor_sub_title2')
    }
    return (
      <div class='trend-follower ranking'>
        <div class="title">{title}</div>
        <div class="sub-title">{sub_title}</div>
        <HomeTabs tabs={tabs} onChangeTab={changeTab} activeIndex={current} />
        {id == 'kols-following' ? <HomeTrendFollower /> : null}
        {id == 'kols-discussing' ? <HomeTrendKol /> : null}
        {id == 'vc-investments' ? <HomeTrendInvestor /> : null}
      </div>
    )
  }
};
