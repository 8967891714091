//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      value: [],
      numberList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      compKey: 0
    };
  },
  props: ["val", "suffix"],
  watch: {
    val() {
      this.value = (this.val || '').toString().split("");
      this.compKey += 1;
    }
  },
  created() {
    this.value = (this.val || '').toString().split("");
  },
};
