
import '../index.less'
import { mapGetters } from 'vuex'
import { ChevronDown, Search } from 'kui-icons'
export default {
  props: {
    oDatas: [Array],
    totals: Number,
  },
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      showDay: true,
      page: 1,
      total: this.totals || 0,
      current: 0,
      loading: true,
      size: 30,
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
      guildData: this.oDatas || [],
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('social.col_game'), key: 'name' },
        { title: this.$t('social.col_volume'), key: 'social_volume', render: (h, p) => h('span', this.utils.changeUnit(p.social_volume)), className: "chain", width: 180 },
        { title: this.$t('social.col_engagement'), key: 'total_engagements', width: 200 },
        { title: this.$t('social.col_kol'), key: 'mentions_kol', width: 440 },
      ],
      currentDay: 1,
      dayRanges: [
        { label: '24' + this.$t('com.hour'), value: 1 },
        { label: '7' + this.$t('com.day'), value: 7 },
        { label: '30' + this.$t('com.day'), value: 30 }
      ],
      days: 1,
      engagements: 0,
      engagement: 0,
      timer: null,
      query_name: ''
    };
  },

  mounted() {
    this.getKol()
  },
  methods: {
    dayChange(item) {
      if (item.value == this.currentDay) return
      this.currentDay = item.value
      this.days = item.value
      this.page = 1
      if (this.h5) {
        this.guildData = []
      }
      this.getKol()
    },
    onHandlePageSize({ value }) {
      this.page = 1
      this.size = value;
      this.getKol()
    },
    onHandlePage(page) {
      this.page = page;
      this.getKol()
    },
    seeMore() {
      this.page++
      this.getKol()
    },
    getKol() {
      let { size, query_name } = this
      this.loading = true
      this.$http.get('/api/v1/meta/kol/games/hot/rank', { limit: size, offset: (this.page - 1) * size, days: this.days, query_name }, ({ result }) => {
        this.loading = false
        if (result.records.length) {
          if (this.page == 1) {
            this.engagements = result.records[0].total_engagements
            this.engagement = this.engagements / 5
          }
          this.total = result.count
          let guildData = result.records.map((m, i) => {
            m.logos = [], m.nicks = [], m.tids = []
            m.mentions_details.records && m.mentions_details.records.slice(0, 3).map(h => {
              m.nicks.push(h.nick)
              m.logos.push({ tid: h.tid, nick: h.nick, logo: h.logo })
            })
            let unit = m.total_engagements / this.engagement
            m.starCount = Math.ceil(unit)
            m.count = parseInt(unit)
            m.paddingRight = ((1 - unit % 1) * 16)
            m.no = i + ((this.page - 1) * size) + 1
            m = { ...m, ...m.investment_info }
            return m
          })
          if (this.h5) {
            this.guildData = this.guildData.concat(guildData)
          } else {
            this.guildData = guildData
          }
        } else {
          this.guildData = []
        }

      }, err => {
        this.loading = false
      })
    },
    onSearch(e) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.query_name = e.target.value
        this.page = 1
        this.getKol()
      }, 300);
    }
  },
  render() {
    let { h5, guildData, columns, size, page, total, pageRanges, loading, dayChange, dayRanges, currentDay, showDay, days } = this
    if (h5 && !guildData.length) {
      guildData = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
    }
    return (
      <div class="trend-box">
        {!h5 ? <Input type="search" class="search-input right" shape="circle" theme="light" onKeyup={(e) => this.onSearch(e)} placeholder={this.$t('menu.search')}>
          <Icon type={Search} slot="suffix" />
        </Input> : null}
        {showDay && <div class="daysfilter-wrap">
          {h5 ? <Select width={80} bordered={false} value={1} onChange={dayChange} class="h5-daysfilter">
            {
              dayRanges.map((item, i) => {
                return (
                  <Option value={item.value} label={item.label} key={i} onClick={() => this.dayChange(item, i)} />
                )
              })
            }
          </Select> : <div class="daysfilter flex-base">
            {
              dayRanges.map((item, i) => {
                return (
                  <span class={['day', { 'active': currentDay == item.value }]} key={i} onClick={() => this.dayChange(item, i)}>{item.label}</span>
                )
              })
            }
          </div>}
        </div>}
        {
          h5 ?
            <HomeTrendItem data={guildData} type='kols-discussing' loading={loading && page == 1} /> :
            <HomeTrendKolPc data={guildData} columns={columns} loading={loading} days={days} />
        }
        {
          (total > 30 && h5) ? (
            guildData.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
          ) : null
        }

        {!h5 &&
          <div class="page-wrap">
            <Page total={total} current={page} pageSize={size} onChange={this.onHandlePage} />
            <div class="page-sizer flex-base">
              <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
              <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
            </div>
          </div>}
      </div>
    )
  }
};
