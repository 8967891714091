//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: Array,
    columns: Array,
    loading: Boolean
  },
  data() {
    return {
      descShow: false,
      list: [],
      title: '',
      current: 0,
      tabs: [
        { name: this.$t('com.all'), value: '' },
        { name: 'WHALE', value: 'WHALE' },
        { name: 'PLATFORM', value: 'PLATFORM' },
        { name: 'EXCHANGE', value: 'EXCHANGE' },
        { name: 'BUILDER', value: 'BUILDER' },
        { name: 'VC', value: 'VC' },
        { name: 'PROJECT', value: 'PROJECT' },
        { name: 'NFT', value: 'NFT' },
      ],
      _list: [],
      WHALE_list: [],
      PLATFORM_list: [],
      EXCHANGE_list: [],
      BUILDER_list: [],
      VC_list: [],
      PROJECT_list: [],
      NFT_list: [],
    }
  },
  methods: {
    showList(query_name, query_types, days, count, title, item) {
      if (!item.is_online) return this.$Message.info('暂未收录');
      this.current = 0
      this.title = title
      if (!count) return
      this.$http.get('/api/v1/meta/game/kol/list', { query_name, query_types, days, limit: 999, offset: 0 }, ({ result }) => {
        this.tabs.map(h => {
          h.name = h.value || this.$t('com.all')
          let list = []
          result.records.map(m => {
            if ((m.content_type || []).findIndex(n => h.value == n) > -1) {
              list.push(m)
            }
          })
          this[h.value + '_list'] = list
          h.name = h.name + '(' + (h.value ? this[h.value + '_list'].length : result.records.length) + ')'
        })
        this.list = result.records
        this._list = result.records
        this.descShow = true
      })
    },
    toTT({ tid }) {
      open(`https://twitter.com/${tid}`)
    },
    rowClick(item) {
      if (!item.is_online) return this.$Message.info('暂未收录');
      this.$router.push(`/games/${item.game_name}?type=community`)
    },
    changeTab(i) {
      if (i == this.current) return
      this.current = i
      this.content_type = this.tabs[i].value
      this.list = this[this.content_type + '_list']
    },
    filterChange(a, b) {
      this.$emit('filter', b)
    },
  }
}
