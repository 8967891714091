//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { AlertCircle } from "kui-icons";
export default {
  data() {
    return {
      AlertCircle
    }
  },
  props: {
    data: Array,
    columns: Array,
    change: [Number, String],
    loading: Boolean,
    width: Number
  },
  watch: {
    change(v) {
      this.$nextTick(() => {
        this.getLine()
      })
    }
  },
  methods: {
    filterChange(a, b) {
      this.$emit('filter', b)
    },
    rowClick(item) {
      window.location.href = `/games/${item.query_name}?type=chain`
    },
    getLine() {
      let s = moment(), date = []
      for (let i = 0; i < 6; i++) {
        date.unshift(s.add(-1, 'days').format('MMM DD'))
      }
      date.push(moment().format('MMM DD'))
      this.data.forEach(x => {
        let lineid = document.getElementById('map_line_' + x.symbol)
        let data_list = x.price_7d.reverse()
        let xAxis = date
        let option = {
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              lineStyle: { width: 0 }
            },
            backgroundColor: '#121326',
            borderWidth: 0,
            textStyle: { color: '#fff' },
            formatter: (params, ticket, callback) => {
              let param = params[0]
              return `${param.axisValue}<br/>$${param.data}`;
            }
          },
          grid: { left: '5px', right: '0', bottom: '5px', top: '5px', containLabel: true },
          xAxis: {
            type: 'category', data: xAxis,
            axisLine: { show: false, }, //X line bottom
            axisLabel: { show: false }, // label
            axisTick: { show: false } //刻度
          },
          yAxis: {
            type: 'value',
            axisLabel: { show: false }, //x label
            splitLine: { show: false, }, //X
            axisTick: { show: false },//刻度
            min: value => value.min,
            max: value => value.max,
          },
          series: {
            data: [],
          }
        }
        if (data_list.length < 7) {
          let line = echarts.init(lineid)
          line.dispose()
          lineid.innerHTML = '--'
          lineid.style = ''
        } else {
          let line = echarts.init(lineid)
          line.clear()
          let lineoption = Object.assign({}, option)
          lineoption.series.data = data_list
          lineoption.series.type = 'line'
          let color = data_list[6] > data_list[5] ? '#3cd19b' : '#FF5147';
          lineoption.series.lineStyle = { width: 1, color: color }
          lineoption.series.symbol = 'circle'
          lineoption.series.itemStyle = { color: color }
          lineoption.series.symbolSize = 8
          lineoption.series.showAllSymbol = false
          option.series.emphasis = {
            color: color
          }
          line.setOption(lineoption)
        }
      })
    },
  }
}
