//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/guild-detail.less'
import { mapGetters } from 'vuex'
export default {
  layout: 'index',
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  data() {
    return {
      isOpen: false,
      loading: true,
      data: {},
      investorData: [{}, {}, {}, {}, {}],
      tableData: [],
      moneyList: [],
      game_ids: [],
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('investor.date'), key: 'date', width: 300 },
        { title: this.$t('investor.rounds'), key: 'rounds', render: (h, p) => h('span', this.utils.getRounds(p.rounds, this.lang) || '--') },
        { title: this.$t('investor.money'), key: 'money', className: 'chain', render: (h, p) => h('span', this.utils.moneyUnit(p.money, this.lang) || '--') },
        {
          title: this.$t('investor.company'), key: 'investor_name', className: 'chain'
          , render: (h, p) => {
            return p.investot_list.map(m => {
              return h('div', m.investor_name || '--')
            })
          }
        },
      ],
    };
  },
  mounted() {
    this.getMoney()
    this.getinvestor()
    this.getInfo()
  },
  methods: {
    getInfo() {
      let query_name = this.$route.params.id
      this.$http.get('/api/v1/meta/studio/info', { query_name }, ({ result }) => {
        if (result.socials) {
          result.socials = result.socials.filter(h => h.title != 'youtube')
          if (result.website) {
            result.socials.unshift({ type: 'blog', url: result.website })
          }
        }
        result.description = result.desc
        result.description_en = result.desc_en
        this.data = result
        this.game_ids = result.game_ids
        this.loading = false
        this.getData()
      })
    },
    getinvestor() {
      this.loading = true
      this.$http.get('/api/v1/meta/studio/list', { limit: 10, offset: 0 }, res => {
        this.investorData = res.result.records.map(m => {
          m.description = m.desc
          m.description_en = m.desc_en
          return m
        })
        this.loading = false
      })
    },
    getMoney() {
      let query_name = this.$route.params.id
      this.$http.get('/api/v1/meta/studio/investment', { limit: 50, offset: 0, query_name }, ({ result }) => {
        this.moneyList = result.records.map((m, i) => {
          m.no = i + 1
          return m
        })
      })
    },
    getData() {
      let { game_ids } = this
      if(!game_ids.length) return
      this.$http.get('/api/v1/meta/game/assign/list', { game_ids:JSON.stringify(game_ids), limit: 20, offset: 0 }, ({ result }) => {
        this.tableData = result.records.map((m, i) => {
          let { new_user, total_user  } = m.chain_users
          m.new_users = this.utils.changeUnit(new_user)
          m.total_users_v0 = this.utils.changeUnit(total_user)
          m.no = i + 1
          return m
        })
      })
    },
  },
};
