//
//
//
//
//
//
//
//
//
//
//
//
 
export default {
  data() {
    return {
      socials: [
        { url: "https://twitter.com/MyMetadata_io", type: "twitter", title: "twitter" },
        { url: "https://discord.gg/7BGJ7YCBz7", type: "discord", title: "discord" },
        { url: "https://t.me/my_metadata", type: "telegram", title: "telegram" }
      ],
    }
  },
  methods: {
    back() {
      window.history.back()
    },
    home() {
      window.location.href = '/'
    }
  }
}
