//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { AlertCircle } from "kui-icons";
export default {
  computed: {
    ...mapGetters(['lang'])
  },
  props: {
    data: Array,
    columns: Array,
    total: Number,
  },
  methods: {
    filterChange(a, b) {
      this.page = 1
      this.$emit('filter', b)
    },
    onHandlePage(page) {
      this.$emit('page', page)
    },
    onHandlePageSize({ value }) {
      this.size = value
      this.page = 1
      this.$emit('size', value)
    }
  },
  data() {
    return {
      AlertCircle,
      page: 1,
      size: 30,
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
    }
  }
}
