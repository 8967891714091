//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang', 'theme'])
  },
  data() {
    return {
      game: '',
      colors: ['orange', 'cyan', 'green', 'magenta', 'blue'],
      date: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 365, label: 'ALL' },
      ],
      date_users: 30,
      date_deposite: 30,
      date_withdraw: 30,
      date_spending: 30,
      date_GST: 30,
      date_GMT: 30,
      date_holders: 30,

      activeusers: 0,
      totalusers: 0,
      new_deposited_users: 0,
      daily_deposited_users: 0,
      new_withdraw_users: 0,
      daily_withdraw_users: 0,
      deposited_sol: 0,
      profit: 0,

      daily_incrate: 0,
      total_incrate: 0,
      new_deposited_incrate: 0,
      daily_deposited_incrate: 0,
      new_withdraw_incrate: 0,
      daily_withdraw_incrate: 0,
      deposited_incrate: 0,
      profit_incrate: 0,
      query_name: '',
      gridText: {
        'users': {
          'left': this.$t('subject.totalusers'),
          'right': this.$t('subject.activeusers'),
        },
        'deposite': {
          'left': this.$t('subject.new_deposited_users'),
          'right': this.$t('subject.daily_deposited_users'),
        },
        'withdraw': {
          'left': this.$t('subject.new_withdraw_users'),
          'right': this.$t('subject.daily_withdraw_users'),
        },
        'spending': {
          'left': this.$t('subject.deposited_sol'),
          'right': this.$t('subject.profit'),
        },
        'GST': {
          'left': this.$t('subject.deposited'),
          'mid': this.$t('subject.withdraw'),
          'right': this.$t('subject.net_deposited'),
        },
        'GMT': {
          'left': this.$t('subject.deposited'),
          'mid': this.$t('subject.withdraw'),
          'right': this.$t('subject.net_deposited'),
        },
        'holders': this.$t('subject.holders'),
      },
      symbol: {
        GST: {},
        GMT: {},
      },
      holderIndex: 0,
    }
  },
  mounted() {
    let game = this.$route.params.id
    this.game = game
    this.init()
  },
  methods: {
    init() {
      this.date_users = 30
      this.date_deposite = 30
      this.date_withdraw = 30
      this.initMap()
      this.getUsers()
      this.getDeposit()
      this.getWithdraw()
      this.getSpending()
      this.getSymbol('GST')
      this.getSymbol('GMT')
    },
    initMap() {
      let { h5, theme } = this
      let leftColor = theme == 'dark' ? '#717171' : '#999'
      let dashedColor = theme == 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#dddddd60'
      let _this = this
      let option = {
        graphic: [{
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: h5 ? 100 : 200, height: h5 ? 19 : 38 }
        },
        {
          rotation: Math.PI / 2,
          type: 'text', left: '5px', top: 'middle', style: { fill: leftColor, text: '', font: '14px "Inter"' }
        },
        {
          rotation: Math.PI / -2,
          type: 'text', right: '5px', top: 'middle', style: { fill: leftColor, text: '', font: '14px "Inter"' }
        }
        ],
        tooltip: {
          extraCssText: "z-index:1", trigger: 'axis',
          backgroundColor: '#121326', borderWidth: 0,
          textStyle: { color: '#fff' },
          axisPointer: { label: { backgroundColor: '#6a7985' } },
          formatter: (p) => {
            let str = `<p>${p[0].axisValueLabel}</p>`
            p.forEach((x) => {
              str += `<div class="k-row k-row-flex"><span class="k-col" style="margin-right:30px;flex:1">${x.marker}${x.seriesName}:</span><span class="k-col">${_this.utils.changeUnit(x.data)}</span></div>`
            })
            return str
          },
        },
        grid: { borderColor: 'transparent', /*backgroundColor: '#F9F9FB',*/ show: true, left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        xAxis: {
          type: 'category', data: [],
          axisLine: { show: false },
          axisLabel: { color: "#666", formatter: v => moment(new Date(v)).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
        legend: { data: [], icon: 'circle', bottom: 0, textStyle: { color: '#999', fontSize: 12 }, itemHeight: 8, },
        yAxis: [
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            alignTicks: true,
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor, //垂直于y轴的线的颜色
                width: 1,
                type: 'dashed' //'dotted'虚线 'solid'实线
              }
            },
            // min: value => value.min,
            // max: value => value.max,
          }
        ],
        series: [
          {
            name: '',
            yAxisIndex: 0,
            data: [], type: 'bar',
            barMaxWidth: 15,
            itemStyle: { color: '#39f' }
          },
          {
            name: '',
            yAxisIndex: 1,
            data: [], type: 'line',
          },
        ]
      };
      ['users', 'deposite', 'withdraw', 'spending', 'GST', 'GMT', 'holders'].forEach(id => {
        let chart = echarts.init(document.getElementById('map_' + id))
        this['char_' + id] = chart
        chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' });
        if (id == 'users' || id == "spending") {
          option.series = [
            {
              name: this.gridText[id]['left'],
              yAxisIndex: 0,
              data: [],
              type: 'bar',
              barMaxWidth: 15,
              itemStyle: { color: '#39f' }
            },
            {
              name: this.gridText[id]['right'],
              yAxisIndex: 1,
              data: [],
              type: 'line',
              itemStyle: { color: 'orange' }
            },
          ];
          ['left', 'right'].map((m, n) => {
            option.graphic[n + 1].style.text = this.gridText[id][m]
          });
          if (id == 'users') {
            let line = {
              type: 'value',
              axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
              alignTicks: true,

              splitLine: {
                show: true,
                lineStyle: {
                  color: dashedColor,
                  width: 0,
                  type: 'dashed'
                }
              },
              // min: value => value.min,
              // max: value => value.max,
            }
            option.yAxis[0].min = value => value.min
            option.yAxis[1] = Object.assign({}, line)
          } else {
            option.yAxis[0].min = null
          }
        } else if (id == 'deposite' || id == 'withdraw') {
          option.series = [
            {
              name: this.gridText[id]['left'],
              data: [],
              type: 'bar',
              stack: 'deposite',
              barMaxWidth: 15,
              itemStyle: { color: '#39f' }
            },
            {
              name: this.gridText[id]['right'],
              data: [],
              stack: 'deposite',
              type: 'bar',
              barMaxWidth: 15,
              itemStyle: { color: 'orange' }
            },
          ];
          option.graphic[1].style.text = ''
          option.graphic[2].style.text = ''
          // option.yAxis[0].min = value => 0
        } else if (id == 'GST' || id == 'GMT') {
          option.series = [
            {
              yAxisIndex: 0,
              name: this.gridText[id]['left'],
              data: [],
              type: 'bar',
              barMaxWidth: 15,
              itemStyle: { color: '#39f' }
            },
            {
              yAxisIndex: 0,
              name: this.gridText[id]['mid'],
              data: [],
              type: 'bar',
              barMaxWidth: 15,
              itemStyle: { color: 'orange' }
            },
            {
              yAxisIndex: 1,
              name: this.gridText[id]['right'],
              data: [],
              type: 'line',
              itemStyle: { color: 'cyan' }
            },
          ];
          option.yAxis = [
            {
              type: 'value',
              alignTicks: true,
              axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
              splitLine: {
                show: true,
                lineStyle: {
                  color: dashedColor, //垂直于y轴的线的颜色
                  width: 1,
                  type: 'dashed' //'dotted'虚线 'solid'实线
                }
              },
              // min: value => value.min,
              // max: value => value.max,
            },
            {
              type: 'value',
              alignTicks: true,
              axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
              splitLine: {
                show: true,
                lineStyle: {
                  color: dashedColor, //垂直于y轴的线的颜色
                  width: 0,
                  type: 'dashed' //'dotted'虚线 'solid'实线
                }
              },
              // min: value => value.min,
              // max: value => value.max,
            },
          ],
            option.graphic[1].style.text = ''
          option.graphic[2].style.text = ''
        } else if (id == 'holders') {
          option.series = [
            {
              name: 'GST' + this.gridText['holders'],
              data: [],
              type: 'line',
              itemStyle: { color: '#39f' }
            },
            {
              name: 'GMT' + this.gridText['holders'],
              data: [],
              type: 'line',
              itemStyle: { color: 'orange' }
            },

          ]
          option.graphic[1].style.text = 'GST' + this.$t('subject.holders')
          option.graphic[2].style.text = 'GMT' + this.$t('subject.holders')
        }
        // let line = {
        //   type: 'value',
        //   axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13 },
        //   splitLine: { show: false, },
        //   min: value => value.min,
        //   max: value => value.max,
        // }
        // option.yAxis[1] = Object.assign({}, line)
        // option.yAxis[2] = Object.assign({}, line, { axisLabel: { show: false } })
        // option.yAxis[3] = Object.assign({}, line, { axisLabel: { show: false } })
        chart.setOption(option)
        window.addEventListener('resize', () => {
          chart.resize()
        })
      })
    },
    dateChange(key) {
      try {
        let xAxis = this['xAxis_' + key]
        let datas = this['series_' + key]
        let datas1 = this['series1_' + key]
        let datas2 = this['series2_' + key]
        let day = this['date_' + key] * -1
        let option = {
          xAxis: { data: xAxis.slice(day) },
          series: [
            { data: datas.slice(day) },
            { data: datas1.slice(day) },
          ]
        }
        if (key == 'GST'  || key == 'GMT') {
          option.series.push({ data: datas2.slice(day) })

        }
        this['char_' + key].setOption(option)
      } catch (e) { console.log(e) }
    },
    renderMap(id, xAxis = [], series = [], series1 = [], series2 = []) {
      try {
        let chart = this['char_' + id]
        chart.hideLoading()
        let count = 365
        let day = 30
        if (xAxis.length < count) {
          let t = count - xAxis.length
          let j = xAxis.slice(0, 1)[0] || Date.now();
          let f = moment(new Date(j)).subtract(t, 'days')
          let nx = []
          for (let i = 0; i < t; i++) {
            nx.push(f.format('YYYY/MM/DD'))
            f.add(1, 'days')
          }
          xAxis = [].concat(nx, xAxis)
        }
        //series
        if (series.length < count) {
          let ns = new Array(count - series.length).fill('0')
          series = [].concat(ns, series)
          series1 = [].concat(ns, series1)
          series2 = [].concat(ns, series2)
        }
        xAxis = xAxis.map(x => x.replace(/-/g, '/'))

        let option = {
          xAxis: { data: xAxis.slice(-day) },
          series: [{
            data: series.slice(-day)
          }, {
            data: series1.slice(-day)
          },]
        }
        if (id == 'users' || id == 'spending' || id == 'holders') {
          // console.log(id);
          option.series[0].yAxisIndex = 0
          option.series[1].yAxisIndex = 1
        } else if (id == 'GST' || id == 'GMT') {
          option = {
            xAxis: { data: xAxis.slice(-day) },
            series: [{
              data: series.slice(-day)
            }, {
              data: series1.slice(-day)
            }, {
              data: series2.slice(-day)
            },]
          }
          // option.series[0].yAxisIndex = 0
          // option.series[1].yAxisIndex = 0
          // option.series[2].yAxisIndex = 1
        }
        // }
        this['xAxis_' + id] = xAxis
        this['series_' + id] = series
        this['series1_' + id] = series1
        this['series2_' + id] = series2
        chart.setOption(option)
      } catch (e) { console.log(e) }
    },
    getUsers() {
      this.$http.get('/api/v1/meta/game/dune/activeUsers', { query_name: this.game, days: 365 }, res => {
        let { date_list = [], daily_user_list = [], total_user_list = [], daily_users = 0, total_users = 0, daily_incrate = 0, total_incrate = 0 } = res.result || {}
        this.daily_incrate = daily_incrate
        this.total_incrate = total_incrate
        date_list = date_list.reverse()
        daily_user_list = daily_user_list.reverse()
        total_user_list = total_user_list.reverse()
        this.renderMap('users', date_list, total_user_list, daily_user_list)
        this.activeusers = this.utils.changeUnit(daily_users)
        this.totalusers = this.utils.changeUnit(total_users)
      })
    },
    getDeposit() {
      this.$http.get('/api/v1/meta/game/dune/depositedUsers', { query_name: this.game, days: 365 }, res => {
        let { date_list = [], new_deposited_list = [], daily_deposited_list = [], daily_deposited_users = 0, new_deposited_users = 0, new_deposited_incrate, daily_deposited_incrate } = res.result || {}
        date_list = date_list.reverse()
        new_deposited_list = new_deposited_list.reverse()
        daily_deposited_list = daily_deposited_list.reverse()
        this.renderMap('deposite', date_list, new_deposited_list, daily_deposited_list)
        this.daily_deposited_users = this.utils.changeUnit(daily_deposited_users)
        this.new_deposited_users = this.utils.changeUnit(new_deposited_users)
        this.new_deposited_incrate = new_deposited_incrate
        this.daily_deposited_incrate = daily_deposited_incrate

      })
    },
    getWithdraw() {
      this.$http.get('/api/v1/meta/game/dune/withdrawUsers', { query_name: this.game, days: 365 }, res => {
        let { date_list = [], new_withdraw_list = [], daily_withdraw_list = [], daily_withdraw_users = 0, new_withdraw_users = 0, daily_withdraw_incrate, new_withdraw_incrate } = res.result || {}
        date_list = date_list.reverse()
        new_withdraw_list = new_withdraw_list.reverse()
        daily_withdraw_list = daily_withdraw_list.reverse()
        this.renderMap('withdraw', date_list, new_withdraw_list, daily_withdraw_list)
        this.new_withdraw_users = this.utils.changeUnit(new_withdraw_users)
        this.daily_withdraw_users = this.utils.changeUnit(daily_withdraw_users)
        this.new_withdraw_incrate = new_withdraw_incrate
        this.daily_withdraw_incrate = daily_withdraw_incrate
      })
    },
    getSpending() {
      this.$http.get('/api/v1/meta/game/dune/spending', { query_name: this.game, days: 365 }, res => {
        let { date_list = [], deposited_sol_list = [], profit_list = [], deposited_sol = 0, profit = 0, deposited_incrate, profit_incrate } = res.result || {}
        profit_list = profit_list.map(m => {
          return m.toFixed(2)
        })
        deposited_sol_list = deposited_sol_list.map(m => {
          return m.toFixed(2)
        })
        date_list = date_list.reverse()
        deposited_sol_list = deposited_sol_list.reverse()
        profit_list = profit_list.reverse()
        this.renderMap('spending', date_list, deposited_sol_list, profit_list)
        this.profit = this.utils.changeUnit(profit)
        this.deposited_sol = this.utils.changeUnit(deposited_sol)
        this.profit_incrate = profit_incrate
        this.deposited_incrate = deposited_incrate
      })
    },
    // GST  GMT
    getSymbol(symbol) {
      this.$http.get('/api/v1/meta/game/dune/token', { query_name: this.game, days: 365, symbol }, ({ result }) => {
        this.holderIndex++
        result.date_list = result.date_list.reverse()
        result.deposited_list = result.deposited_list.reverse()
        result.withdraw_list = result.withdraw_list.reverse()
        result.net_deposited_list = result.net_deposited_list.reverse()
        result.net_deposited_usdc = result.net_deposited_usdc.toFixed(2)
        this.socialUsers = result
        this.symbol[symbol] = result
        this.renderMap(symbol, result.date_list, result.deposited_list, result.withdraw_list, result.net_deposited_list)
        if (this.holderIndex == 2) {
          this.$nextTick(() => {
            this.renderMap('holders', this.symbol.GST.date_list, this.symbol.GST.holders_list.reverse(), this.symbol.GMT.holders_list.reverse(),)
          })
        }
      })
    },
  }
}
