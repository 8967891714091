//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { ChevronDown } from "kui-icons";
export default {
  computed: {
    ...mapGetters(['lang'])
  },
  props: {
    data: Array,
    columns: Array,
    total: Number,
  },
  data() {
    return {
      ChevronDown
    }
  },
  methods: {
    dayChange({ value }) {
      this.$emit('day', value)
    },
    filterChange(a, b) {
      this.$emit('filter', b)
    },
    seeMore() {
      this.$emit('com.more')
    }
  },
}
