//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './index.less'
import {
  LogoFacebook, LogoTwitter, LogoDiscord, LogoYoutube, Globe,
  LogoInstagram, LogoTiktok, LogoMedium, LogoTelegram, LogoReddit, LogoGithub,LogoGitbook
} from 'kui-icons'
export default {
  props: {
    data: Array,
  },
  data() {
    return {
      icons: {
        facebook: LogoFacebook,
        twitter: LogoTwitter,
        discord: LogoDiscord,
        youtube: LogoYoutube,
        instagram: LogoInstagram,
        tiktok: LogoTiktok,
        medium: LogoMedium,
        telegram: LogoTelegram,
        reddit: LogoReddit,
        github: LogoGithub,
        blog: Globe,
        gitbook:LogoGitbook
      },
      socialList: [],
    }
  },
  watch: {
    data: {
      handler(v) {
        this.socialList = (v || []).map(m => {
          if (!m.type && m.website) {
            let obj = {
              title: "blog",
              type: "blog",
              url: this.utils.replaceUrl(m['website'])
            }
            m = obj
          }
          return m
        })
      },
      immediate: true,
    },
  },
};
