//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CaretDown, ChevronDown } from "kui-icons";
import moment from 'moment'
import { mapGetters } from 'vuex'
export default {

  computed: {
    ...mapGetters(['h5', 'theme'])
  },
  data() {
    return {
      ChevronDown,
      tokenItem: {},
      showDrop: false,
      legendIndex: '',
      whale_typeColors: [],
      asset_sectionColors: [],
      token_countColors: [],
      whaleList: [],
      columns: [
        { title: this.$t('whale.col_whale_add'), key: 'holder_address', },
        { title: this.$t('whale.col_assets'), key: 'last_token_sum', render: (h, p) => h('span', this.utils.changeUnit(p.last_token_sum,'',1)), width: this.h5 ? 150 : 100, },
        { title: this.$t('whale.col_precent'), key: 'token_sum_ratio', width: '' },
        { title: this.$t('whale.col_type'), key: 'holder_type', width: this.h5 ? 150 : '', render: (h, p) => h('span', this.utils.titleCase(p.holder_type)) },
        { title: this.$t('whale.col_change'), key: 'token_sum_cp_ratio', width: this.h5 ? 80 : '' },
      ],
      loading: false,
      CaretDown,
      game: 'alien-worlds-1',
      whaleData: [{}],
      chains: [],
      select_address: '',
      chain: '',
      address: '',
      page: 1,
      size: 5,
      total: 0,
      whale_typeIndex: '',
      asset_sectionIndex: '',
      token_countIndex: '',
      data: {},
    }
  },
  mounted() {
    let game = this.$route.params.id
    game = game == 'game' || !game ? 'alien-worlds-1' : game;
    this.game = game
    this.initMap()
    this.getWhale()
  },

  methods: {
    initMap() {
      let option = {
        graphic: [{
          right: this.h5 ? '43%' : '50%',
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: this.h5 ? 100 : 150, height: this.h5 ? 19 : 28.5 }
        }],
        grid: { borderColor: 'transparent', /*backgroundColor: '#F9F9FB',*/ show: true, left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        legend: { data: [] },
      };
      ['whale_type', 'asset_section', 'token_count'].forEach(id => {
        let ele = document.getElementById('map_' + id)
        if (!ele) return;
        this['char_' + id] = chart
        let chart = echarts.init(ele)
        chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' })
        this['char_' + id] = chart
        chart.setOption(option)
        window.addEventListener('resize', () => {
          chart.resize()
        })
      })
    },

    renderChart(id, series = []) {
      let chart = this['char_' + id]
      chart.hideLoading()
      let option = {}
      option.tooltip = {
        confine:true,
        trigger: 'item'
      }
      if (id == 'asset_section' || id == 'token_count') {
        option.formatter = (p) => {
          let x = p.data, str = ''
          let assetTooltip = this.$t('whale.pie_assets_title1') + x.name +' '+ this.tokenItem.value+' ' + this.$t('whale.pie_assets_title2')
          let tokenTooltip = this.$t('whale.pie_token_title1') + x.name + this.$t('whale.pie_token_title2')
          str = `<div  style="">
                    <div  style=";width:100%;">
                      ${p.marker}${id == 'asset_section' ? assetTooltip : tokenTooltip}
                    </div>
                    <div style="display:flex;justify-content:space-between;">
                       <span>${this.utils.changeUnit(x.value)}</span>
                      <span style="font-size:14px;color:${p.color};margin-left:3px">${x.precent || '--'}</span>
                    </div>
            </div>`
          return str
        }
      }
      if (id == 'token_count') {
        series = series.splice(0, 15)
      }
      option.series = [{
        name: '',
        type: 'pie',
        radius: ['55%', '90%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        data: series
      }]
      let colors = chart.getModel().option.color
      this[id + 'Colors'] = series.map((m, n) => {
        m.color = colors[n % 9]
        return m
      })
      chart.on('mouseover', (item) => {
        this[id + 'Index'] = item.dataIndex
      })
      chart.on('mouseout', (item) => {
        this[id + 'Index'] = ''
      })
      chart.setOption(option)
    },
    legendMouseOver(id, i) {
      this['char_' + id].dispatchAction({
        type: 'highlight',
        dataIndex: i
      });
      this['char_' + id].dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: i,
      });
    },
    legendMouseOut(id, i) {
      this['char_' + id].dispatchAction({
        type: 'downplay',
        dataIndex: i
      });
      this['char_' + id].dispatchAction({
        type: "hideTip",
      });
    },

    changeAdd({ value }) {
      this.data = this.whaleData.filter(m => m.symbol == value)[0]
      this.fresh(this.data)
    },
    initPrecent(value) {
      let val = ''
      if (value == 0.1) {
        val = '0-10%'
      } else if (value == 0.1) {
        val = '10-20%'
      } else if (value == 0.2) {
        val = '10-20%'
      } else if (value == 0.3) {
        val = '20-30%'
      } else if (value == 0.5) {
        val = '30-50%'
      } else if (value == 0.8) {
        val = '50-80%'
      } else if (value == 1.0) {
        val = '80-100%'
      }
      return val
    },
    fresh(data) {
      let { token_asset_section, token_count_section, whale_type_section } = data
      let asset_section_series = [], count_section_series = [], type_section_series = []
      let asset_section = Object.keys(token_asset_section)
      let assetValues = Object.values(token_asset_section)
      let assetTotal = assetValues.reduce((a, b) => a + b, 0)
      let remain = 0
      asset_section.map((name, i) => {
        let c = parseFloat(this.utils.changeUnit1(assetValues[i] * 100 / assetTotal))
        if (i == asset_section.length - 1) {
          c = parseFloat(this.utils.formatNum(100, remain, '-', 2))
        } else {
          c = c < 1 && c > 0 ? (c.toFixed(2) - 0) : c
          remain += c
        }
        asset_section_series.push({ value: token_asset_section[name], name: this.initPrecent(name), precent: c + '%' })
      })
      let count_section = Object.keys(token_count_section)
      let tokenValues = Object.values(token_count_section)
      let countTotal = tokenValues.reduce((a, b) => a + b, 0)
      let tokenRemain = 0
      count_section_series = count_section.map((name, i) => {
        let c = parseFloat(this.utils.changeUnit1(tokenValues[i] * 100 / countTotal))
        if (i == count_section.length - 1) {
          c = parseFloat(this.utils.formatNum(100, tokenRemain, '-', 2))
        } else {
          c = c < 1 && c > 0 ? (c.toFixed(2) - 0) : c
          tokenRemain += c
        }
        let obj = { value: token_count_section[name], name, precent: c + '%' }
        return obj
      }).sort(
        (a, b) => {
          return b.value - a.value;
        });
      let type_section = Object.keys(whale_type_section)
      type_section.map(name => {
        type_section_series.push({ value: whale_type_section[name], name: this.utils.titleCase(name) })
      })
      this.renderChart('whale_type', type_section_series)
      this.renderChart('asset_section', asset_section_series)
      this.renderChart('token_count', count_section_series)
      this.chain = data.chain
      this.address = data.address
      this.getWhaleList()
    },
    onHandlePage(page) {
      this.page = page;
      this.getWhaleList()
    },
    getWhaleList() {
      let { size, page } = this
      let game_id = localStorage.getItem('game_id')
      this.$http.get('/v2/game/whale/list', { game_id, noEncrypt: true, chain: this.chain, address: this.address, limit: size, offset: (page - 1) * size, }, (result) => {
        this.loading = false
        this.total = result.count - 0
        this.whaleList = result.list.map(m => {
          m.token_sum_ratio = m.token_sum_ratio ? (m.token_sum_ratio.toFixed(2) + '%') : '--'
          return m
        })
      })
    },
    freshChain(chain) {
      if (chain == 'binance-smart-chain') chain = 'BSC'
      else if (chain == 'ethereum') chain = 'ETH'
      else if (chain == 'okexchain') chain = 'OEC'
      else {
        chain = chain.toUpperCase()
      }
      return chain
    },

    chooseToken(item) {
      this.tokenItem = item
      this.data = this.whaleData.filter(m => m.symbol == item.value)[0]
      this.fresh(this.data)
    },
    getWhale() {
      let game_id = localStorage.getItem('game_id')
      this.chains = []
      this.$http.get('/v2/game/whale/stats', { game_id, noEncrypt: true }, (result) => {
        if (result && result.length) {
          this.whaleData = result
          result.map(m => {
            m.updated_at = moment(m.updated_at).format('YYYY-MM-DD HH:mm')
            m.newchain = this.freshChain(m.chain)
            let value = m.symbol
            this.chains.push({ value, logo: m.logo, newchain: m.newchain })
            this.select_address = m.symbol
            this.tokenItem = { value: result[0].symbol, logo: result[0].logo, newchain: m.newchain }
          })
          this.data = result[0]
          this.fresh(result[0])
        }
      })
    },

  }
}
