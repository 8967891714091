//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';
export default {
  components: { Swiper, SwiperSlide },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      },
      ido: [],
      whitelist: [],
    }
  },
  mounted() {
    this.getIdo()
    this.getIdo('whitelist')
  },
  methods: {
    getIdo(type = 'ido') {
      this.$http.get('/api/v1/meta/ido/list', {
        limit: 4, offset: 0,
        ido_type: type, utc: this.$i18n.locale == 'en' ? '' : '8'
      }, ({ result }) => {
        this[type] = result.records.map(m => {
          m.protocols = m.protocols.slice(0, 1)
          m.social = m.social.filter(h => h.title == 'telegram' || h.title == 'twitter')
          return m
        })

      })
    }
  }
}
