import { render, staticRenderFns } from "./item.vue?vue&type=template&id=55f51386&"
import script from "./item.vue?vue&type=script&lang=js&"
export * from "./item.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Social: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/social/index.vue').default,Tags: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/tags/index.vue').default,KolModal: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/kolModal/index.vue').default})
