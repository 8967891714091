//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/submit.less'
import { mapGetters } from 'vuex'
import { AddCircle, RemoveCircle } from "kui-icons";

export default {
  layout: 'index',
  computed: {
    ...mapGetters(['lang', 'h5'])
  },
  data() {
    return {
      AddCircle, RemoveCircle,
      form: [
        { title: this.$t('submit.name'), key: 'name', },
        { title: this.$t('submit.desc'), key: 'short_desc', },
        { title: this.$t('submit.website'), key: 'website', },
        { title: this.$t('submit.twitter'), key: 'twitter', },
        { title: this.$t('submit.telegram'), key: 'telegram', },
        { title: this.$t('submit.discord'), key: 'discord', },
        { title: this.$t('submit.contact'), key: 'contact_telegram', },
        { title: this.$t('submit.contracts'), key: 'contract', contract: [{ chain: '', address: '' }] },
      ],
      formData: {
        name: '',
        short_desc: '',
        website: '',
        twitter: '',
        telegram: '',
        discord: '',
        contact_telegram: '',
        contract: {},
      },
      chains: [],
      showSubmit: false
    }
  },
  mounted() {
    this.getChain()
  },
  methods: {
    minus(index) {
      this.form[7].contract.splice(index, 1)
    },
    add() {
      this.form[7].contract.push({ chain: '', address: '' })
    },
    getChain() {
      this.$http.get('/api/v1/meta/chain/list', { limit: 50, offset: 0 }, ({ result }) => {
        this.chains = this.chains.concat(result.records)
      })
    },
    submitGame() {
      let data = Object.assign(this.formData, {})
      let contract = []
      this.form[7].contract.map(m => {
        let item = {}
        item[m['chain']] = m['address']
        contract.push(item)
      })
      let showError = false
      // debugger
      for (let i = 0; i < this.form.length; i++) {
        let item = this.form[i]
        if (!this.formData[item.key]) {
          return this.$Message.error((this.lang == 'en' ? 'Please enter the ' : ' 请输入') + item.title)
        }
        if (item.key == 'contract') {
          showError = item.contract.every(m => { return m.chain && m.address })
        }
      }
      if (!showError) {
        return this.$Message.error(this.$t('submit.contract'))
      }
      data.contract = contract
      this.$http.post('/api/v1/meta/game/apply', data, ({ result }) => {
        this.showSubmit = true
        this.formData = {
          name: '',
          short_desc: '',
          website: '',
          twitter: '',
          telegram: '',
          discord: '',
          contact_telegram: '',
          contract: {},
        }
        this.form[7].contract = [{ chain: '', address: '' }]
      }, err => {
        this.$Message.error(err.message)
      })
    },
  }
}
