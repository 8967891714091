//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import { ChevronForward } from 'kui-icons'
export default {
  computed: {
    ...mapGetters(['h5', 'lang', 'theme'])
  },
  layout: 'game',
  data() {
    return {
      ChevronForward,
      engagementColumns: [
        { title: '#', key: 'no', align: 'right', fixed: 'left', width: 60 },
        { title: this.$t('social.col_name'), key: 'kol_info', width: 220, fixed: 'left' },
        { title: this.$t('social.col_engagement'), key: 'total_engagements', render: (h, p) => h('span', this.utils.changeUnit(p.total_engagements)) },
        { title: this.$t('social.col_tweet'), key: 'tweets', render: (h, p) => h('span', this.utils.changeUnit(p.tweets)) },
        { title: this.$t('social.col_like'), key: 'total_likes', render: (h, p) => h('span', this.utils.changeUnit(p.total_likes)) },
        { title: this.$t('social.col_reply'), key: 'total_raplies', render: (h, p) => h('span', this.utils.changeUnit(p.total_raplies)) },
        { title: this.$t('social.col_retweet'), key: 'total_retweets', render: (h, p) => h('span', this.utils.changeUnit(p.total_retweets)) },
        { title: this.$t('social.col_quote'), key: 'total_quotes', render: (h, p) => h('span', this.utils.changeUnit(p.total_quotes)) },
      ],
      followerColumns: [
        { title: '#', key: 'no', align: 'right', width: 40 },
        { title: this.$t('social.col_name'), key: 'game_name' },
        { title: this.$t('social.col_follower1'), key: 'followers_count', render: (h, p) => h('span', this.utils.changeUnit(p.followers_count)) },
      ],
      follower: [],
      engagement: [],
      colors: ['orange', 'cyan', 'green', 'magenta', 'blue'],
      date: [
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
        { value: 365, label: 'ALL' },
      ],
      date_kol: [
        { value: 1, label: '24H' },
        { value: 7, label: '7D' },
        { value: 30, label: '30D' },
      ],
      date_socialUsers: 30,
      date_twitterHot: 30,
      date_kol_top: 7,
      gridText: {
        'twitterHot': {
          'left': this.$t('subject.social_volume'),
          'mid': this.$t('subject.social_engagement'),
          'right': this.$t('subject.mentions_kol'),
        },
        'socialUsers': {
          'left': this.$t('subject.twitter_followers'),
          'mid': this.$t('subject.discord_members'),
          'right': this.$t('subject.telegram_members'),
        },
      },
      socialUsers: {},
      twitterData: {},
      loading1: true,
      loading2: true,
      current: 0,
      list: [],
      _list: [],
      WHALE_list: [],
      PLATFORM_list: [],
      EXCHANGE_list: [],
      BUILDER_list: [],
      VC_list: [],
      PROJECT_list: [],
      NFT_list: [],
      descShow: false,

      tabs: [
        { name: this.$t('com.all'), value: '' },
        { name: 'WHALE', value: 'WHALE' },
        { name: 'PLATFORM', value: 'PLATFORM' },
        { name: 'EXCHANGE', value: 'EXCHANGE' },
        { name: 'BUILDER', value: 'BUILDER' },
        { name: 'VC', value: 'VC' },
        { name: 'PROJECT', value: 'PROJECT' },
        { name: 'NFT', value: 'NFT' },
      ],
    }
  },
  mounted() {
    let game = this.$route.params.id
    this.game = game
    this.init()
  },
  methods: {

    init() {
      this.date_users = 30
      this.date_deposite = 30
      this.date_withdraw = 30
      this.initMap()
      this.getTwitterHot()
      this.getSocialUsers()
      this.getTweets()
      this.getFollowers()
    },
    initMap() {
      let { h5, theme } = this
      let leftColor = theme == 'dark' ? '#717171' : '#999'
      let dashedColor = theme == 'dark' ? 'rgba(255, 255, 255, 0.05)' : '#dddddd60'
      let option = {
        graphic: [{
          type: 'image', right: 'center', top: 'middle',
          style: { image: 'https://res.mymetadata.io/img/web/logo-wave.png?!', width: h5 ? 100 : 200, height: h5 ? 19 : 38 }
        },
        {
          rotation: Math.PI / 2,
          type: 'text', left: '5px', top: 'middle', style: { fill: leftColor, text: '', font: '14px "Inter"' }
        },
        {
          rotation: Math.PI / -2,
          type: 'text', right: '5px', top: 'middle', style: { fill: leftColor, text: '', font: '14px "Inter"' }
        }
        ],
        tooltip: {
          extraCssText: "z-index:1", trigger: 'axis',
          backgroundColor: '#121326', borderWidth: 0,
          textStyle: { color: '#fff' },
          axisPointer: { type: 'cross', label: { backgroundColor: '#6a7985' } },
          formatter: (p) => {
            let str = `<p>${p[0].axisValueLabel}</p>`

            p.forEach((x) => {
              let bfh = x.seriesName == this.gridText.growth_rate ? '%' : ''
              str += `<div class="k-row k-row-flex"><span class="k-col" style="margin-right:30px;flex:1">${x.marker}${x.seriesName}:</span><span class="k-col">${x.data}${bfh}</span></div>`
            })
            return str
          },
        },
        grid: { borderColor: 'transparent', /*backgroundColor: '#F9F9FB',*/ show: true, left: '30px', right: '30px', bottom: '6%', top: '6%', containLabel: true },
        xAxis: {
          type: 'category', data: [],
          axisLine: { show: false },
          axisLabel: { color: "#666", formatter: v => moment(new Date(v)).format('MMM DD'), fontFamily: 'Inter', fontSize: 13 },
          axisTick: { show: false }
        },
        legend: { data: [], icon: 'circle', bottom: 0, textStyle: { color: '#999', fontSize: 12 }, itemHeight: 8, },
        yAxis: [
          {
            type: 'value',
            axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13, formatter: v => this.utils.changeUnit1(v) },
            splitLine: {
              show: true,
              lineStyle: {
                color: dashedColor, //垂直于y轴的线的颜色
                width: 1,
                type: 'dashed' //'dotted'虚线 'solid'实线
              }
            },
            // min: value => value.min,
            // max: value => value.max,
          },
        ],
        series: [
          {
            name: '',
            data: [],
            type: 'line',
            itemStyle: { color: '#39f' }
          },
          {
            name: '',
            data: [],
            type: 'line',
            itemStyle: { color: 'orange' }
          },
          {
            name: '',
            data: [],
            type: 'line',
            itemStyle: { color: 'cyan' }
          },
        ]
      };
      ['socialUsers', 'twitterHot'].forEach(id => {
        let chart = echarts.init(document.getElementById('map_' + id))
        this['char_' + id] = chart
        chart.showLoading({ text: '', spinnerRadius: 15, maskColor: 'tansparent' });
        ['left', 'mid', 'right'].map((m, n) => {
          option.series[n].name = this.gridText[id][m]
        })
        let line = {
          type: 'value',
          axisLabel: { color: "#666", fontFamily: 'Inter', fontSize: 13 },
          splitLine: {
            show: false,
            lineStyle: {
              color: dashedColor, //垂直于y轴的线的颜色
              width: 0,
              type: 'dashed' //'dotted'虚线 'solid'实线
            }
          },
          // min: value => value.min,
          // max: value => value.max,
        }
        option.yAxis[1] = Object.assign({}, line)
        option.yAxis[2] = Object.assign({}, line, { axisLabel: { show: false } })
        chart.setOption(option)
        window.addEventListener('resize', () => {
          chart.resize()
        })
      })
    },
    dateChange(key) {
      if (key == 'kol_top') {
        this.getTweets()
        return;
      }
      try {
        let xAxis = this['xAxis_' + key]
        let datas = this['series_' + key]
        let datas1 = this['series1_' + key]
        let datas2 = this['series2_' + key]
        let day = this['date_' + key] * -1
        let option = {
          xAxis: { data: xAxis.slice(day) },
          series: [
            { data: datas.slice(day) },
            { data: datas1.slice(day) },
            { data: datas2.slice(day) },
          ]
        }
        this['char_' + key].setOption(option)
      } catch (e) { console.log(e) }
    },
    changeTab(i) {
      if (i == this.current) return
      this.current = i
      this.content_type = this.tabs[i].value
      this.list = this[this.content_type + '_list']
    },
    toTT({ tid }) {
      open(`https://twitter.com/${tid}`)
    },
    seeAll() {
      this.current = 0
      this.$http.get('/api/v1/meta/game/kol/followers', { query_name: this.game, is_all: 1 }, ({ result }) => {
        this.tabs.map(h => {
          h.name = h.value || this.$t('com.all')
          let list = []
          result.records.map(m => {
            if ((m.content_type || []).findIndex(n => h.value == n) > -1) {
              list.push(m)
            }
          })
          this[h.value + '_list'] = list
          h.name = h.name + '(' + (h.value ? this[h.value + '_list'].length : result.records.length) + ')'
        })
        this.list = result.records
        this._list = result.records
        this.descShow = true
      })
    },
    getFollowers() {
      this.loading2 = true
      this.$http.get('/api/v1/meta/game/kol/followers', { query_name: this.game }, res => {
        this.loading2 = false
        this.follower = res.result.records.map((m, i) => {
          // m.avatar = m.avatar.replace('https://pbs.twimg.com/', 'https/res.mymetadata.io/')
          m.no = i + 1
          return m
        })
      })
    },
    getTweets() {
      this.loading1 = true
      this.$http.get('/api/v1/meta/game/kol/tweets', { query_name: this.game, days: this.date_kol_top }, res => {
        this.loading1 = false
        this.engagement = res.result.records.map((m, i) => {
          m.no = i + 1
          return m
        })
      })
    },
    renderMap(id, xAxis = [], series = [], series1 = [], series2 = []) {
      try {
        let chart = this['char_' + id]
        chart.hideLoading()
        let count = 365
        let day = 30
        if (xAxis.length < count) {
          let t = count - xAxis.length
          let j = xAxis.slice(0, 1)[0] || Date.now();
          let f = moment(new Date(j)).subtract(t, 'days')
          let nx = []
          for (let i = 0; i < t; i++) {
            nx.push(f.format('YYYY/MM/DD'))
            f.add(1, 'days')
          }
          xAxis = [].concat(nx, xAxis)
        }
        //series
        if (series.length < count) {
          let ns = new Array(count - series.length).fill('0')
          series = [].concat(ns, series)
          series1 = [].concat(ns, series1)
          series2 = [].concat(ns, series2)
        }
        xAxis = xAxis.map(x => x.replace(/-/g, '/'))

        let option = {
          xAxis: { data: xAxis.slice(-day) },
          series: [{
            data: series.slice(-day)
          }, {
            data: series1.slice(-day)
          }, {
            data: series2.slice(-day)
          },]
        }
        // }
        this['xAxis_' + id] = xAxis
        this['series_' + id] = series
        this['series1_' + id] = series1
        this['series2_' + id] = series2
        chart.setOption(option)
      } catch (e) { console.log(e) }
    },
    getSocialUsers() {
      this.$http.get('/api/v1/meta/game/dune/socialUsers', { query_name: this.game, days: 365 }, ({ result }) => {
        result.date_list = result.date_list.reverse()
        result.twitter_list = result.twitter_list.reverse()
        result.discord_list = result.discord_list.reverse()
        result.telegram_list = result.telegram_list.reverse()
        this.socialUsers = result
        this.renderMap('socialUsers', result.date_list, result.twitter_list, result.discord_list, result.telegram_list)
      })
    },
    getTwitterHot() {
      this.$http.get('/api/v1/meta/game/dune/twitterHot', { query_name: this.game, days: 365 }, ({ result }) => {
        result.date_list = result.date_list.reverse()
        result.mentions_kol_list = result.mentions_kol_list.reverse()
        result.social_engagement_list = result.social_engagement_list.reverse()
        result.social_volume_list = result.social_volume_list.reverse()
        this.twitterData = result
        this.renderMap('twitterHot', result.date_list, result.social_volume_list, result.social_engagement_list, result.mentions_kol_list)
      })
    },

  }
}
