
import './index.less'
import { mapGetters } from 'vuex'
import { ChevronDown } from 'kui-icons'
export default {
  props: {
    limit: { type: Number, default: 10 },
    is_showCol: Boolean,
    datas: [Array],
    totals: Number,
    showViewall: Boolean,
    showPage: Boolean,
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  watch: {
    datas(v) {
      this.guildData = v
    },
    total(v) {
      this.total = v
    }
  },
  data() {
    return {
      width: 1317,
      page: 1,
      total: this.totals || 0,
      current: 0,
      size: this.limit,
      loading: true,
      showCol: this.is_showCol || false,
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
      guildData: this.datas || [],
      columns: [
        { title: '#', key: 'no', align: 'right', fixed: 'left', width: 60 },
        { title: this.$t('investor.col_name'), key: 'name', width: 200, fixed: 'left' },
        { title: this.$t('investor.col_investment_count'), key: 'investment_count', render: (h, p) => h('span', this.utils.changeUnit(p.investment_count)), className: 'chain', },
        { title: this.$t('investor.col_market_cap'), key: 'market_cap', render: (h, p) => h('span', this.utils.changeUnit(p.market_cap)), className: 'chain' },
        { title: this.$t('investor.col_project_name'), key: 'project_name', className: 'chain', render: (h, p) => h('span', p.project_name || '--'), },

      ],
      columns_more: [
        { title: '#', key: 'no', align: 'right', fixed: 'left', width: 60 },
        { title: this.$t('investor.col_name'), key: 'name', fixed: 'left', width: 200 },
        { title: this.$t('investor.col_investment_count'), key: 'investment_count', render: (h, p) => h('span', this.utils.changeUnit(p.investment_count)), className: 'chain', },
        { title: this.$t('investor.col_market_cap'), key: 'market_cap', render: (h, p) => h('span', this.utils.changeUnit(p.market_cap)), className: 'chain' },
        { title: this.$t('investor.col_project_name'), key: 'project_name', className: 'chain', render: (h, p) => h('span', p.project_name || '--'), },
        { title: '', key: 'money', width: 120, className: 'chain', render: (h, p) => h('span', this.utils.moneyUnit(p.money, this.lang) || '--'), },
        { title: '', key: 'rounds', width: 120, render: (h, p) => h('span', this.utils.getRounds(p.rounds, this.lang) || '--'), }
      ],
      guildSearch: [],
    };
  },

  mounted() {
    if (this.h5) {
      this.width = 830
    }
    this.getGuilds()

  },
  methods: {
    filterChange({ key, order }) {
      this.guildSearch = order ? [key + (order == 'asc' ? '_1' : '_2')] : []
      this.getGuilds()
    },

    onHandlePageSize({ value }) {
      this.page = 1
      this.size = value;
      this.getGuilds()
    },
    onHandlePage(page) {
      this.page = page;
      this.getGuilds()
    },
    seeMore() {
      if (this.showPage) {
        this.page++
        this.getGuilds()
      } else {
        window.location.href = '/rank/guilds'
      }
    },
    getGuilds() {
      let { guildSearch, size } = this
      let order_list = JSON.stringify(guildSearch)
      this.loading = true
      this.$http.get('/api/v1/meta/investor/rank', { limit: size, offset: (this.page - 1) * size, order_list }, ({ result }) => {
        this.total = result.count
        this.loading = false
        let guildData = result.records.map((m, i) => {
          m.no = i + ((this.page - 1) * size) + 1
          m = { ...m, ...m.investment_info }
          return m
        })
        this.showCol = guildData.some(m => { return m.project_name && m.money && m.rounds })
        if (this.h5) {
          this.guildData = this.guildData.concat(guildData)
        } else {
          this.guildData = guildData
        }
      })
    },
  },
  render() {
    let { h5, guildData, columns, columns_more, size, loading,
      page, total, pageRanges, showCol, width, showPage, showViewall } = this
    let show_columns = showCol ? columns_more : columns
    return (
      <div class="guild-box investor-box">
        <HomeInvestorPc onFilter={this.filterChange} loading={loading} data={guildData} columns={show_columns} width={width} />
        {
          (total > 30 && h5 && showPage) ? (
            guildData.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
          ) : null
        }

        {(!h5 && showPage) &&
          <div class="page-wrap">
            <Page total={total} current={page} pageSize={size} onChange={this.onHandlePage} />
            <div class="page-sizer flex-base">
              <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
              <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
            </div>
          </div>}
        {showViewall && <a href="/rank/investors"><div class="more">{this.$t('com.view_all')}</div></a>
        }
      </div>
    )
  }
};
