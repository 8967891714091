//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import '~/assets/less/news.less'
import '~/assets/less/layout/mmd.less'
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
  layout: 'index',
  data() {
    return {
      moment,
      data: {},
      id: '',
      imgs: [],
      game: '',
      list: [],
    };
  },
  head() {
    return {
    }
  },
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  async asyncData({ $http, route }) {

    return $http.get('/api/v1/news/info', { news_id: route.params.id }).then(({ result }) => {
      result.content = result.content || result.sub_title
      result.content_en = result.content_en || result.content || result.sub_title_en
      return {
        data: result
      }
    })
  },
  mounted() {
    this.getList()
  },
  watch: {
    $route: {
      handler(v) {
        this.getList()
      },
      deep: true
    },
  },
  methods: {
    getList() {
      this.$http.get('/api/v1/news/list', { limit: 10, offset: 0, type: 'strategy' }).then(({ result }) => {
        this.list = result.records
      })
    },
    go(item) {
      this.$router.push(`/news/${item.news_id}`)
    },
  },
};
