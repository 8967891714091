
import '../index.less'
import { mapGetters } from 'vuex'
import { ChevronDown, Search } from 'kui-icons'
export default {
  props: {
    oDatas: [Array],
    totals: Number,
  },
  computed: {
    ...mapGetters(['h5'])
  },
  data() {
    return {
      page: 1,
      total: this.totals || 0,
      current: 0,
      size: 30,
      loading: true,
      pageRanges: [
        { label: '10', value: 10 },
        { label: '30', value: 30 },
        { label: '50', value: 50 },
      ],
      followeData: this.oDatas || [],
      columns: [
        { title: '#', key: 'no', align: 'right', width: 60 },
        { title: this.$t('kol.col_name'), key: 'name', width: 250 },
        { title: 'Twitter Followers', key: 'followers_count', sorter: true, width: 200 },
        { title: 'KOL Followers', key: 'kol_count', sorter: true, width: 200 },
        { title: 'New KOL Followers 7D', key: 'kol_count_7d', sorter: true, width: 200 },
      ],
      order_field: '',
      timer: null,
      query_name: '',
      order: 'desc',
      sortKey: ['followers_count', 'kol_count', 'kol_count_7d'],
    };
  },

  mounted() {
    this.columns[3]._order = this.order
    this.getFollower()
  },
  methods: {
    onHandlePageSize({ value }) {
      this.page = 1
      this.size = value;
      this.getFollower()
    },
    onHandlePage(page) {
      this.page = page;
      this.getFollower()
    },
    filterChange({ key, order }) {
      this.sortKey.map((m, i) => {
        if (m == key) {
          if (!order) {
            this.order = 'asc'
            this.columns[i + 2]._order = 'asc'
          } else {
            this.order = this.order == 'asc' ? 'desc' : 'asc'
          }
          this.order_field = this.order ? (key + (this.order == 'asc' ? '_1' : '_2')) : ''
        } else {
          this.columns[i + 2]._order = ''
        }
      })
      this.page = 1
      this.getFollower()
    },
    seeMore() {
      this.page++
      this.getFollower()
    },
    getFollower() {
      let { order_field, size, query_name } = this
      this.loading = true
      this.$http.get('/api/v1/meta/kol/games/followers/rank', { limit: size, offset: (this.page - 1) * size, order_field, query_name }, ({ result }) => {
        let followerList = result.records.map(m => {
          return m.followers_count
        })
        let max = Math.max.apply(null, followerList)
        this.total = result.count
        this.loading = false
        let followeData = result.records.map((m, i) => {
          m.width = (m.followers_count / max) * 100
          m.no = i + ((this.page - 1) * size) + 1
          m = { ...m, ...m.investment_info }
          return m
        })
        if (this.h5) {
          this.followeData = this.followeData.concat(followeData)
        } else {
          this.followeData = followeData
        }
      })
    },
    onSearch(e) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.query_name = e.target.value
        this.page = 1
        this.getFollower()
      }, 300);
    }
  },
  render() {
    let { h5, followeData, columns, size, page, total, pageRanges, loading, filterChange } = this
    if (h5 && !followeData.length) {
      followeData = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},]
    }
    return (
      <div class="trend-box">
        {!h5 ? <Input type="search" class="search-input" shape="circle" theme="light" onKeyup={(e) => this.onSearch(e)} placeholder={this.$t('menu.search')}>
          <Icon type={Search} slot="suffix" />
        </Input> : null}
        {h5 ? <HomeTrendItem loading={loading && page == 1} data={followeData} type='kols-following' /> :
          <HomeTrendFollowerPc loading={loading} data={followeData} columns={columns} loading={loading} onFilter={filterChange} />}
        {
          (total > 30 && h5) ? (
            followeData.length < total ?
              <div class="load-more flex-base" onClick={this.seeMore}>{this.$t('com.more')}<Icon type={ChevronDown} size="20" /></div> :
              <div class="load-more flex-base">{this.$t('com.over')}</div>
          ) : null
        }

        {!h5 &&
          <div class="page-wrap">
            <Page total={total} current={page} pageSize={size} onChange={this.onHandlePage} />
            <div class="page-sizer flex-base">
              <div class="page-sizer-label">{this.$t('com.pagesize')}</div>
              <Select options={pageRanges} size="small" value={size} class="filter-ranges" onChange={this.onHandlePageSize} />
            </div>
          </div>}
      </div>
    )
  }
};
