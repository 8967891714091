
import './index.less'
import { mapGetters } from 'vuex'

export default {
  layout: "index",
  computed: {
    ...mapGetters(['h5', 'lang','whaleData'])
  },
  mounted() {
    // this.getCount()
  },
  data() {
    return {
      current: '',
      tabs: [
        { name: this.$t('whale.whale_growth'), value: 'active_user' },
        { name: this.$t('whale.whale_increase'), value: 'increase' },
        { name: this.$t('whale.whale_decline'), value: 'decline' },
      ],
      active_user: [
        { title: this.$t('whale.col_name'), key: 'name' },
        { title: this.$t('whale.col_growth_new'), key: 'rank' },
        { title: this.$t('whale.col_growth_change'), key: 'increased_num', },
      ],
      increase: [{ title: this.$t('whale.col_name'), key: 'name' },
      { title: this.$t('whale.col_increase_addcount'), key: 'rank' },
      { title: this.$t('whale.col_increase_addprecent'), key: 'increased_num', },],
      decline: [{ title: this.$t('whale.col_name'), key: 'name' },
      { title: this.$t('whale.col_decline_minuscount'), key: 'rank' },
      { title: this.$t('whale.col_decline_minusprecent'), key: 'increased_num', }
      ],
      h5Colume: [],
      type: 'active_user',
    };
  },
  methods: {
    changeTab(i) {
      if (this.current == i) return
      this.current = i
      this.$refs.whaleTable.page = 1
      this.type = this.tabs[i].value
      this.$nextTick(() => {
        this.$refs.whaleTable.getData()
      })
    },
    getCount() {
      this.$http.get('/v2/game/whale/track_count', { limit: 50, offset: 0, noEncrypt: true }, (result) => {
        this.whaleData = result
      })
    },
  },

  render() {
    let { active_user, increase, decline, tabs, changeTab, current, h5, type, whaleData } = this
    let h5Colume = [...this[type]]
    return (
      <div class="whale home-item">
        <div class="page-center">
          <div class="title">
            {this.$t('whale.title')}
          </div>
          <div class="sub-title">
            {this.$t('whale.sub_title1')}<span class="value">{whaleData.game_count}</span>{this.$t('whale.sub_title2')}<span class="value">{this.utils.changeUnit(whaleData.whale_count)}</span>{this.$t('whale.sub_title3')}
          </div>
          {h5 ? <HomeTabs tabs={tabs} onChangeTab={changeTab} activeIndex={current} /> : null}
          <div class="whale-tables">
            <HomeWhaleTable columns={h5Colume} type={type} ref="whaleTable" />
            {!h5 ? <HomeWhaleTable columns={increase} type='increase' /> : null}
            {!h5 ? <HomeWhaleTable columns={decline} type='decline' /> : null}
          </div>
          <a href="/whale/change">
            <div class="more">{this.$t('com.view_all')}</div>
          </a>
        </div>
      </div>
    )
  }
};
