import { render, staticRenderFns } from "./_id.vue?vue&type=template&id=6f6d53a7&"
import script from "./_id.vue?vue&type=script&lang=js&"
export * from "./_id.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GamesBase: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/base.vue').default,SubjectsTrends: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/trends.vue').default,GamesOverview: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/overview.vue').default,SubjectsStepn: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/stepn.vue').default,SubjectsWalken: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/walken.vue').default,SubjectsMelody: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/melody.vue').default,SubjectsRead2n: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/read2n.vue').default,SubjectsSocial: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/social.vue').default,CalcWalken: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/calc/walken.vue').default,GamesStrategy: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/games/strategy.vue').default,SubjectsView: require('/Users/xiaobei/Projects/suomier/mymetadata-server/components/subjects/view.vue').default})
