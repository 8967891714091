//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex'
export default {
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  props: {
    item: Object,
    loading: Boolean
  },
  data() {
    return {
      gameTypes: {
        recommend: this.$t('newgame.recommend'),
        testing: this.$t('newgame.testing'),
        financing: this.$t('newgame.financing'),
        coming: this.$t('newgame.coming'),
      },
    }
  },
  methods: {
    ...mapMutations(['setShowBanner']),
    rowClick(item) {
      if (this.loading) return false
      !this.h5 && this.setShowBanner(false)
      this.$router.push(`/games/${item.game_name}`)
    },
  },
}
