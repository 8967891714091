//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['lang', 'h5'])
  },
  props: {
    hasFixed: Boolean,
    data: Array,
    columns: Array,
    loading: Boolean
  },
  methods: {
    toTT({ tid }) {
      open(`https://twitter.com/${tid}`)
    }
  }
}
