//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import '~/assets/less/subject.less'

export default {
  computed: {
    ...mapGetters(['h5', 'lang'])
  },
  layout: 'game',
  data() {
    return {
      tabIndex: 0,
      tabActive: 'data',
      tabs: [
        // { title: this.$t('subject.overview'), key: 'overview' },
        { title: this.$t('subject.trends'), key: 'info' },
        { title: this.$t('subject.data'), key: 'data' },
        { title: this.$t('subject.social'), key: 'social' },
      ],
      game: this.$route.params.id
    }
  },
  watch: {
    h5(v) {
      let type = this.$route.query.type || ''
      if (!v && !/data|social|calc|strategy/.test(type)) {
        this.tabActive = 'data'
      }
      if (!v && this.tabs[0].key == 'overview') {
        this.tabs.splice(0, 1)
      }
      if (v && this.tabs[0].key != 'overview') {
        this.tabs.unshift({ title: this.$t('subject.overview'), key: 'overview' })
      }
    }
  },
  created() {
    let type = this.$route.query.type || ''
    if (this.game == 'walken') {
      this.tabs.splice(3, 0, { title: '回本测算', key: 'calc' })
      // console.log(this.tabs)
    } else if (this.game == 'melody' || this.game=='read2n') {
      this.tabs.splice(3, 0, { title: this.$t('games.strategy'), key: 'strategy' })
    }
    if (this.h5) {
      this.tabs.unshift({ title: this.$t('subject.overview'), key: 'overview' })
      if (/info|data|social|view|calc|strategy|overview/.test(type)) {
        this.tabActive = type
        this.tabIndex = this.tabs.findIndex(x => x.key == type)
      }
    } else {
      this.tabs.push({ title: this.$t('subject.v'), key: 'view' },)

    }
  },
  methods: {

    changeTab(key, i) {
      if (this.h5) {
        this.tabActive = key
        this.$router.push(`/subject/${this.game}?type=${key}`)
        // this.setScroll(i)
      } else if (/data|social|calc|strategy/.test(key)) {
        this.tabActive = key
      }
    },
    setScroll(i) {
      if (!this.$refs.tabList) return;
      let box = this.$refs.tabList.$el
      let item = box.children[i]//e.target
      if (!item) return;
      let w = box.offsetWidth, w2 = box.scrollWidth
      let { offsetLeft, offsetWidth } = item
      let half = offsetLeft + (offsetWidth / 2)
      this.ScrollTo(box, half > w / 2 ? half - w / 2 : 0, 300)
    },
    ScrollTo(target, number = 0, time) {
      if (!time) {
        target.scrollTop = target.scrollLeft = number;
        return number;
      }
      const spacingTime = 20; // 设置循环的间隔时间  值越小消耗性能越高
      let spacingInex = time / spacingTime; // 计算循环的次数
      let nowLeft = target.scrollLeft; // 获取当前滚动条位置
      let everLeft = (number - nowLeft) / spacingInex; // 计算每次滑动的距离
      let scrollTimer = setInterval(() => {
        if (spacingInex > 0) {
          spacingInex--;
          this.ScrollTo(target, nowLeft += everLeft);
        } else {
          clearInterval(scrollTimer); // 清除计时器
        }
      }, spacingTime);
    },

  }
}
